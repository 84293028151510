<footer class="bg-dark-purple text-white">
    <div class="bg-dark-purple text-white-50 p-0">
        <div class="container">
            <div class="p-2 text-center footer-links">
                <a routerLink="/" class="btn btn-link">Home</a>
                <a routerLink="/aboutus" class="btn btn-link">About Us</a>
                <a routerLink="/Pricing"  class="btn btn-link">Pricing</a>
                <a routerLink="/Faq" class="btn btn-link">Help/FAQs</a>
                <a routerLink="/Privacy-Policy" class="btn btn-link">Privacy Policy</a>
                <a routerLink="/Termandcondition" class="btn btn-link">Terms Of Conditions</a>
                <a routerLink="/Cancellation-policy" class="btn btn-link">Cancellation Policy</a>
                <a routerLink="/Contactus" class="btn btn-link">Contact Us</a>
            </div>
        </div>
    </div>
    <div class="footer-main border-bottom">
        <div class="container">
            <div class="row">
                <div class="col-xl-3 col-lg-6 col-md-12">
                    <h2>Source My Trip</h2>
                    <p class="mb-1 mt-2">One of the leaders in the Indian travel industry, SourceMyTrip is the go-to platform for your travel needs. Find the best online flight tickets booking and hotel booking deals, and save money every time you hit the road for business or leisure</p>
                    <h6 class="mb-2 mt-3">Social Icons</h6>
                    <ul class="social-icons mb-0 mt-3">
                        <li> <a class="social-icon" href="https://www.facebook.com/pages/category/Travel-Company/sourcemytripcom-230973773742627/"
                            target="_blank"><i class="fa fa-facebook text-white-50"></i></a> </li>
                        <li> <a class="social-icon" href="https://twitter.com/sourcemytrip" target="_blank"><i class="fa fa-twitter text-white-50"></i></a> </li>
                        <li> <a class="social-icon" href="https://www.instagram.com/sourcemytrip/" target="_blank"><i class="fa fa-instagram text-white-50"></i></a> </li>
                        <li> <a class="social-icon" href="https://www.linkedin.com/in/sourcemytrip-com-91034a8a/" target="_blank"><i class="fa fa-linkedin text-white-50"></i></a> </li>
                    </ul>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12">
                    <h6>Quick Links</h6>
                    <ul class="list-unstyled mb-0">
                        <li><a routerLink="/"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Flight </a></li>
                        <li><a routerLink="/hotel"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Hotel </a></li>
                        <li><a routerLink="/holiday"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Holiday </a></li>
                        <li><a routerLink="/transfer"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> Transfer </a></li>
                        <li><a routerLink="/seightseeing"><i class="fa fa-angle-double-right mr-2 text-secondaryf"></i> SeightSeeing </a></li>
                    </ul>
                    <ul class="social-icons iatatop"><li class="iatali"><img src="assets/images/iataimg.png"  class="iataimg"></li>
                        <li  class="iatali"><img src="assets/images/IATO.png" class="iataimg"></li>
                    </ul>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12">
                    <h6 class="mt-6 mt-xl-0">Get In Touch</h6>
                    <ul class="list-unstyled mb-0">
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-home mr-3 text-secondaryf"></i></span>
                            <span style="display:table-cell;vertical-align: top;">Property No. 101-A2, Plot No. 416-0, Makani No. 31435 96000,<br> mohamed hamed saif alrumhi building,<br>Al Muteena, Dubai</span></li>
                        <li><span><i class="fa fa-envelope mr-3 fs-12 text-secondaryf"></i></span><span>sourcemytrip85@gmail.com</span></li>
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-phone mr-3 text-secondaryf"></i></span><span style="display:table-cell;vertical-align: top;">+971 56 263 6755</span></li>
                    </ul>
                    <!--<ul class="list-unstyled mb-0" style="border-top: 1px solid whitesmoke;margin-top: 1px;border-top-style:dashed; ">
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-home mr-3 text-secondaryf"></i></span>
                            <span style="display:table-cell;vertical-align: top;">4239 DARK STAR LANE,<br> DALLAS Taxas 72511</span></li>
                        
                    </ul>-->
                    <!--<ul class="list-unstyled mb-0" style="border-top: 1px solid whitesmoke;margin-top: 1px;border-top-style:dashed; ">
                        <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-hand-o-right mr-3 text-secondaryf"></i></span>
                            <span style="display:table-cell;vertical-align: top;"><a style="color: white;" routerLink="/Contactus">More Branches</a></span></li>
                        
                    </ul>-->
                </div>
                <div class="col-xl-3 col-lg-6 col-md-12">
                    <h6 class="mb-2 mt-6 mt-xl-0">Subscribe</h6>
                    <div class="form-group mb-2 mt-3">
                        <input type="text" class="form-control br-tl-5 br-bl-5" placeholder="Name"> </div>
                    <div class="form-group mb-2 mt-0">
                        <input type="text" class="form-control br-tl-5 br-bl-5" placeholder="Email"> </div>
                    <div>
                        <button type="button" class="btn btn-secondaryf btn-block"> Subscribe </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-dark-purple text-white-50 p-2">
        <div class="container">
            <div class="row d-flex">
                <div class="col-lg-12 col-sm-12  mt-2 mb-2 text-center "> Copyright © 2021 SourceMyTrip. All rights reserved </div>
            </div>
        </div>
    </div>
</footer>
