import { AccountService } from './../Service/account.service';
import { Router } from '@angular/router';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthServiceService } from '../Service/auth-service.service';
import { NavigationService } from '../Service/navigation.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { CurrencyModel } from '../Model/currency-model.model';
import { parse } from 'querystring';
import { GlobalConstant } from '../Model/global-constant.model';
import { CompanySetting } from '../Model/FlightSearchModel';
import { CompanySettingService } from '../Service/company-setting.service';
import { SharedServiceService } from '../Service/shared-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  modalRef: BsModalRef;
  config: ModalOptions = {  class: 'modal-dialog' };
  isLogin: boolean;
  product: string = 'flight';
  SignInRegister: string = '';
  currencySymbol: string = '₹';
  sourceCurr: string = 'INR';
  currencyConversionRate: number=1;
  expectedCurrency: string = '';
  expectedCurrencyCode: string = '';
  mappedCountryName: string='';
  hideCurrencyPopup: boolean;
  CurrencyModelArray: Array<CurrencyModel> = [];
  CompanySetting = CompanySettingService;
  timeLeft: number;
  @ViewChild("SignInRegisterPopup", { static: false }) SignInRegisterPopup: TemplateRef<any>;
  constructor(private accountService: AccountService, private router: Router
    , private _navigation: NavigationService, private modalService: BsModalService,public sharedServiceService:SharedServiceService) {
      sharedServiceService.currencySymbol=this.sharedServiceService.currencySymbol;
      sharedServiceService.currencyConversionRate=this.sharedServiceService.currencyConversionRate;
      sharedServiceService.isShownCurrency=true;
    this._navigation.currentSelected.subscribe(status => this.product = status);
    this._navigation.isLoginRegister.subscribe(data => {
      if (data == 'Login') {
        this.isLogin = true;
        if (this.modalRef)
          this.modalRef.hide();
      }
      else if (data == 'Registration') {
        if (this.modalRef)
          this.modalRef.hide();
      }
      else if (data == 'ForgetPassword') {
        if (this.modalRef)
          this.modalRef.hide();
         
      }
      else if (data == 'Login popup show') {
        if (this.modalRef)
          this.modalRef.hide();
        this.SignInRegisterclick('Sign In');
      }
      else if (data == 'Register popup show') {
        if (this.modalRef)
          this.modalRef.hide();
        this.SignInRegisterclick('Register');
      }
      else if (data == 'Forget password popup show') {
        if (this.modalRef)
          this.modalRef.hide();
        this.SignInRegisterclick('Forget Password');
      }
    });
  }

  ngOnInit() {

    this.accountService.currentUser.subscribe(x => {
      if (x != null)
        this.isLogin = true;
      else
        this.isLogin = false;
    });

    this.accountService.currency().subscribe(data => {
      var alldata = data as any;
      this.CurrencyModelArray=alldata.CurrencyList;
      var defaultCurreny = this.CurrencyModelArray.find(x=> x.CurrencyCode == this.sourceCurr);
      if(defaultCurreny){
        this.expectedCurrencyCode = defaultCurreny["MappedCountryCode"];
        this.expectedCurrency = defaultCurreny["CurrencyCode"];
        this.mappedCountryName = defaultCurreny["MappedCountryName"];
      }
    }, err => {  
    });
  }
  
  SignInRegisterclick(data: string) {
    this.SignInRegister = data;
    this.modalRef = this.modalService.show(this.SignInRegisterPopup,this.config);
  }
  Logout() {
    this.accountService.logout();
    this.router.navigate(['/']);
  }
  CurrencyChange(event){
    this.hideCurrencyPopup=false;
    console.log(event); //option value will be sent as event
     this.expectedCurrency=event.split('-')[0];
     this.currencySymbol=event.split('-')[1]=="Rp"?"₹":event.split('-')[1];
     this.sharedServiceService.selectedCurrency=this.expectedCurrency;
    this.accountService.GetCurrencyRate(this.sourceCurr,this.expectedCurrency).subscribe(data => {
      var alldata = data as any;
      this.currencyConversionRate= alldata.CurrencyList[0].Conversion;
      this.sharedServiceService.updateGlobalVar(this.currencySymbol,this.currencyConversionRate,this.sharedServiceService.selectedCurrency);
    });
    this.expectedCurrencyCode = event.split('-')[2];
    this.mappedCountryName = event.split('-')[3];
   }
  ChangeProduct(Product) {
    if (Product === 'flight') {
      this.product = 'flight';
      this.router.navigate(["/flight"]);
    } else if (Product === 'hotel') {
      this.product = 'hotel';
      this.router.navigate(["/hotel"]);
    } else if (Product === 'holiday') {
      this.product = 'holiday';
      this.router.navigate(["/holiday"]);
    } else if (Product === 'transfer') {
      this.product = 'transfer';
      this.router.navigate(["/transfer"]);
    } else if (Product === 'sightseeing') {
      this.product = 'sightseeing';
      this.router.navigate(["/sightseeing"]);
    } else
      this.router.navigate(["/"]);
  }
  CurrencyShowPopup()
  {
    this.hideCurrencyPopup=true;
  }

}
