import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Authentication, loginRegisterModel, RequestBody, RequestHeader, UserDetails, UserRQ } from 'src/app/Model/loginRegisterModel';
import { AccountService } from 'src/app/Service/account.service';
import { NavigationService } from 'src/app/Service/navigation.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {
  loginModel: loginRegisterModel;
  loader: boolean;
  @ViewChild("loginbtn", { static: false }) loginbtn: ElementRef;
  constructor(private accountService: AccountService, private router: Router,
    private navigationService: NavigationService, private toastr: ToastrService) { }


  ngOnInit() {
    this.loginModel = new loginRegisterModel();
    this.loginModel.UserRQ = new UserRQ();
    this.loginModel.UserRQ.Authentication = new Authentication();
    this.loginModel.UserRQ.RequestHeader = new RequestHeader();
    this.loginModel.UserRQ.RequestHeader.Method = "ForgotPassword";
    this.loginModel.UserRQ.RequestBody = new RequestBody();
    this.loginModel.UserRQ.RequestBody.UserDetails = new UserDetails();
  }
  myFormSubmitHandler() {
    let el: HTMLElement = this.loginbtn.nativeElement;
    el.click();
  }
  LoginUser(valid) {
    if (valid) {
      this.loader = true;
      this.accountService.ForgotPassword(this.loginModel).subscribe(data => {
        console.log(data);
        var alldata = data as any;
        if (alldata.UserRS.ResponseHeader == null) {
          this.loader = false;
          this.toastr.warning('Contact admin !');
          return false;
        }
        else if (alldata.UserRS.ResponseHeader.Status == "SUCCESS") { 
          this.loader = false;         
          this.toastr.success('Successfully Send Login Details.');
          this.navigationService.SetLoginRegister('ForgetPassword');
        }
        else if (alldata.UserRS.ResponseHeader.Status == "NOTEXIST") {    
          this.loader = false;      
          this.toastr.warning('Data Not Exist !');
          this.navigationService.SetLoginRegister('ForgetPassword');
        }
        else {
          this.loader = false;
          this.toastr.warning(alldata.UserRS.ResponseHeader.Message);
          this.navigationService.SetLoginRegister('ForgetPassword');
        }
      }, err => {
        this.loader = false;
        this.toastr.error('Wrong Username/Password.');
      })
    }
  }
  gotoregister(val) {
    this.navigationService.SetLoginRegister(val);
  }
  gotologin() {
    this.navigationService.SetLoginRegister('Login popup show');
  }
}
