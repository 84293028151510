import { Router } from '@angular/router';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { loginRegisterModel, UserRQ, Authentication, RequestHeader, RequestBody, UserDetails } from './../Model/loginRegisterModel';
import { AccountService } from '../Service/account.service';
import { NavigationService } from '../Service/navigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  loginModel: loginRegisterModel;
  loader: boolean;
  @ViewChild("loginbtn", { static: false }) loginbtn: ElementRef;
  constructor(private accountService: AccountService, private router: Router,
    private navigationService: NavigationService, private toastr: ToastrService) { }


  ngOnInit() {
    this.loginModel = new loginRegisterModel();
    this.loginModel.UserRQ = new UserRQ();
    this.loginModel.UserRQ.Authentication = new Authentication();
    this.loginModel.UserRQ.RequestHeader = new RequestHeader();
    this.loginModel.UserRQ.RequestHeader.Method = "Login";
    this.loginModel.UserRQ.RequestBody = new RequestBody();
    this.loginModel.UserRQ.RequestBody.UserDetails = new UserDetails();
  }
  myFormSubmitHandler() {
    let el: HTMLElement = this.loginbtn.nativeElement;
    el.click();
  }
  LoginUser(valid) {
    if (valid) {
      this.loader = true;
      this.accountService.login(this.loginModel).subscribe(data => {
        console.log(data);
        var alldata = data as any;
        if (alldata.UserRS.ResponseHeader == null) {
          this.loader = false;
          this.toastr.warning('Wrong Username/Password.');
          return false;
        }
        else if (alldata.UserRS.ResponseHeader.Status == "SUCCESS") {
          localStorage.setItem("token", alldata.UserRS.ResponseBody.UserDetails.ClientId);
          localStorage.setItem("UserDetails", JSON.stringify(alldata.UserRS.ResponseBody.UserDetails));
          //this.router.navigate(['account/']);
          this.toastr.success('Login successful');
          this.navigationService.SetLoginRegister('Login');
          window.location.reload();
        }
        else {
          this.loader = false;
          this.toastr.warning(alldata.UserRS.ResponseHeader.Message);
        }
      }, err => {
        this.loader = false;
        this.toastr.error('Wrong Username/Password.');
      })
    }
  }
  gotoregister(val) {
    this.navigationService.SetLoginRegister(val);
  }
}
