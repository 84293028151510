// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  "isPaymentHit": true,
  isAllowedCancellation: true,
  defaultGateway: "CCAvenue",
  mobileNo:"+9971060888",
  emailId:"santosh011@gmail.com",
  "AppRedirectURLFlight": "https://sourcemytrip.co/flight-payment",
  "AppRedirectURLHotel": "https://sourcemytrip.co/hotel-payment",
  //"AppRedirectURLHotel": "http://localhost:4200/hotel-payment",
  "AppRedirectURLSightseeing": "https://sourcemytrip.co/sightseeing/sightseeing-payment",
  "AppRedirectURLHoliday": "https://sourcemytrip.co/holiday/holiday-payment",
  "AppRedirectURLTransfer": "https://sourcemytrip.co/transfer/transfer-payment",

  UtilityBaseURL: 'https://betaapi.sourcemytrip.co/Beta_UtilityService/',
  GenericBaseURL: 'https://betaapi.sourcemytrip.co/Beta_GenericAPI/',
  //GenericBaseURL: 'http://localhost:51524/',
  PaymentInitiateURL: 'https://payment.sourcemytrip.co/PaymentSrv.asmx/PaymentInitiate',
  PaymentURL: "https://payment.sourcemytrip.co/API/PaymentWaiting.aspx?PG=",
  HotelBaseURL: 'https://betaapi.sourcemytrip.co/Beta_HotelAPI/',
  /*HotelBaseURL: 'http://localhost:62216/',*/
  FlightBaseURL: 'https://betaapi.sourcemytrip.co/Beta_FlightAPI/',
  //FlightBaseURL: 'http://localhost:53734/',
  PackageBaseURL:'https://betaapi.sourcemytrip.co/Beta_PackageAPI/', 
  SightseeingBaseURL:'https://betaapi.sourcemytrip.co/Beta_SeightSeeing/',
  TransferBaseURL:'https://betaapi.sourcemytrip.co/Beta_TransferAPI/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
