import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  baseURLGenric = '';
  UtilityBaseURL='';
  constructor(private _http: HttpClient) { 
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('token')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseURLGenric = environment.GenericBaseURL;
    this.UtilityBaseURL=environment.UtilityBaseURL;
  }
  
  
  private header = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT'
  });

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  ForgotPassword(request: any) {
    return this._http.post(this.baseURLGenric + "api/User/ForgotPassword", request, { headers: this.header });
  }
  register(request: any) {
    return this._http.post(this.baseURLGenric + "api/User/Registration", request, { headers: this.header });
  }
  currency() {
    return this._http.get(this.UtilityBaseURL + 'api/MulticurrencyList?cid=SMT&channel=B2C',{ headers: this.header });
  }
  GetCurrencyRate(sourceCurr,expectedCurrency) {
    return this._http.get(this.UtilityBaseURL + 'api/GetCurrencyRate?cid=SMT&sourceCurr='+sourceCurr+'&expectedCurrency='+expectedCurrency+'',{ headers: this.header });
  }
  login(request: any) {
    return this._http.post(this.baseURLGenric + "api/User/Login", request, { headers: this.header }).pipe(
      map(user=>{
        var alldata=user as any;
        if(alldata.UserRS.ResponseHeader.Status=="SUCCESS"){
          this.currentUserSubject.next(user);
                return user;
        }else{
          this.currentUserSubject.next(null);
          return user;
        }
      })
    );
  }

  getBookingList(request:any){
    return this._http.post(this.baseURLGenric + "api/booking/SearchBooking", request, { headers: this.header });
  }

  getBookingDetail(request:any){
    return this._http.post(this.baseURLGenric + "api/booking/BookingDetail", request, { headers: this.header });
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("UserDetails");
    this.currentUserSubject.next(null);
}
}
