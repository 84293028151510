import { VoucherModel, BookingDetailRequest,Authentication, Header, RequestBody,SubAgent } from './../Model/VoucherModel';
import { HotelService } from './../Service/hotel.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  ReservationRef:string;
  voucherModel:VoucherModel;
  constructor(private route:ActivatedRoute,private hotelService:HotelService) { }

  ngOnInit() {
    this.ReservationRef = this.route.snapshot.params.bookingID;
    this.getVoucher();
  }

  getVoucher(){
    this.voucherModel=new VoucherModel;
    this.voucherModel.BookingDetailRequest=new BookingDetailRequest;
    this.voucherModel.BookingDetailRequest.Authentication=new Authentication;
    this.voucherModel.BookingDetailRequest.Authentication.SubAgent=new SubAgent;
    this.voucherModel.BookingDetailRequest.Header=new Header;
    this.voucherModel.BookingDetailRequest.RequestBody=new RequestBody;

    this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef=this.ReservationRef;

   this.hotelService.getHotelVoucher(this.voucherModel).subscribe(data=>{
   })
  }
}
