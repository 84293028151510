import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { RegisterComponent } from './register/register.component';
import { MyProfileComponent } from '../my-profile/my-profile.component';
import { HotelModule } from '../hotel/hotel.module';
import { FilterPipe } from '../Filter/FilterPipe.pipe';
import { OrderrByPipe } from '../Filter/OrderrBy.pipe';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';


@NgModule({
  declarations: [AccountComponent, RegisterComponent, MyProfileComponent, FilterPipe, OrderrByPipe, ForgetPasswordComponent],
  imports: [
    CommonModule,
    AccountRoutingModule,
    FormsModule,
    HotelModule,
    ToastrModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [AccountComponent, RegisterComponent, ForgetPasswordComponent]
})
export class AccountModule { }
