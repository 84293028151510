  <!--login-->
  <div class="login-box">
    <div class="login-head">
        <h2>Log In</h2>
        <p>New to Source My Trip? <a href="javascript:void(0);" (click)="gotoregister('Register popup show')">Register</a></p>
    </div>
    <div class="field-box">
        <div class="row">
            <div class="col-md-12 b-r">

                <form (ngSubmit)="LoginUser(loginForm.form.valid)" #loginForm="ngForm" novalidate >
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email"
                        [(ngModel)]="loginModel.UserRQ.RequestBody.UserDetails.UserName" 
                        [ngClass]="{'is-invalid': loginForm.submitted && Email.invalid}" 
                       #Email="ngModel" required name="Email">
                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Password"
                        [(ngModel)]="loginModel.UserRQ.RequestBody.UserDetails.Password" 
                        [ngClass]="{'is-invalid': loginForm.submitted && Password.invalid}" 
                       #Password="ngModel" required name="Password">
                    </div>
                    <ul class="form_ul">
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" 
                                    [(ngModel)]="loginModel.UserRQ.RequestBody.UserDetails.RememberMe" 
                                    [ngClass]="{'is-invalid': loginForm.submitted && RememberMe.invalid}" 
                                    #RememberMe="ngModel" name="RememberMe" >Remember me
                                </label>
                            </div>
                        </li>
                        <li>
                            <a href="javascript:void(0);" (click)="gotoregister('Forget password popup show')" class="forget">Forget password?</a>
                        </li>
                    </ul>
                    <button type="submit" hidden #loginbtn></button>
                    <a href="javascript:void(0)" (click)="myFormSubmitHandler()" class="submit_login">Log In</a>


                </form>
            </div>
           
        </div>
    </div>

</div>

<app-loader *ngIf="loader"></app-loader>