<div class="menu-bottom" style="padding-top: 80px;"></div>

<section class="about-padd">
  <div class="container">
    <div class="section-title text-center">
      <!-- <h2>Faqs</h2> -->

       <img style="width: 100px;" src="assets/images/under-sub-2.png">
    </div>

    <div class="faq-box">

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How do I get live quotes for my private flight?</h3>
      <p>Once you have submitted your flight request to Air Aspire (online, by email or by phone) we will send your
        request out to our network of accredited aircraft operators.</p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How long will it take to get quotes back for my flight request?</h3>
      <p>For flights in Europe, Middle East, South Asia and the USA you can expect live quotes and aircraft
        availability
        to be returned to you within minutes. Requests for larger airline aircraft and for flights in particular
        regions
        of the world, the response time may be longer.</p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Can I see actual photos of the private jets which have quoted for my flight?</h3>
      <p>Actual aircraft images will be sent to you with every flight quote.</p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        I only want to receive flight quotes on new aircraft, can I specify that?</h3>
      <p>We can filter your flight options to include new aircraft, or any other criteria. Please write to us at
        info@airaspire.com if you have any special flight or aircraft requirements.</p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Can I buy a seat on a private aircraft?</h3>
      <p>Air Aspire offers private jet charter, which means the hire of the whole aircraft. The charter price for the
        aircraft does not change if you fly alone or with every seat occupied. The only slight variation in cost will
        be
        when airport passenger tax is applied on the flight. Each country has different regulations for passenger tax
        and
        this will be fully explained during the quoting process.</p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        What's the difference between private jet charter and fractional ownership?</h3>
      <p>Private jet charter involves paying to use someone else's aircraft when they are not using it. This is
        normally a one off charter agreement with no long term financial commitment.
        Fractional ownership involves sharing the full cost of purchasing and operating an aircraft.
        Chartering a private jet can is often described as subsidised flying as the fixed costs (such as aircraft
        purchasing and depreciation, pilot salaries, aircraft maintenance and insurances) are paid for by the owner.
        When you charter an aircraft you are only paying for the fuel, airport fees and a small amount of extra cost.
      </p>


      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        I've missed my scheduled airline flight. How quickly can I get a private jet?</h3>
      <p>Private jets can be organised very quickly, depending on the location of available aircraft. This can be as
        quick as 30 minutes from your flight request or may take longer, subject to availability and your location. You
        just need to submit your request to Air Aspire and all our operators will be invited to quote instantly.
      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How much does it cost to travel by private jet?</h3>
      <p>The cost of flying by private jet depends on flight time, aircraft hourly rate, positioning sectors (where the
        aircraft is flown empty before or after the passenger sector) and airport fees. All prices that you receive
        from our network are fully inclusive, there are no hidden costs.
      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        What happens after I book my flight?</h3>
      <p>Once you have booked your flight with Air Aspire, we will confirm the flight with the operator.
        You then need to sign and return the contract that we send you, let us know any catering requests, and send us
        the passport details for each of the passengers

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How can I pay for my private flight with Private Fly?</h3>
      <p>We can accept bank transfers and payment by credit or debit card. To discuss payment options please call +91
        9250003366 or write to us at info@airaspire.com

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Which private jet airfield is closest to my home?</h3>
      <p>Using the closest airport to your house or work can save hours on the length of a journey. Private jet
        passengers are not limited to the same airports that scheduled airlines use and therefore can often use
        airports much closer to their final destination.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        What happens when I get to the Airport?</h3>
      <p>Most larger airports have either separate private jet terminals or buildings called FBOs (Fixed Based
        Operators) where private jet passengers check in for their flight. At many airports, private jet passengers can
        be driven straight through security to their aircraft. The time from arriving at the private jet terminal to
        boarding your aircraft is normally less than 5 minutes. If however there is a delay to your flight or perhaps
        you have chosen to arrive early, the FBOs will normally have private executive lounges for you to relax or work
        in.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How long before my flight do I need do check in?</h3>
      <p>Air Aspire recommends that our passengers arrive at the airport 70 minutes before the expected departure
        (airborne) time. At some airports such as London City Airport, this can be reduced to 15 minutes.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Do I still need to show my passport when travelling by private jet charter?</h3>
      <p>Depending on your flight destination and nationality you may not require your passport when travelling by
        private jet. However Air Aspire always recommends that our clients travel with a passport, even for domestic
        flights within their own country.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Who will meet me when I arrive at the airport?</h3>
      <p>At your departure and arrival airport you will be escorted to/ from the aircraft by both the aircraft captain
        and a representative from the handling agent (or FBO). If you require ground transportation to or from the
        airport please ask your Air Aspire representative who will be happy to arrange this for you.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How do I know where to go when I arrive at the airport?</h3>
      <p>Most airports have dedicated private jet check-in facilities. These are normally provided by specialist
        handling agents (often called FBOs). PrivateFly will send you detailed directions to the airport facilities and
        your aircraft captain will be waiting for you at the entrance.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Do I still have customs/ immigration when travelling by private jet charter?</h3>
      <p>Customs and immigration procedures must be complied with for all private jet flights. Private jet customers
        are often not aware of any checks as in most cases these can be completed prior to the flight. At larger
        airports the private jet terminal will have dedicated customs and immigration staff. At smaller airports it is
        rare to see Customs and Immigration officials although they will be aware of your flight and can occasionally
        make special visits to the airport to meet a flight.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Are private jets quicker than scheduled airliners?</h3>
      <p>The flying speed of a private jet varies dramatically depending on the type of aircraft. For example the
        fastest civilian aircraft in the world is the Citation X (ten), however many smaller jets including the range
        of aircraft called VLJs (Very Light Jets) generally have lower maximum flight speeds than airliners. The time
        saving of flying privately is made on the ground. With minimal check in times and no delays waiting for luggage
        and clearing customs, flying by private jet is much faster than using a scheduled airline. Also on longer
        flights Air Traffic Control (ATC) will be able to offer private jet many more short cuts than a scheduled
        airline. This is because most private jets have a higher rate of climb and can fly higher than a normal air
        scheduled airline.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        What sort of aircraft do I need for my trip?</h3>
      <p>The type of aircraft that is most suited for your itinerary will depend on many factors including; flight
        distance, number of passengers, runway lengths, global region and of course your budget. Please do contact a
        Air Aspire representative to ask more about the type of aircraft required for your private flight.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How do I know my aircraft is safe?</h3>
      <p>Air Aspire has a safety policy that maximises the safety and security of our registered members. The aircraft
        operators who quote for your flight through the Air Aspire network are licensed to commercially charter their
        aircraft and are governed by their national civil aviation authority. Air Aspire is able on request to send
        detailed reports concerning the companies and crews that operate your selected aircraft.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How many pilots will there be on my aircraft?</h3>
      <p>Air Aspire always recommends that you have 2 pilots on every flight - indeed this is often a legal requirement
        depending on the type of aircraft. There are some examples of when only having one pilot is acceptable, such as
        daylight helicopter flights.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        What catering will I have on board?</h3>
      <p>VIP catering suitable for the time of day will be provided on-board. For small cabin aircraft this will
        normally be pre-prepared buffet style options. All flights will also include a fully licensed bar and soft
        drinks. For larger cabin aircraft which have galley facilities, catering can be prepared on-board and hot meals
        served. Special catering requests will always be accommodated when possible.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Can I order specific food on my private jet?</h3>
      <p>Special catering requests can nearly always be sourced as most private jet hubs have arrangements with
        catering companies or nearby restaurants and hotels.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        How much luggage can I take on my private jet?</h3>
      <p>The amount of luggage that can be taken on a private jet depends on the size of the aircraft and the number of
        passengers travelling. For a small jet the normal baggage limit is one per passenger seat plus cabin luggage.
        Larger jets such as a Gulfstream have large baggage holds which can have up to 20 large suitcases.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        What happens if I want to change the time of my departure?</h3>
      <p>Private aviation is all about flexibility, so if you need to change your departure time it is generally not an
        issue. However, please do give PrivateFly as much notice as possible so that we can arrange new Air Traffic
        Control and airport slots to match your schedule.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Can I add a last minute passenger onto my flight?</h3>
      <p>For most private jet journeys it is normally easy to add extra passengers onto the flight at the last minute.
        However to save any embarrassment with or delays for your guests, it is advisable to check with Air Aspire
        first. Some destinations (such as Russia) require pre-clearance of customers. Also for shorter runways, there
        may be a limitation on the maximum number of passengers due to the aircraft landing and take off weight limits.

      </p>

      <h3><i class="fa fa-hand-o-right" aria-hidden="true"></i>

        Can I also book helicopters with Air Aspire?</h3>
      <p>Air Aspire has an extensive network of helicopters. Helicopter landing sites outside of airports require the
        landowners permission. Please contact your Air Aspire representative to discuss your helicopter charter
        requirements.

      </p>

    </div>

  </div>
</section>