import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HotelRoutingModule } from './hotel-routing.module';
import { HotelComponent } from './hotel.component';
import { OwlModule } from 'ngx-owl-carousel';  
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HotelResultComponent } from '../hotel-result/hotel-result.component';
import { Ng5SliderModule} from 'ng5-slider';
import { HotelDetailComponent } from '../hotel-detail/hotel-detail.component'
import { ModalModule } from 'ngx-bootstrap/modal';
import { HotelPassengerComponent } from '../hotel-passenger/hotel-passenger.component';
import { HotelConfirmationComponent } from '../hotel-confirmation/hotel-confirmation.component';
import { VoucherComponent } from '../voucher/voucher.component';
import { InvoiceComponent } from '../invoice/invoice.component';
import { HotelPaymentConfirmationComponent } from '../hotel-payment-confirmation/hotel-payment-confirmation.component';
import { PaymentDeclinedComponent } from '../payment-declined/payment-declined.component';
import { HotellistloaderComponent } from '../hotellistloader/hotellistloader.component';
import { MatProgressBarModule } from '@angular/material';
import { LoaderComponent } from '../loader/loader.component';
import { ToastrModule } from 'ngx-toastr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FormatTimePipe } from '../Pipe/format-time.pipe';

@NgModule({
  declarations: [HotelComponent, HotelResultComponent, HotelDetailComponent,LoaderComponent, 
    HotelPassengerComponent, HotelConfirmationComponent, VoucherComponent
    , InvoiceComponent ,HotelPaymentConfirmationComponent,PaymentDeclinedComponent,HotellistloaderComponent,FormatTimePipe],
  imports: [
    CommonModule,
    HotelRoutingModule,
    OwlModule,    
    FormsModule,
    TypeaheadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    Ng5SliderModule,
    ModalModule.forRoot(),
    MatProgressBarModule,
    ToastrModule.forRoot(),
    SlickCarouselModule,
  ],
  exports: [LoaderComponent, FormatTimePipe],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class HotelModule { }
