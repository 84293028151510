
    <!--breadcrumb--box-->
    <div class="breadcrumb">
        <div class="container">
            <span>Home<i class="fas fa-chevron-right"></i>Booking</span>
            <span>Holding Price : {{display}}</span>
        </div>
    </div>


    <!---hotel-booking-->
    <section class="h-booking">
        <div class="container">
            <form focusOnError (ngSubmit)="ProcedToPay(bookForm.form.valid)" #bookForm="ngForm" novalidate id="bookForm">
            <div class="row">
                <div class="col-md-8">
                  <div class="hotl-info">
                    <img *ngIf="hotelDetails.Image != '' && hotelDetails.Image != null" [src]="hotelDetails.Image"
                         onError="this.src='/assets/images/hotel/not_avil.jpg'" class="hotl-img">
                    <img *ngIf="hotelDetails.Image == '' || hotelDetails.Image == null" src="/assets/images/hotel/not_avil.jpg" class="hotl-img">
                    <div class="hotl-txt">
                      <h3>{{hotelDetails.Name}}</h3>
                      <span class="str-rw">
                        <i *ngFor="let star of ' '.repeat(hotelDetails.Star).split('')" class="fas fa-star"></i>
                      </span>
                      <span class="add-txt">
                        {{hotelDetails.DisplayAdd}}
                      </span>
                    </div>
                  </div>
                    <div class="trav-info">
                        <h4>Guest Details</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="usr">Mobile No. <span style="color: #ed1300">*</span>
                                    </label>
                                    <input class="form-control"  [(ngModel)]="bookHotelModel.BookingXML.PassengerDetails.Passenger[0].MobileNumber" id="mobileno" name="mobileno" pattern="[0-9]*" maxlength="10" type="text"
                                    #mobileno="ngModel"  [ngClass]="{'is-invalid': bookForm.submitted &&  mobileno.invalid }" required>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="usr">Email id <span style="color: #ed1300">*</span>
                                    </label>
                                    <input class="form-control"  [(ngModel)]="bookHotelModel.BookingXML.PassengerDetails.Passenger[0].EMailID" id="email" name="email"  #email="ngModel"  [ngClass]="{'is-invalid': bookForm.submitted &&  email.invalid }" required maxlength="50" type="email">
                                </div>
                            </div>
                        </div>

                        <h4>Room-1</h4>

                        <div class="row form-group" *ngFor="let pax of bookHotelModel.BookingXML.PassengerDetails.Passenger;let ad=index;">
                            <div class="col-md-1 ad-txt">
                                <span>{{pax.PaxType=="ADT"? 'Adult':'Child'}}-{{ad+1}}</span>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group"><label>Title <span style="color: #ed1300">*</span></label>
                                    <select class="form-control" id="Title_{{ad}}" name="Title_{{ad}}"  [(ngModel)]="bookHotelModel.BookingXML.PassengerDetails.Passenger[ad].Title" #title="ngModel"  [ngClass]="{'is-invalid': bookForm.submitted &&  title.invalid }" required> 
                                        <option selected value="">Select</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                    </select></div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group"><label>First Name <span style="color: #ed1300">*</span></label>
                                    <input [(ngModel)]="bookHotelModel.BookingXML.PassengerDetails.Passenger[ad].FirstName" 
                                    class="form-control" id="FirstName_{{ad}}" name="FirstName_{{ad}}" 
                                    pattern="[a-zA-Z][a-zA-Z ]+" #firstname="ngModel"  [ngClass]="{'is-invalid': bookForm.submitted &&  firstname.invalid }" required maxlength="25" type="text" >
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group"><label>Middle Name </label>
                                    <input [(ngModel)]="bookHotelModel.BookingXML.PassengerDetails.Passenger[ad].MiddleName" class="form-control" id="Middlename_{{ad}}" name="Middlename_{{ad}}" 
                                    pattern="[a-zA-Z][a-zA-Z ]+" maxlength="25"
                                     type="text">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group"><label>Last Name <span style="color: #ed1300">*</span></label>
                                    <input [(ngModel)]="bookHotelModel.BookingXML.PassengerDetails.Passenger[ad].LastName"
                                     class="form-control" id="LastName_{{ad}}" name="LastName_{{ad}}" 
                                     pattern="[a-zA-Z][a-zA-Z ]+" #lastname="ngModel"  [ngClass]="{'is-invalid': bookForm.submitted &&  lastname.invalid }" required maxlength="25" type="text">
                                </div>
                            </div>
                        </div>
                        

                        <h4>Special Request (if any)</h4>
                        <div class="row form-group">
                            <div class="col-md-12">
                                <textarea class="form-control" cols="20" id="" name="SpecialRequest" rows="2" 
                                autocomplete="off"></textarea>
                            </div>
                        </div>

                    </div>
                    <div class="trav-info" *ngIf="SelectedRoom">
                        <h4>Cancellation Policy</h4>
                        <ul>
                            <li class="mb-10 ml-16" *ngFor="let Policy of SelectedRoom.CancellationPolicy.Policies.Policy;">
                                If you cancel booking between {{Policy.DateFrom}} To
                                {{Policy.DateTo}} then Cancellation Charges will be
                                {{Policy.Currency}} {{Policy.GrossPrice}}
                            </li>
                            <li class="mb-10 ml-16">
                                Note:- In case of multiple cancellation policies with same date range, after cancellation; highest cancellation charges will be applicable.
                            </li>
                        </ul>
                    </div>
                    <div class="trav-info" style="display: none;">
                        <h4>Terms and conditions</h4>
                        <ul>
                            <li class="mb-10 ml-16">
                                Guests may need ID proof with valid address, with city not same as that of hotel
                            </li>
                            <li class="mb-10 ml-16">
                                Unmarried couples may not be allowed, no refund would be provided if hotel denies check-in in such cases
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="paymt-box">
                        <h2>Trip Summary</h2>
                        <div class="paymt-box-inner">
                            <div class="chk-tmng">
                                <div class="chk-tmng-one">
                                    <label>Check In</label>
                                    <span>{{searchRequest.CheckIn}}</span>
                                </div>
                                <div class="chk-tmng-two">
                                    <i class="far fa-clock"></i>
                                    <span>{{searchRequest.Duration}} Night </span>
                                </div>
                                <div class="chk-tmng-three">
                                    <label>Check Out</label>
                                    <span>{{searchRequest.CheckOut}}</span>
                                </div>
                            </div>
                            <div class="chk-line chk-pdd">
                                <span class="chk-l">ROOM TYPE:</span>
                                <span class="chk-r">{{SelectedRoom.Type}}</span>
                                <div class="clear"></div>
                            </div>
                            <div class="chk-line chk-pdd">
                                <span class="chk-l">MEAL PLAN:</span>
                                <span class="chk-r">{{SelectedRoom.MealDesc}}</span>
                                <div class="clear"></div>
                            </div>
                            <div class="chk-line chk-pdd">
                                <span class="chk-l">NO OF ROOMS:</span>
                                <span class="chk-r">{{SelectedRoom.NoOfRoom}}</span>
                                <div class="clear"></div>
                            </div>
                            <div class="chk-line chk-pdd">
                                <span class="chk-l">GUESTS</span>
                                <span class="chk-r">{{SelectedRoom.NoOfAdult}} ADULTS, {{SelectedRoom.NoOfChild}} CHILD</span>
                                <div class="clear"></div>
                            </div>
                            <div class="chk-line chk-pdd" *ngIf="coupanStatus=='SUCCESS' && IsCoupanAmount">
                                <span class="chk-l">Coupan Discount</span>
                             
                                <span class="chk-r">{{CoupanAmount}} </span>
                                
                                <div class="clear"></div>
                            </div>
                            <div class="chk-total">
                                <div class="chk-total-book">Total</div>
                                <div class="chk-total-r">
                                    {{sharedServiceService.currencySymbol}}{{(SelectedRoom.GrossPrice)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}</div>
                                <div class="clear"></div>
                            </div>
                            <div class="promocode">
                                <p>Promocode</p>
                                <span style="color:red" *ngIf="coupanStatus=='NOTEXIST'|| coupanStatus=='EXPIRED' || coupanStatusText=='Enter Coupon'" >{{coupanStatusText}}</span>
                                <span style="color:green" *ngIf="coupanStatus=='SUCCESS'" >{{coupanStatusText}}  <a *ngIf="coupanStatus=='SUCCESS' && IsCoupanAmount"><span style="color:red ! important" class="chk-r" (click)="RemoveCoupanCode()" >Delete</span></a></span>
                               
                                <div class="form-prmo">
                                    <input type="text" placeholder="Enter promocode" id="coupanCode" name="coupanCode" value="" #coupanCode>
                                    <button type="button" class="prom-btn" (click)="ApplyCoupanCode(coupanCode.value)">Apply</button>
                                </div>
                            </div>
                            <div class="promocode gatewayborder" >
                              <ul>
                                <li class="gatewayheading">Payment GateWay</li>
                                <!--<li class="padding-gateway"><input type="radio" name="paymentgateway" id="RAZORPAY" [(ngModel)]="PaymentGateway" value="RAZORPAY">
                                    <img src="assets/images/Gateway/RazorpayPMT.png">
                                </li>-->
                                <li class="padding-gateway">
                                  <input type="radio" name="paymentgateway" id="CCAvenue" [(ngModel)]="PaymentGateway" value="CCAvenue">
                                  <img src="assets/images/Gateway/CCAvenue.png">
                                </li>
                                <li class="padding-gateway" *ngIf="IsProdution!=true">
                                  <input type="radio" name="paymentgateway" id="PAYTM" [(ngModel)]="PaymentGateway" value="PAYTM">
                                  <img src="assets/images/Gateway/PAYTM.PNG">
                                </li>
                                <li class="padding-gateway">
                                  <input type="radio" name="paymentgateway" id="PAYU" [(ngModel)]="PaymentGateway" value="PAYU">
                                  <img src="assets/images/Gateway/PAYU.PNG">
                                </li>
                                <li class="padding-gateway" *ngIf="IsProdution!=true">
                                  <input type="radio" name="paymentgateway" id="rdATOMINT" [(ngModel)]="PaymentGateway" value="ATOMINT">
                                  <img src="assets/images/Gateway/ATOM-INT.png">
                                </li>
                                <li class="padding-gateway" *ngIf="IsProdution!=true">
                                  <input type="radio" name="paymentgateway" id="rdATOMDOM" [(ngModel)]="PaymentGateway" value="ATOMDOM">
                                  <img src="assets/images/Gateway/ATOM-DOM.png">
                                </li>
                                <li class="padding-gateway">
                                  <input type="radio" name="paymentgateway" id="EASEBUZZ" [(ngModel)]="PaymentGateway" value="EASEBUZZ">
                                  <img src="assets/images/Gateway/Easebuzz_Logo.jpg"><span class="pgservicecharge"> Service Charge Applicable</span>
                                </li>
                              </ul>
                                <!-- <p></p>
                                <input type="radio" name="paymentgateway" id="rdATOM" [(ngModel)]="PaymentGateway" value="ATOMDOM">
                                <label for="rdATOM" style="padding: 5px;">ATOM(Domestic)</label>
                                <input type="radio" name="paymentgateway" id="rdATOMINT" [(ngModel)]="PaymentGateway" value="ATOMINT">
                                <label for="rdATOMINT" style="padding: 5px;">ATOM(international)</label>
                                 -->
                                
                                <!-- <label for="PAYU" style="padding: 5px;">PAYU</label> -->
                                <!-- <input type="radio" name="paymentgateway" id="NIMBBL" [(ngModel)]="PaymentGateway" value="NIMBBL">
                                <label for="NIMBBL" style="padding: 5px;">NIMBBL</label> -->
                            </div>
                            <div>&nbsp;</div>
                            <button class="btn btn-pay" type="submit" >Proceed to Pay</button>
                            <p class="trm-cnd">By booking with Sourcemytrip, you agree to our <a href="javascript:void(0)" (click)="TermConditionpopup(termcondition)">Terms and Conditions</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </div>
    </section>
    <app-loader *ngIf="loader"></app-loader>
    <app-loader *ngIf="loadercoupon"></app-loader>
    <!-- session expired popup start -->
    <div class="prc-change-over " [ngClass]="IsSessionPopUp ? 'session-expire-popup': 'session-expire-popup-h'">
        <div class="prc-change-wrap">
            <div class="close-change-hotel" routerLink="/hotel"><em class="fa fa-close"></em></div>
            <div class="sess-exp">
                <div class="change-hotel0img"><img style="width:80px;height:80px;"
                        src="assets/images/logo-main.png" alt=""></div>
                <div class="sess-title">Your session has expired</div>
                <div class="sess-det">
                    Please refresh the page. Don't worry, we keep all your
                    filters and breakdowns in place
                </div>
                <div class="ref-res"><a style="color: #fff;" routerLink="/hotel">Home</a></div>
            </div>
        </div>
    </div>
    <ng-template #termcondition>
      
      <div class="modal-header">
        <h4 class="modal-title pull-left">Terms and conditions</h4>
        <button type="button" class="close pull-right" style="background: none;" aria-label="Close"
                (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <ul>
            <li class="mb-10 ml-16">
              Hotel Standard Check-in time is 1400 hours IST and check-out time is 1200 hours.
            </li>
            <li class="mb-10 ml-16">
              Early check-in and late check-out are available on subject to availability.
            </li>
            <li class="mb-10 ml-16">
              Guests may need ID proof with valid address, with city not same as that of hotel
            </li>
            <li class="mb-10 ml-16">
              Unmarried couples may not be allowed, no refund would be provided if hotel denies check-in in such cases
            </li>
          </ul>
        </div>
      </div>
    </ng-template>
    <!-- session expired popup end -->
