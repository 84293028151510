
<div class="breadcrumbs hotel_banner" style="padding-top: 80px;">
    <div class="container">
        <div class="text-left breadcrumbs-item"  style="padding-top: 20px; padding-bottom: 20px;">
           
   
             <h2><span>Privacy</span> Policy</h2>
             <div style="content: '';display: block;width: 175px;height: 3px;background: #06608c;border-radius: 25%;margin-top: 15px;margin-bottom: 5px;"></div>
        </div>
    </div>
  </div>
  
  
  <div class="main-body pri-body"  style="padding-bottom: 30px;">
    <div class="container p-inner">
      <p>We value the trust youplace in Sourcemytrip.com. That's why we insist uponthe highest standards for secure transactions and
        customerinformation privacy. Please read the following statement to learnabout our information gathering and dissemination
        practices. Note: Our privacypolicy is subject to change at any time. To make sure you areaware of any changes, please
        review this policyperiodically.</p>
  
  
  
      <h2 class="title-section">
        <span class="">Collection of </span> Personally Identifiable Information</h2>
      <div class="cws_divider mb-25"></div>
      <p>We collect personally identifiable information (email address) from you when youset up a free account with Sourcemytrip.com.
        While you can browse some sections ofour site without being a registered member, certain activities (such as searchingfor
        properties, viewing property contact information, and registering a property)do require registration. We do use your
        contact information to send youoffers as well as results of your 'Aap ki Price' quote. You may always opt-outof receiving
        such information; see the Choice/Opt-Out section below.</p>
  
      <h2 class="title-section">
        <span class="">Use of </span> Demographic and ProfileData</h2>
      <div class="cws_divider mb-25"></div>
      <p>
        We use personal information to provide the services you request. To the extentwe use your personal information to market
        to you, we will provide you the abilityto opt-out of such uses. We use your personal information to resolve disputes;troubleshoot
        problems; help promote a safe service; collect fee sowed; measureconsumer interest in our products and services, inform
        you about online and offlineoffers, products, services, and updates; customize your experience; detect andprotect us
        against error, fraud and other criminal activity; enforce our termsand conditions; and as otherwise described to you
        at the time of collection. In our efforts to continually improve our productand service offerings, we collect and analyze
        demographic and profile dataabout our users' activity on our website. We identify and use your IP address to helpdiagnose
        problems with our server, and to administer our website. Your IPaddress is also used to help identify you and to gather
        broad demographicinformation. We will occasionally ask you to complete optional onlinesurveys. These surveys may ask
        you for contact information and demographicinformation (like zip code, age, or income level).We use this data to tailoryour
        experience at our site, providing you with content that we think you mightbe interested in--and to display content
        according to your preferences.</p>
  
      <h5>Cookies</h5>
      <p>A "cookie" is a small piece of information stored by a Web server ona Web browser so it can be later read back from that
        browser. Cookies are usefulfor enabling the browser to remember information specific to a given user. Sourcemytrip.complaces
        both permanent and temporary cookies in your computer?s hard drive. Sourcemytrip.com cookies do not contain any of your
        personally identifiable information.</p>
  
  
      <h2 class="title-section">
        <span class="">Sharing of </span> personal information</h2>
      <div class="cws_divider mb-25"></div>
      <p>
        We may share personal information without other corporate entities and affiliatesto: help detect and prevent identity theft,
        fraud and other potentially illegalacts; correlate related or multiple accounts to prevent abuse of our services;and
        to facilitate joint or co-branded services that you request where suchservices are provided by more than one corporate
        entity. Those entities andaffiliates may not market to you as a result of such sharing unless youexplicitly opt-in.
        We may disclose personal information if required todo so by law or in the good faith belief that such disclosure is
        reasonablynecessary to respond to subpoenas, court orders, or other legal process. We maydisclose personal information
        to law enforcement offices, third party rightsowners, or others in the good faith belief that such disclosure is reasonablynecessary
        to: enforce our Terms or Privacy Policy; respond to claims that anadvertisement, posting or other content violates
        the rights of a third party;or protect the rights, property or personal safety of our users or the generalpublic. Sourcemytrip.com
        and its affiliates will share some orall of your personal information with another business entity should we (or ourassets)
        plan to merge with, or be acquired by that business entity. Should sucha transaction occur, that other business entity
        (or the new combined entity)will be required to follow this privacy policy with respect to your personalinformation.</p>
  
  
      <h2 class="title-section">
        <span class="">Links to </span> Other Sites</h2>
      <div class="cws_divider mb-25"></div>
      <p>
        Our site links to other websites that may collect personally identifiableinformation about you. Sourcemytrip.com is not responsible
        for the privacypractices or the content of those linked websites.</p>
  
  
      <h2 class="title-section">
        <span class="">Security </span> Precautions</h2>
      <div class="cws_divider mb-25"></div>
      <p>
  
        Our site has stringent security measures in place to protect the loss, misuse,and alteration of the information under our
        control. Whenever you change oraccess your account information, we offer the use of a secure server. Once yourinformation
        is in our possession we adhere to strict security guidelines,protecting it against unauthorized access.</p>
  
  
      <h2 class="title-section">
        <span class="">Choice/ </span> Opt-Out</h2>
      <div class="cws_divider mb-25"></div>
      <p>
          Sourcemytrip.com provides all users with the opportunity to opt-out of receivingon-essential(promotional, marketing-related)
        communications from us on behalfof our partners, and from us in general, after setting up an account. To remove your
        contact information from all Sourcemytrip.comlists and newsletters, Please contact us at the address below.</p>
  
  
      <h2 class="title-section">
        <span class="">Questions?</span>
      </h2>
      <div class="cws_divider mb-25"></div>
      <p>
        Questions regarding this statement should be directed to the following address:</p>
      <p>Sourcemytrip Pvt. Ltd
        <br> chandra@Sourcemytrip.com
      </p>
  
  
  
  
  
  
  
  
  
  
    </div>
  </div>