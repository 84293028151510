   <!--modify-box--->
   <div class="hotel-modify">
    <div class="container">
        <form>
            <div class="hot-mod-inner">
                <div class="row-up-txt-2">
                    Modify Search
                </div>
                <form (ngSubmit)="ModifyResult(searchForm.form.valid)" #searchForm="ngForm" novalidate id="hsearchForm">
                <div class="row">
                    <div class="col-md-4 col-hotel-2">
                        <div class="form-group">
                            <input [(ngModel)]="DestinationCity" [typeahead]="hotelResulList" 
                            [isAnimated]="true" typeaheadWaitMs="1000" [typeaheadScrollable]="true"
                            [typeaheadOptionsInScrollableView]="20" placeholder="Destination or Hotel name"
                            [typeaheadItemTemplate]="hotelSearchTemplate" autocomplete="off"
                            [ngClass]="{'is-invalid': searchForm.submitted && hotelFromCity.invalid || !fromCityValid}" 
                            (typeaheadOnSelect)="formatHotelCity($event)" class="form-control" name="hotelFromCity" (click)="$event.target.select()"
                            #hotelFromCity="ngModel"  required>                            
                        </div>
                        <ng-template #hotelSearchTemplate let-model="item" let-index="index">
                            <div class=" fxLayout" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="0">                
                              <div class="item item-1" fxFlex="85%">                
                                <div class="SearchAirportcity">
                                    <i class="fas fa-map-marker-alt"></i>                                
                                   {{ model.CityName }} ({{model.CityCode}}), <span>
                                    {{model.CountryName}}</span>                
                                </div>
                              </div>                                     
                            </div>
                          </ng-template>
                    </div>
                    <div class="col-md-2 col-hotel-2" (click)="checkin.show()">
                        <div class="form-group">                          
                            <input name="checkINDate" style="position: absolute; z-index: -1;"  bsDatepicker class="form-control" #checkin="bsDatepicker"
                         bsDatepicker [outsideClick]="true" [(ngModel)]="HotelSearchModel.Request.Master.CheckIn" 
                         [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'YYYY-MM-DD' }"  (bsValueChange)="HotelFromDateChange($event);" [minDate]="minDate"  [maxDate]="fromMax" >
                         <span class="form-control" style="padding-top: 14px;">
                            {{HotelSearchModel.Request.Master.CheckIn | date: "dd MMM yyyy" }}
                        </span>
                        </div>
                    </div>
                    <div class="col-md-2 col-hotel-2" (click)="checkout.show()">
                        <div class="form-group">
                            <input name="checkOutDate"   bsDatepicker class="form-control" style="position: absolute; z-index: -1;"  #checkout="bsDatepicker"
                        bsDatepicker [outsideClick]="true" [(ngModel)]="HotelSearchModel.Request.Master.CheckOut" 
                        [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'YYYY-MM-DD' }"  [minDate]="minCheckoutDate">
                        <span class="form-control" style="padding-top: 14px;">
                            {{HotelSearchModel.Request.Master.CheckOut | date: "dd MMM yyyy" }}
                        </span>    
                    </div>
                    </div>
                    <div class="col-md-3 col-hotel-2">
                        <div class="modi-dropdn">
                            <button type="button" class="dropbtn" onclick="dropfuntion()">
                                <span>{{GuestCount}} Person {{HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length}} Room</span>
                            </button>
                            <div class="drop-content">
                                <div *ngFor="let room of HotelSearchModel.Request.RequestDetails.Search.Rooms.Room;let i=index;">
                                <div class="rm-txt">
                                    Room {{i+1}}:
                                </div>
                                <div class="left-box">
                                    <span class="txt">
                                        <span id="txt-innr">Adult <em>(Above 12 years)</em></span>
                                    </span>
                                </div>
                                <div class="right-box">
                                    <div class="pls-mns-row">
                                        <a type="button" id="adt-min" class="sub" *ngIf="room.Adult>1"  (click)="DecreaseGuest('A',i)">-</a>
                                        <span id="adlt-nbr" class="adlt-number">
                                            {{room.Adult}}
                                        </span>
                                        <a type="button" id="adt-plus" class="plus" *ngIf="room.Adult<4" (click)="IncreaseGuest('A',i)">+</a>
                                    </div>
                                </div>
                                <div class="space-rw">
                                </div>
                                <div class="left-box">
                                    <span class="txt">
                                        <span id="txt-innr">Child <em>(Below 12 years)</em></span>
                                    </span>
                                </div>
                                <div class="right-box">
                                    <div class="pls-mns-row">
                                        <a type="button" id="adt-min" class="sub"  *ngIf="room.Child>0"  (click)="DecreaseGuest('C',i)">-</a>
                                        <span id="adlt-nbr" class="adlt-number">
                                            {{room.Child}}
                                        </span>
                                        <a type="button" id="adt-plus" class="plus" *ngIf="room.Child<4" (click)="IncreaseGuest('C',i)">+</a>
                                    </div>
                                </div>
                                <div class="space-rw">
                                </div>

                                <div class="right-box" *ngIf="room.Child>0">
                                    <div class="pls-mns-row">
                                        <div *ngFor="let age of HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray;let c=index;">
                                            <select [(ngModel)]="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[c]" name="age{{i}}{{c}}"> 
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                            <option>6</option>
                                            <option>7</option>
                                            <option>8</option>
                                            <option>9</option>
                                            <option>10</option>
                                            <option>11</option>
                                            <option>12</option> 
                                          </select>
                                        </div>  
                                    </div>
                                </div>
                            </div>
                                <div class="clear"></div>
                                <a class="add-room" *ngIf="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length<5" (click)="AddRoom()">Add Room
                                </a>
                                <a class="add-room" *ngIf="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length>1" (click)="RemoveRoom()">Remove Room
                                </a>                             
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1 col-hotel-2">
                        <button #searchButton type="submit" hidden></button>
                        <a (click)="myFormSubmitHandler()" href="javascript:void(0)"class="hotel-sub-btn">
                            <i class="fas fa-search"></i>
                        </a>

                    </div>
                </div>
            </form>
            </div>
        </form>
    </div>
</div>

   <!--result-box-->
   <div class="result-area" >
    <div class="container searchbar" *ngIf="hotelSuppliers!=null? hitcount != hotelSuppliers.length:hotelSuppliers" style="padding-bottom: 15px;">
        <div class="row">
          <div class="col-sm-12">
            <h4>Getting the best deals from top hotels...</h4>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      
          </div>
      
        </div>
      </div>
    <div class="container">
        <div class="loader-hotel" *ngIf="!showResult && !noResultFound">
            <!-- <img src="/assets/images/travel-fare-img/hotel-loading.gif" class="img-fluid"> -->
            <app-hotellistloader></app-hotellistloader>
          </div>
          <div  class="loader-hotel error-four-main" *ngIf="noResultFound">
            <div class="res-no-data">
            <div class="four-img">
                <img src="assets/images/no-result-img.png" alt="No Result Found."/>
            </div>
            <div class="four-page-not">
               <span>Oops !</span> No Result Found
            </div>
            <div class="nf-details">
                We could not find any result as per your search criteria.

            </div>
            <div class="nf-details1">                
                Please update your search criteria and do the search again.
            </div>
            <div class="src-again" style="padding-bottom:20px;">
                <button type="submit" class="slt-hotel radius-20" (click)="myFormSubmitHandler()">
                Search Again
            </button></div>
        </div>  
            </div>
        <div class="row" *ngIf="showResult">
            <div class="col-md-3 fltr-hotel">
                <div class="side-box">
                    <div class="cats-title">
                        Hotel Name
                    </div>
                    <div class="form-area">
                        
                        <input [(ngModel)]="HotelName" (keyup)="ApplyFilter();" autocomplete="disabled" placeholder="Search by Hotel Name" class="form-control" name="hotelName">
                        <!-- <input [(ngModel)]="HotelName" [typeahead]="hotelNameList" [isAnimated]="true"
                        typeaheadWaitMs="1000" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="20"
                        placeholder="Search by Hotel Name" [typeaheadItemTemplate]="hotelSearchTemplate"
                        (typeaheadOnSelect)="formatHotelName($event)" class="form-control" name="hotelName" id="hotelName"> -->
                        <i *ngIf="!isHotelName" class="fa fa-search" style="position: absolute;right: 35px;bottom: 16px;"></i>
                        <i *ngIf="isHotelName" class="fas fa-times" style="position: absolute;right: 35px;bottom: 16px;" (click)="ClearHotel()"></i>
                      <ng-template #hotelSearchTemplate let-model="item" let-index="index">
                        <div class=" fxLayout" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="0">
                          <div class="item item-1" fxFlex="10%">                            
                            <i class="fas fa-hotel" style="color: #E1E1E1;font-size: 16px;"></i>
                          </div>
                          <div class="item item-1" fxFlex="90%">
                            <div class="SearchAirportcity">
                              {{ model }}
                            </div>
                          </div>
                        </div>
                      </ng-template>
                </div>
                    <div class="side-spc"></div>
                    <div class="cats-title">
                        Price
                    </div>
                    <div class="slidecontainer">
                        <ng5-slider [(value)]="minMaxValues.priceMinValue" [(highValue)]="minMaxValues.priceMaxValue"
                        [options]="priceOptions" (userChangeEnd)="PriceChange()">
                        </ng5-slider>
                    </div>
                </div>
                <div class="side-box">
                    <div class="cats-title">
                        Popular
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="customCheck-Free-Cancellation" name="custm" 
                        (click)="IsFreeCancellation=!IsFreeCancellation;ApplyFilter();">
                        <label class="custom-control-label" for="customCheck-Free-Cancellation">Free Cancellation </label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="customCheck-offer" name="custm" 
                        (click)="IsRecommendedHotel=!IsRecommendedHotel;isStarLowToHigh=null;isStarHighToLow=null;
                        isPriceLowToHigh=null;isPriceHighToLow=null;ApplyFilter();">
                        <label class="custom-control-label" for="customCheck-offer">Offer</label>
                    </div>
                    
                </div>
                <div class="side-box">
                    <div class="cats-title">
                        Star Rating
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="customCheck-1" name="custm" (click)="StarChange(4)">
                        <label class="custom-control-label" for="customCheck-1">5 Star ({{HotelFilterModel.Ratings[4].count}})</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="customCheck-2" name="custm" (click)="StarChange(3)">
                        <label class="custom-control-label" for="customCheck-2">4 Star ({{HotelFilterModel.Ratings[3].count}})</label>
                    </div>
                    <div class="custom-control custom-checkbox mb-1">
                        <input type="checkbox" class="custom-control-input" id="customCheck-3" name="custm" (click)="StarChange(2)">
                        <label class="custom-control-label" for="customCheck-3">3 Star ({{HotelFilterModel.Ratings[2].count}})</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck-4" name="custm" (click)="StarChange(1)">
                        <label class="custom-control-label" for="customCheck-4">2 Star ({{HotelFilterModel.Ratings[1].count}})</label>
                    </div>
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck-5" name="custm" (click)="StarChange(0)">
                        <label class="custom-control-label" for="customCheck-5">1 Star ({{HotelFilterModel.Ratings[0].count}})</label>
                    </div>
                </div>
                

                <div class="side-box" style="max-height: 200px; overflow-y: scroll;"
                *ngIf="HotelFilterModel.Inclusions !=null && HotelFilterModel.Inclusions.length>0">
                    <div class="cats-title">
                        Inclusions
                    </div>
                    <div class="custom-control custom-checkbox mb-1" *ngFor="let inc of HotelFilterModel.Inclusions;let i=index;">
                        <input type="checkbox" class="custom-control-input" id="customCheckp_inc_{{i}}" [(ngModel)]="inc.value" (change)="ApplyFilter();" name="custm">
                        <label class="custom-control-label" for="customCheckp_inc_{{i}}">{{inc.name}}</label>
                    </div>
                </div>
            </div>
            <div class="col-md-9 rigt-box-hotel" *ngIf="FilteredHotels.length>0">
                <div class="tot-hotels">                    
                    {{getHotelSearchSourceAndDestination()}}
                </div>
                <div class="sort-bar">
                    <div class="srt-lbl">
                        Sort by:
                    </div>
                    <div class="srt-li"  [ngClass]="{'activeClass':IsRecommendedHotel}"
                    (click)="IsRecommendedHotel=!IsRecommendedHotel;isStarLowToHigh=null;isStarHighToLow=null;
                    isPriceLowToHigh=null;isPriceHighToLow=null;ApplyFilter();">
                        Recommended
                    </div>
                    <div class="srt-li" [ngClass]="{'activeClass':isStarLowToHigh}"
                    (click)="IsRecommendedHotel=false;isPriceLowToHigh=null;isPriceHighToLow=null;StarClick('L')">
                        Star - Low to High
                    </div>
                    <div class="srt-li" [ngClass]="{'activeClass':isStarHighToLow}"
                    (click)="IsRecommendedHotel=false;isPriceLowToHigh=null;isPriceHighToLow=null;StarClick('H')">
                        Star - High to Low
                    </div>
                    <div class="srt-li" [ngClass]="{'activeClass':isPriceLowToHigh}"
                    (click)="IsRecommendedHotel=false;isStarLowToHigh=null;isStarHighToLow=null;PriceClick('L')">
                        Price - Low to High
                    </div>
                    <div class="srt-li" [ngClass]="{'activeClass':isPriceHighToLow}"
                    (click)="IsRecommendedHotel=false;isStarLowToHigh=null;isStarHighToLow=null;PriceClick('H')">
                        Price - High to Low
                    </div>
                </div>
                <div class="hotel-item" *ngFor="let hotel of FilteredHotels | slice:0:showMoreCount">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="img-hotl">
                                <div *ngIf="hotel.Offer=='Y'" class="htl_offer"><img src="/assets/images/HTLoffer.png"></div>
                                <div *ngIf="hotel.Refundablenfo != null && hotel.Refundablenfo != '' " class="Overlay__TopLeft HeroImage__CombinedBadgeContainer">
                                    <div class="sc-bdfBwQ gNRfUM sc-JAcuL eKGqnO DefaultBadge DefaultBadge--OptionIncluded">
                                        <div>
                                            <span>{{hotel.Refundablenfo}}</span>
                                        </div>
                                    </div>
                                </div>
                                <img *ngIf="hotel.Image != '' && hotel.Image != null" [src]="hotel.Image"
                                    onError="this.src='/assets/images/hotel/not_avil.jpg'" class="img-fluid">
                                <img *ngIf="hotel.Image == '' || hotel.Image == null" src="/assets/images/hotel/not_avil.jpg" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-md-5 bl-r">
                            <div class="hotel-item-cnt">
                                <h3>{{hotel.Name}} <span class="str-rw">
                                    <i *ngFor="let star of ' '.repeat(hotel.Star).split('')" class="fas fa-star"></i>
                                </span> <span  *ngIf="!IsProdution">--Provider-{{hotel.Provider}} </span></h3>
                                
                                <div class="addrs">
                                    <i class="fas fa-map-marker-alt"></i>
                                    <span>
                                        {{hotel.DisplayAdd}}
                                    </span>
                                </div>
                                <div *ngIf="hotel.SupCode=='TBO' && hotel.Desc !=null  && hotel.Desc !=''" class="addrs">
                                    <i class="fas fa-info-circle"></i>
                                    <span>{{hotel.Desc.length>145? (hotel.Desc | slice:0:143)+' ..' : hotel.Desc}}
                                        
                                    </span>
                                </div>
                                <ol class="olinclude" *ngIf="hotel.Inclusions !=null && hotel.Inclusions.length>0">
                                    <li class="liinclude" *ngFor="let inc of hotel.Inclusions.slice(0,3)">{{inc}}</li>
                                    <li class="liinclude" *ngIf="hotel.Inclusions.length>3">+{{hotel.Inclusions.length-3}}</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-3 price-r">
                            <div class="tot-price">
                                {{sharedServiceService.currencySymbol}}
                                <span>{{(hotel.MinPrice)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}<small>
                                        Price for 1 Night
                                    </small></span>
                                <button type="submit" class="slt-hotel" (click)="GetHotelRoom(hotel.HotelId,hotel.Token,hotel.Provider)">
                                    Select Hotel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container" *ngIf="showResult">
                    <div class="row">
                      <div class="col-lg-12 text-center" *ngIf="FilteredHotels.length>showMoreCount">
                        <button class="slt-hotel" (click)="showMoreCount=showMoreCount+50">Show More</button>
                      </div>
                    </div>
                  </div>

            </div>


             <!--no-filter-result-->
             <div  class="loader-hotel" *ngIf="FilteredHotels.length==0" style="width: 75% !important;">
                <div class="res-no-data error-four-main">
                  <div class="four-img" >
                      <img src="assets/images/filter-no-result.png"  alt="No Result Found."/>
                  </div>
                  <div class="four-page-not">
                     <span>Oops !</span> No Result Found
                  </div>
                  <div class="nf-details">
                    We could not find any result as per your filter criteria.
      
                  </div>
                  <div class="nf-details1">                
                    Try a different combination of filters.
                  </div>
                  <div class="src-again" style="padding-bottom:20px;">
                     </div>
              </div>            
                </div>
                <!--no-filter-result end-->
        </div>
    </div>
</div>

<app-loader *ngIf="loader"></app-loader>