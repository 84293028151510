<!--Forget Password-->
<div class="login-box">
    <div class="login-head">
        <h2>Forget Password</h2>
        <p>Already have an account? <a href="javascript:void(0);" (click)="gotologin()">Log In</a></p>
    </div>
    <div class="field-box">
        <div class="row">
            <div class="col-md-12 b-r">
                <form (ngSubmit)="LoginUser(loginForm.form.valid)" #loginForm="ngForm" novalidate>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email"
                            [(ngModel)]="loginModel.UserRQ.RequestBody.UserDetails.UserName"
                            [ngClass]="{'is-invalid': loginForm.submitted && Email.invalid}" #Email="ngModel" required
                            name="Email">
                    </div>
                    <button type="submit" hidden #loginbtn></button>
                    <a href="javascript:void(0)" (click)="myFormSubmitHandler()" class="submit_login">Submit</a>
                </form>
            </div>
        </div>
    </div>

</div>

<app-loader *ngIf="loader"></app-loader>