import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../Service/common.service';
import { Authentication, RequestBody, RequestHeader, UserDetails, UserRQ, loginRegisterModel } from '../Model/loginRegisterModel';
import { NavigationService } from '../Service/navigation.service';
import { ToastrService } from 'ngx-toastr';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-resetPassword',
  templateUrl: './resetPassoword.component.html',
  styleUrls: ['./resetPassoword.component.css']
})
export class ResetPassowordComponent implements OnInit {
  @ViewChild(HeaderComponent,{static:false}) headerComponent : HeaderComponent;
suppliedToken:string='';
timeOut:number=0;
showTimeOutScreen:boolean= false ;
resetPasswordSuccessed : boolean = false;
userName:string = '';
loginModel: loginRegisterModel = new loginRegisterModel();
  constructor(private activatedRoute: ActivatedRoute,
    private navigationService: NavigationService, 
    private toastr: ToastrService,
    private commonService: CommonService) {
    
   }
   
  ngOnInit() {
    
    this.loginModel.UserRQ = new UserRQ();
    this.loginModel.UserRQ.Authentication = new Authentication();
    this.loginModel.UserRQ.RequestHeader = new RequestHeader();
    this.loginModel.UserRQ.RequestHeader.Method = "ResetPassword";
    this.loginModel.UserRQ.RequestBody = new RequestBody();
    this.loginModel.UserRQ.RequestBody.UserDetails = new UserDetails();
    //this.headerComponent.SignInRegisterclick('Login');
    this.activatedRoute.queryParams.subscribe(params => {
      this.suppliedToken = params.token;
      this.loginModel.UserRQ.RequestHeader.Token = params.token;
       this.commonService.GetEmailByToken(this.loginModel).subscribe(data => {
          var alldata = data as any;
          this.timeOut = alldata.UserRS.ResponseBody.UserDetails.TimeOut;
          this.userName = alldata.UserRS.ResponseBody.UserDetails.UserName;
          if (this.timeOut > 15){
            this.showTimeOutScreen = true;
          } 
       },err => {
        this.toastr.error('Wrong Username/Password.');
      });
  });
  
  }
  ResetAgain = () =>{
      this.navigationService.SetLoginRegister('ForgetPassword');
  }
  onSubmit = () => {
    if (this.timeOut > 15){
      this.showTimeOutScreen = true;
      return;
    }    
    this.showTimeOutScreen = false;
    this.loginModel.UserRQ.RequestBody.UserDetails.UserName = this.userName;
    this.loginModel.UserRQ.RequestBody.UserDetails.Password = this.newPassword;
    this.loginModel.UserRQ.RequestHeader.Method = "ResetPassword";
    this.loginModel.UserRQ.RequestHeader.Token = this.suppliedToken.replace(/\s/g, '+');
    this.commonService.RestPassword(this.loginModel).subscribe(data => {
        var obj = (data) as any;
        this.resetPasswordSuccessed = false;
        if (obj.UserRS.ResponseHeader.Status == "SUCCESS") { 
          this.resetPasswordSuccessed = true;       
        this.toastr.success('Password has been reset !');
      }
    else if (obj.UserRS.ResponseHeader.Status == "NOTEXIST") {        
        this.toastr.info('The link has been expired !');
    }
    else {
      this.toastr.error('Error !');
    }
      },err => {
       //this.loader = false;
       this.toastr.error('Error !');
     });
  }
   newPassword:string='';
   confirmPassword:string='';
 onPasswordChange = (value) =>{
  this.newPassword = value;
 }
 onConfirmPasswordChange = (value) =>{
  this.confirmPassword = value;
 }
 validatePassword = () =>{
  if(this.newPassword != '')
  return !(this.newPassword === this.confirmPassword) ;
    return true;
  }
}
