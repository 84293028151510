
<div class="breadcrumbs hotel_banner" style="padding-top: 80px;">
    <div class="container">
        <div class="text-left breadcrumbs-item" style="padding-top: 20px; padding-bottom: 20px;">
          
  <h2><span>Cancellation</span> Policy</h2>
  <div style="content: '';display: block;width: 250px;height: 3px;background: #06608c;border-radius: 25%;margin-top: 15px;margin-bottom: 5px;"></div>
        </div>
    </div>
  </div>
  
  <div class="main-body pri-body" style="padding-bottom: 30px;">
      <div class="container p-inner">
      <p>All Bookings made through www.Sourcemytrip.com & agents.Sourcemytrip.com are considered Refundable or Non-refundable based on
        the Fare Rules mentioned on that flight while booking. Apart from Airlines cancellation charges. Sourcemytrip Pvt
        Ltd will charge additional {{CompanySetting.Currency}} 100 (Incl of all taxes) as their service charge.</p>
      <p>Cancellation requests sent 4 hrs prior to flight departure will be considered valid cancellation request. Cancellation
        request sent within after Scheduled flight departure time will be considered under No Show and applicable NO Show Charges
        as applied by the Operating airlines will charged. Sourcemytrip Pvt Ltd will charge additional {{CompanySetting.Currency}} 100 (Incl of
        all taxes) as their service charge.</p>
      <p>In case the client is cancelling a tour/travel services or any other booking due to any avoidable / unavoidable circumstances
        we must be informed in written via mail.And as per the rule, cancellation charges would be effective from the date
        we receive letter in written and following amounts will be retained:</p>
      <ul>
        <li>60 days & prior to arrival - 10% of the tour/service cost.</li>
        <li>59 days to 30 days prior to arrival - 20% of the tour/service cost.</li>
        <li>29 days to 15 days prior to arrival - 25% of the tour/service cost.</li>
        <li>14 days to 08 days prior to arrival - 50% of the tour/service cost.</li>
        <li>07 days & less, prior to arrival or no show - NO REFUND</li>
      </ul>
      <p>Cancellation for Hotel Booking will be in accordance applicable cancellation policy of respective hotel, Sourcemytrip Pvt Ltd will charges cancellation fee of {{CompanySetting.Currency}} 300per booking over and above the Cancellation charges imposed by respective
        Hotel
      </p>
  
    
  
      <h2 class="title-section">
        <span class="">Refund </span> Policy</h2>
      <div class="cws_divider mb-25"></div>
      <p>
        All normal cancellation requests refund will be processed within 48 hr of flight cancellation.</p>
      <p>If flight booked from deposit mode refund will reflect in the agent account. If we have received the payment through
        Online Transaction the refund will credited to respective account. Payment made using any other form of payment will
        refunded by Cheque within a period of 45 days from dated of receipt of request All refund shall be processed subject
        to processing of refunds by the respective airline/service provider .</p>
      <p>No show refund will be processed once we received the refund from the airline. No show refund may take time up to 45
        days.</p>
      <p>In case the client is cancelling a tour/travel services or any other booking due to any avoidable/unavoidable circumstances
        we must be informed in written via mail.And as per the rule, cancellation charges would be effective from the date
        we receive letter in written and following amounts will be retained: </p>
      <p>Refund due to cancellation of Hotel booking will be strictly in accordance with Refund Policy of the respective hotel
        and refund charges will be Credited upon approval of Refund & payment from respective Hotel!!</p>
  
  
  
  
  
  
  
  
  
  
    </div>
  </div>