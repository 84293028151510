
<div class="breadcrumbs hotel_banner" style="padding-top: 80px;">
    <div class="container">
        <div class="text-left breadcrumbs-item"  style="padding-top: 20px; padding-bottom: 20px;">
            
        <h2><span *ngIf="!resetPasswordSuccessed">Select a new paasword for your account</span> </h2>
        <h2><span *ngIf="resetPasswordSuccessed">Reset Password Successfull. Login with new password. </span> </h2>
        
    </div>
  </div>
  
  <div class="main-body" *ngIf="!resetPasswordSuccessed"  style="padding-bottom: 30px;">
    <div class="container">        
        <div class="row" style="padding-top: 20px;background-color: #fff" *ngIf="showTimeOutScreen">
            <div class="col-md-5" style="box-shadow: 0 0 1px 2px #b2b2b27a;padding: 15px;">
                <div class="row">                    
                    <div class="col-md-12" style="padding-bottom: 10px;">
                        <b style="color:red">Password Rest Option Time-Out</b>
                        <br/><b>You will need to do antother request to reset/change your password.</b>
                        <a [routerLink]="['']"><input type="button" style="background-color:red" class="btn btn-primary mb1 bg-red" value="Reset Again!" name="Reset Again!"  (click)="ResetAgain()"></a>
                        
                    </div>                    
                </div>
            </div>
        </div>       
      <div class="row" style="padding-top: 20px;background-color: #fff" *ngIf="!showTimeOutScreen">  
        <div class="col-md-5" style="box-shadow: 0 0 1px 2px #b2b2b27a;padding: 15px;">
            <div class="row">
                <div class="col-md-1" style="padding-bottom: 10px;">
                </div>
                <div class="col-md-11" style="padding-bottom: 10px;">
                    <b>Your registered email id is <span style="color:blue;">{{userName}}</span>  </b>
                </div>               
                
            </div>
            <form method="post" action="#">
                <div class="row">
                    <div class="col-md-1" style="padding-bottom: 10px;">
                    </div>
                    <div class="col-md-8" style="padding-bottom: 10px;">
                        <label class="custom-control-label1"  for="newPassword"><b>New Password</b></label>
                        <input type="newPassword" [value]="newPassword" (change)="onPasswordChange($event.target.value)"  class="form-control" placeholder="New Password">
                    </div>
                    
                </div>
  
                <div class="row">
                    <div class="col-md-1" style="padding-bottom: 10px;">
                    </div>
                    <div class="col-md-8 mat-n-t" style="padding-bottom: 10px;">
                        <label class="custom-control-label1" for="confirmPassword"><b>Confirm Password</b></label>
                        <input type="confirmPassword" [value]="confirmPassword" (change)="onConfirmPasswordChange($event.target.value)"  class="form-control" placeholder="Confirm Password">
                        <label style="color: red;" *ngIf="(confirmPassword != '') && (confirmPassword !== newPassword)"><b>Confirm password does not match with new password</b></label>
                    </div>
                </div>
  
              
                <div class="row mar-t10" style="padding-bottom: 10px;">
                    <div class="col-md-1" style="padding-bottom: 10px;">
                    </div>
                    <div class="col-md-8"> <input type="button" class="btn btn-primary" value="Submit" name="submit-contact" [disabled]="validatePassword()" (click)="onSubmit()"></div>
                </div>
            </form>
        </div>  
    </div>
    </div>
  </div>
