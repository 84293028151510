import { HotelService } from './../Service/hotel.service';
import { AccountService } from './../Service/account.service';
import { BookingEnquiryRequest, RequestBody, CityName, DateType,SubAgent,Passenger } from './../Model/MyBookingModel';
import { Component, OnInit } from '@angular/core';
import { UserDetails } from '../Model/loginRegisterModel';
import { MyBookingModel,Authentication } from '../Model/MyBookingModel';
import { VoucherModel, BookingDetailRequest as BookingDetailRequestVM,Authentication as AuthenticationVM, Header, RequestBody as RequestBodyVM,SubAgent as SubAgentVM, BookingCancelModel } from './../Model/VoucherModel';
import { BookingDetailModel, BookingDetailRequest } from '../Model/BookingDetailModel';
import { CompanySettingService } from '../Service/company-setting.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TabHeadingDirective } from 'ngx-bootstrap';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit {

  userDetails:UserDetails;
  myBookingModel:MyBookingModel;
  allBookingList:any[];
  allBookingListFilter:any[];
  showBookingDetail:boolean=false;
  showBookingFlightDetail:boolean=false;
  bookingDetail:any;
  bookingDetailModel:BookingDetailModel;
  loader:boolean;
  totalBookings:number;
  filterText:string;

  clientID:number;
  voucherModel:VoucherModel;
  BookingCancelModel:BookingCancelModel
  showVoucher:boolean;
  htmlStr:any;
  isTicketIssued:boolean;
  isCancelled:boolean;
  isUnConfirmed:boolean;
  isTicketVoid:boolean;
  isRefundedFromPG:boolean;
  isBookingNotFound:boolean;
  showInvoice:boolean;
  htmlStrInvoice: any;
  htmlStrCanxPolicy: any;
  ServiceTyepeValue:string;
  CompanySetting = CompanySettingService;
  CancellationChargedata: any;
  showBookingPackage:boolean=false;
  showBookingSeightSeeing:boolean=false;
  showBookingTransfer:boolean=false;
  tabactveprofile:string="active";
  tabshowactiveprofile:string="show active";
  tabactvebooking:string="active";
  tabshowactivebooking:string="show active";
  isAllowedCancellation:boolean=false;
  isEmailVoucher:boolean=false;
  MobileNo:string="";
  Email:string="";
  constructor(private _accountService:AccountService,private hotelService :HotelService,private toastr: ToastrService,private location: Location,private router: Router) { 
    
    var userDetailJSON=localStorage.getItem("UserDetails");
    if(userDetailJSON!=null && userDetailJSON!=undefined){
    this.userDetails=JSON.parse(userDetailJSON);
     
    }
    this.initilizeBookingRequest();
    if(router.url=="/account/MyBookings")
    {
      this.tabactveprofile="";
      this.tabshowactiveprofile="";
      this.tabactvebooking="active";
     this.tabshowactivebooking="show active";
     this.getMyBookings();
    }
    else
    {
      this.tabactveprofile="active";
      this.tabshowactiveprofile="show active";
      this.tabactvebooking="";
     this.tabshowactivebooking="";
    }
  }

  ngOnInit() {
   
   
  }
  initilizeBookingRequest() {
    this.myBookingModel=new MyBookingModel();
    this.myBookingModel.BookingEnquiryRequest=new BookingEnquiryRequest();
    this.myBookingModel.BookingEnquiryRequest.Authentication=new Authentication();
    this.myBookingModel.BookingEnquiryRequest.Authentication.SubAgent=new SubAgent();
    this.myBookingModel.BookingEnquiryRequest.Header=new Header();
    this.myBookingModel.BookingEnquiryRequest.RequestBody=new RequestBody();
    this.myBookingModel.BookingEnquiryRequest.RequestBody.Passenger=new Passenger();
    this.myBookingModel.BookingEnquiryRequest.RequestBody.CityName=new CityName();
    this.myBookingModel.BookingEnquiryRequest.RequestBody.DateType=new DateType();

    this.myBookingModel.BookingEnquiryRequest.Header.Method="SearchBooking";
    this.myBookingModel.BookingEnquiryRequest.RequestBody.ClientId=this.userDetails.ClientId;
  }

  getMyBookings(){
    this.loader=true;
      this._accountService.getBookingList(this.myBookingModel).subscribe(data=>{ 
        this.loader=false;
        var bookingData=data as any;
       if(bookingData.BookingEnquiryResponse.Header.Status=="SUCCESS")
       this.allBookingList=bookingData.BookingEnquiryResponse.ResponseBody.Booking;
       this.allBookingListFilter=bookingData.BookingEnquiryResponse.ResponseBody.Booking;
       this.totalBookings=this.allBookingList.length;
       this.showBookingSeightSeeing=false;
       this.showBookingPackage=false;
       this.showBookingDetail=false;
       this.showBookingFlightDetail=false;
       this.serviceType='All';
       this.serviceTypeFilter='All';
       //this.router.navigateByUrl(this.router.url + '/');
      this.location.go('/account/MyBookings');
      
     
      })
  }
  getMyProfiles()
  {
    var userDetailJSON=localStorage.getItem("UserDetails");
    if(userDetailJSON!=null && userDetailJSON!=undefined){
      this.userDetails=JSON.parse(userDetailJSON);
      
    }
    this.initilizeBookingRequest();
    
    this.location.go('/account/profile');
   
  }
  getMyContact()
  {
    this.location.go('/account/ChangePassword');
  }
  getBookingDetail(ReservationRef,ServiceType){
    this.loader=true;
    this.bookingDetailModel=new BookingDetailModel();
    this.bookingDetailModel.BookingDetailRequest=new BookingDetailRequest();
    this.bookingDetailModel.BookingDetailRequest.Authentication=new Authentication();
    this.bookingDetailModel.BookingDetailRequest.Authentication.SubAgent=new SubAgent();
    this.bookingDetailModel.BookingDetailRequest.Header=new Header();
    this.bookingDetailModel.BookingDetailRequest.RequestBody=new RequestBody();
    this.bookingDetailModel.BookingDetailRequest.RequestBody.ClientId=this.userDetails.ClientId;    
    this.bookingDetailModel.BookingDetailRequest.Header.Method="BookingDetail";
    this.bookingDetailModel.BookingDetailRequest.RequestBody.ReservationRef=ReservationRef;

    this._accountService.getBookingDetail(this.bookingDetailModel).subscribe(data=>{
      var bookingData=data as any;
      this.loader=false;
     if(bookingData.BookingDetailResponse.Header.Status=="SUCCESS"){
     this.bookingDetail=bookingData.BookingDetailResponse.ResponseBody;
     console.log(this.bookingDetail);
     this.ServiceTyepeValue=ServiceType;
     if(ServiceType=='AIR')
     {
      this.showBookingFlightDetail=true;
      if(this.bookingDetail.FlightBooking!=undefined)
      {
        this.isBookingNotFound=true;
      this.bookingDetail.FlightBooking[0].FlightSegment.forEach(function (value) {
               if(value.Status=="CL") 
               {
                this.isCancelled = true;
                this.break;
               }
               if(value.Status!="HK") 
               {
                this.isUnConfirmed = true;
                this.break;
               }
      });
      this.bookingDetail.FlightBooking[0].PassengerDetail.forEach(function (value) {
        if(value.TicketStatus == "Issued" && !this.isTicketIssued) 
        {
         this.isTicketIssued = true;
        }
        if(!value.IsRefundedFromPG && (value.TicketStatus == "Void" || value.TicketStatus == "Refund") && !this.isTicketVoid) 
        {
         this.isTicketVoid = true;
        }
        if (value.IsRefundedFromPG && !this.isRefundedFromPG)
        {
            this.isRefundedFromPG = true;
        }
});
      }
     }
     else if(ServiceType=='HTL')
     {
      this.showBookingDetail=true;
     }
     else if(ServiceType=='PKG') 
     {
      this.showBookingPackage=true;
     }
     else if(ServiceType=='SSG') 
     {
      this.showBookingSeightSeeing=true;
     }
     else
     {
      this.showBookingTransfer=true;
     }
    }
    })
  }

  BookingStatus(statusCode) {
    if (statusCode == "HK")
      return "CONFIRMED";
    else if (statusCode == "RQ")
      return "ONREQUEST";
    else if (statusCode == "UC")
      return "UNCONFIRMED";
    else if (statusCode == "CL")
      return "CANCELLED";
    else {
      return statusCode
    }
  }

  getCancellationPoloicy(bookingid, ProductId, PolicyDetail, serviceType) {
    if (serviceType == 'HTL')
    {
      this.htmlStrCanxPolicy = PolicyDetail;
    }
  }

getVoucher(bookingid,ProductId){  
  this.voucherModel=new VoucherModel;
  this.voucherModel.BookingDetailRequest=new BookingDetailRequestVM;
  this.voucherModel.BookingDetailRequest.Authentication=new AuthenticationVM;
  this.voucherModel.BookingDetailRequest.Authentication.SubAgent=new SubAgentVM;
  this.voucherModel.BookingDetailRequest.Header=new Header;
  this.voucherModel.BookingDetailRequest.Header.Method="PrintBookingDocument";
  this.voucherModel.BookingDetailRequest.RequestBody=new RequestBodyVM;

  this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef=bookingid;
  this.voucherModel.BookingDetailRequest.RequestBody.ClientId= this.userDetails.ClientId;
  this.voucherModel.BookingDetailRequest.RequestBody.ServiceProductId=ProductId;
  this.voucherModel.BookingDetailRequest.RequestBody.ServiceType=this.ServiceTyepeValue;
 this.hotelService.getHotelVoucher(this.voucherModel).subscribe(data=>{
  this.htmlStr=data;
  this.showVoucher=true;
 },
 error => console.error('There was an error!', error));
}

getInvoice(bookingid,ProductId){  
  this.voucherModel=new VoucherModel;
  this.voucherModel.BookingDetailRequest=new BookingDetailRequestVM;
  this.voucherModel.BookingDetailRequest.Authentication=new AuthenticationVM;
  this.voucherModel.BookingDetailRequest.Authentication.SubAgent=new SubAgentVM;
  this.voucherModel.BookingDetailRequest.Header=new Header;
  this.voucherModel.BookingDetailRequest.RequestBody=new RequestBodyVM;
  this.voucherModel.BookingDetailRequest.Header.Method="PrintBookingInvoice";
  this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef=bookingid;
  this.voucherModel.BookingDetailRequest.RequestBody.ClientId= this.userDetails.ClientId;
  this.voucherModel.BookingDetailRequest.RequestBody.ServiceProductId=ProductId;
  this.voucherModel.BookingDetailRequest.RequestBody.ServiceType=this.ServiceTyepeValue;
 this.hotelService.getHotelInvoice(this.voucherModel).subscribe(data=>{
  this.htmlStrInvoice=data;
  this.showInvoice=true;
 });
}
CancellationCharge(bookingid,ProductId){  
  this.BookingCancelModel=new BookingCancelModel;
  this.BookingCancelModel.CancellationRequest=new BookingDetailRequestVM;
  this.BookingCancelModel.CancellationRequest.Authentication=new AuthenticationVM;
  this.BookingCancelModel.CancellationRequest.Authentication=new AuthenticationVM;
  this.BookingCancelModel.CancellationRequest.Authentication.SubAgent=new SubAgentVM;
  this.BookingCancelModel.CancellationRequest.Header=new Header;
  this.BookingCancelModel.CancellationRequest.RequestBody=new RequestBodyVM;
  this.BookingCancelModel.CancellationRequest.Header.Method="CancellationCharge";
  this.BookingCancelModel.CancellationRequest.RequestBody.ReservationRef=bookingid;

  this.BookingCancelModel.CancellationRequest.RequestBody.ClientId= this.userDetails.ClientId;
  this.BookingCancelModel.CancellationRequest.RequestBody.ServiceProductId=ProductId;
  this.BookingCancelModel.CancellationRequest.RequestBody.ServiceType=this.ServiceTyepeValue;
  console.log(this.BookingCancelModel);
  this.isAllowedCancellation=environment.isAllowedCancellation;
  this.MobileNo=environment.mobileNo;
  this.Email=environment.emailId;
  if(environment.isAllowedCancellation )
  {
  this.hotelService.CancellationCharge(this.BookingCancelModel).subscribe((data: any) => {
    this.CancellationChargedata = data;
 });

  }
}

getEmailvoucher(bookingid,ProductId){  
  this.isEmailVoucher=true;
}
CancelBooking(bookingid,ProductId){  
  this.BookingCancelModel=new BookingCancelModel;
  this.BookingCancelModel.CancellationRequest=new BookingDetailRequestVM;
  this.BookingCancelModel.CancellationRequest.Authentication=new AuthenticationVM;
  this.BookingCancelModel.CancellationRequest.Authentication=new AuthenticationVM;
  this.BookingCancelModel.CancellationRequest.Authentication.SubAgent=new SubAgentVM;
  this.BookingCancelModel.CancellationRequest.Header=new Header;
  this.BookingCancelModel.CancellationRequest.RequestBody=new RequestBodyVM;
  this.BookingCancelModel.CancellationRequest.Header.Method="CancellationCharge";
  this.BookingCancelModel.CancellationRequest.RequestBody.ReservationRef=bookingid;

  this.BookingCancelModel.CancellationRequest.RequestBody.ClientId= this.userDetails.ClientId;
  this.BookingCancelModel.CancellationRequest.RequestBody.ServiceProductId=ProductId;
  this.BookingCancelModel.CancellationRequest.RequestBody.ServiceType=this.ServiceTyepeValue;
  console.log(this.BookingCancelModel);
  this.hotelService.CancelBooking(this.BookingCancelModel).subscribe((data: any) => {
    console.log(data);
    if(data && data.CancellationResponse && data.CancellationResponse.Header && data.CancellationResponse.Header.Status=='SUCCESS'){
      this.toastr.success('Your booking has been successfuly cancel !');
      this.getBookingDetail(ProductId,this.ServiceTyepeValue)
    }
    else{
    this.toastr.error('Some error occured,please try again !');
  }
 });
}

EmailVoucher(bookingid,ProductId,emailId){  
  this.voucherModel=new VoucherModel;
  this.voucherModel.BookingDetailRequest=new BookingDetailRequestVM;
  this.voucherModel.BookingDetailRequest.Authentication=new AuthenticationVM;
  this.voucherModel.BookingDetailRequest.Authentication.SubAgent=new SubAgentVM;
  this.voucherModel.BookingDetailRequest.Header=new Header;
  this.voucherModel.BookingDetailRequest.RequestBody=new RequestBodyVM;
  this.voucherModel.BookingDetailRequest.Header.Method="SendBookingDocumentMail";
  this.voucherModel.BookingDetailRequest.RequestBody.ReservationRef=bookingid;
  this.voucherModel.BookingDetailRequest.RequestBody.ClientId= this.userDetails.ClientId;
  this.voucherModel.BookingDetailRequest.RequestBody.ServiceProductId=ProductId;
  this.voucherModel.BookingDetailRequest.RequestBody.ServiceType=this.ServiceTyepeValue;
  this.voucherModel.BookingDetailRequest.RequestBody.CCEmail=emailId;
  this.hotelService.EmailVoucher(this.voucherModel).subscribe((data: any) => {
    console.log(data);
    if(data.MailSend.Status==false){
      this.toastr.error('Email not send, Please contact to Support Team');
    }
    else{
    this.toastr.success('Email send Successfully');
  }
 });
}

column:string='BookingDate';
direction: number=-1;
bookingStatus:string='All';
serviceType:string='All';
serviceTypeFilter:string='All';

sortBy(property){
  this.column = property;
}
onSelect(val)
{
  this.allBookingList = (this.serviceType==this.serviceTypeFilter && this.bookingStatus==this.serviceTypeFilter)
  ?this.allBookingListFilter:(this.serviceType==this.serviceTypeFilter && this.bookingStatus!=this.serviceTypeFilter)?
  this.allBookingListFilter.filter(x => x.StatusText.toUpperCase() == this.bookingStatus.toUpperCase()):
  (this.serviceType!=this.serviceTypeFilter && this.bookingStatus==this.serviceTypeFilter)?
  this.allBookingListFilter.filter(x => x.ServiceType == this.serviceType)
  :this.allBookingListFilter.filter(x => x.ServiceType == this.serviceType && x.StatusText.toUpperCase() == this.bookingStatus.toUpperCase());
  this.totalBookings=this.allBookingList.length;
}

}
