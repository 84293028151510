import { CompanySettingService } from "../Service/company-setting.service";


export class GetRoomModel{
    Request:GetRoomRequest;
}
    export class GetRoomRequest {
        Master: GetRoomMaster;
        Authentication: GetRoomAuthentication;
        RequestDetails: GetRoomRequestDetails;
    }

    export class GetRoomMaster {
        CountryCode: string = "IN";
        CountryName: string = "India";
        CityCode: string;
        CityName: string;
        DisplayCountryCityName: string;
        CheckIn: Date;
        CheckOut: Date;
        Nationality: string = "IN";
        NationalityName: string = "Indian";
        Residence: string;
        ResidenceName: string;
        Mode: string = "system";
        UserAgent: string = "Mozila 5.0/Windows 7";
        AdditionalMarkup: string = "0";
        GrossCurrSym: string;
        AreaFlag: boolean;
        AreaCode: string;
        Duration: string;
        Comp_Curr: string = CompanySettingService.Currency;
        Agent_Curr: string = CompanySettingService.Currency;
        Gross_Curr: string = CompanySettingService.Currency;
        LangCode: string = "1";
        Agent_ROE: number = 0;
        Gross_ROE: number = 0;
        IP: string = "10.0.50.1";
        SessionId: string;
    }


    export class GetRoomAuthentication {
        AuthenticationKey: string = "2540D49A-FCFE-4422-9C4B-FBF2DA50B0BE";
        Channel: string = CompanySettingService.Channel;
        OnBehalfBooking: boolean = false;
        SubAgent: GetRoomSubAgent;
        CompanyId: string = "RBS";
        ServiceType: string = "Hotel";
    }
    export class GetRoomSubAgent {
        Id: number = 0;
        UserId: number = 0;
        UserName: string = "";
        BranchId: number = 0;
        SaBranchId: number = 0;
    }

    export class GetRoomRequestDetails {
        GetRoom: GetRoomGetRoom;
        Filter: GetRoomFilter;        
    }
    export class GetRoomGetRoom {
        Rooms: GetRoomDetails;
    }
    export class GetRoomDetails{
        Room: GetRoomHotelRoom[];  
    }
    export class GetRoomFilter {

        ProductId:string ="0";
        HotelId: string;
        Provider: string;
        Token: string;
        PriceBreakup: boolean = true;
        HotelDesc: boolean = true;
        OnRequest: boolean = true;
        GeoCode: boolean = true;        
    }

    export class GetRoomHotelRoom {
        Adult: number = 2;
        Child: number = 0;
        ChildCountArray:any[];
        ChildAge: number[];
    }

