import { CompanySettingService } from '../Service/company-setting.service';
import { Header } from './VoucherModel';


export class MyBookingModel{
    BookingEnquiryRequest:BookingEnquiryRequest;
}

export class BookingEnquiryRequest{
    Authentication:Authentication;
    Header:Header;
    RequestBody:RequestBody;
}

export class Authentication{
    AuthenticationKey:string="";
    Channel:string=CompanySettingService.Channel;
    OnBehalfBooking:boolean;
    CompanyId:string=CompanySettingService.CompanyCode;
    DomainUrl:string;
    SubAgent:SubAgent;
}

export class SubAgent{
    Id:string;
    UserId:string;
    UserName:string;
    BranchId:string;
    SaBranchId:string;
}

export class RequestBody{
    ClientId:number;
    ServiceType:string="AIR,HTL,CAR,SSG,PKG";
    ServiceProductId:number=0;
    Channel:string=CompanySettingService.Channel;
    ReservationRef:string;
    ReservationId:string;
    SupplierRef:string;
    Status:string;
    PaymentMode:string;

      SupplierName:string;
      IATANo:string;
      LPONo:string;
      CustomerACNo:string;
      CabinClass:string;
      VoucherNo:string;
      CCEmail:string;
      AgencyLogo:string;
      IsWhiteLabel:string;
      EmailItineraryWithTicket:boolean=false;
      LoginType:string;
      ParantLogo:string;
      SupportEmail:string;
      SupportPhone:string;
      IsShowPrice:boolean=false;
      Passenger:Passenger;
      DateType:DateType;
      CityName:CityName;

}

export class CityName{
    From:string="";
    To:string="";
}
export class Passenger{
    FirstName:string="";
    LastName:string="";
}

export class DateType{
    Type:number=0;
    From:string="";
    To:string="";
}
