<div class="my-account" style="padding-top: 80px;">
    <div class="container">
        <div class="container">
            <div class="row">
                <div class="col-md-2 mb-3">
                    <ul class="nav nav-pills acc-tab" id="myTab" role="tablist">
                        <li class="nav-item" (click)="getMyProfiles()">
                            <a class="nav-link {{tabactveprofile}}" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><i class="fas fa-user-circle"></i>Account</a>
                        </li>
                        <li class="nav-item" (click)="getMyBookings()" >
                            <a class="nav-link {{tabactvebooking}}"  id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i class="fas fa-suitcase"></i>Booking</a>
                        </li>
                        <li class="nav-item" (click)="getMyContact()">
                            <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false"><i class="fas fa-unlock-alt"></i>Change Password</a>
                        </li>
                    </ul>
                </div>

                <div class="col-md-10">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade {{tabshowactiveprofile}}" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div class="header rel-class">
                                <h4 class="title">{{userDetails.Title}} {{userDetails.FirstName}} {{userDetails.LastName}}</h4>
                                <a href="" class="add-lnk">Edit Profile</a>
                            </div>
                            <dl class="user-desp">
                                <dt><i class="fas fa-angle-right"></i>First Name:</dt>
                                <dd>{{userDetails.FirstName}}</dd>
                                <dt><i class="fas fa-angle-right"></i>Last Name:</dt>
                                <dd>{{userDetails.LastName}}</dd>
                                <dt><i class="fas fa-angle-right"></i>Mobile:</dt>
                                <dd>{{userDetails.MobileNo}}</dd>
                                <dt><i class="fas fa-angle-right"></i>Email:</dt>
                                <dd>{{userDetails.Email}}</dd>
                            </dl>
                            <div class="header rel-class">
                                <h4 class="title">Personal Details</h4>
                            </div>
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="">Title<span>*</span></label>
                                        <select class="form-control" name="title"  [(ngModel)]="userDetails.Title" >
                                            <option>Mr</option>
                                            <option>Mrs</option>
                                            <option>Ms</option>
                                            <option>Miss</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">First Name<span>*</span></label>
                                        <input type="text" class="form-control" name="firstName" [(ngModel)]="userDetails.FirstName">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="">Middle Name</label>
                                        <input type="text" class="form-control" name="middleName" [(ngModel)]="userDetails.MiddleName">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="">Last Name<span>*</span></label>
                                        <input type="text" class="form-control"name="lastName" [(ngModel)]="userDetails.LastName">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Email<span>*</span></label>
                                        <input type="email" class="form-control" name="email" [(ngModel)]="userDetails.Email">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Mobile No.<span>*</span></label>
                                        <input type="text" class="form-control" name="mobileNo" [(ngModel)]="userDetails.MobileNo">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Nationality<span>*</span></label>
                                        <select class="form-control" name="nationality" [(ngModel)]="userDetails.Nationality">
                                            <option value="">Select</option>
                                            <option value="Indian">Indian</option>
                                            <option value="Afgan">Afgan</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Date of Birth<span>*</span></label>
                                        <input type="date" class="form-control" name="dateofBirth" [(ngModel)]="userDetails.DateofBirth">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="">Address</label>
                                        <textarea class="form-control" name="address" [(ngModel)]="userDetails.Address"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="header rel-class">
                                <h4 class="title">Passport Details</h4>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Passport Number</label>
                                        <input type="text" class="form-control" name="passportNumber" [(ngModel)]="userDetails.PassportNumber">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Passport Issuing Country</label>
                                        <select class="form-control" name="passportIssueCountry" [(ngModel)]="userDetails.PassportIssueCountry">
                                            <option value="">Select</option>
                                            <option value="Indian">Indian</option>
                                            <option value="Afgan">Afgan</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Passport Issue Date</label>
                                        <input type="date" class="form-control" name="passportIssueDate" [(ngModel)]="userDetails.PassportIssueDate">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="">Expiry Date</label>
                                        <input type="date" class="form-control" name="passportExpDate" [(ngModel)]="userDetails.PassportExpDate">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-updte">Update Profile</button>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade {{tabshowactivebooking}}" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div *ngIf="!showBookingDetail && !showBookingFlightDetail && !showBookingPackage && !showBookingSeightSeeing && !showBookingTransfer">
                            <div class="header rel-class">
                                <h4 class="title">My Bookings</h4>
                            </div>
                        
                            <div class="filtr">
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "serviceType" (ngModelChange)="onSelect(serviceType)" name="serviceType" value="All" checked>
                                            <label class="form-check-label" for="inlineCheckbox1">All</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "serviceType" (ngModelChange)="onSelect(serviceType)" name="serviceType" value="HTL">
                                            <label class="form-check-label" for="inlineCheckbox2">Hotel</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "serviceType" (ngModelChange)="onSelect(serviceType)" name="serviceType" value="AIR">
                                            <label class="form-check-label" for="inlineCheckbox2">AIR</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "serviceType" (ngModelChange)="onSelect(serviceType)" name="serviceType" value="PKG">
                                            <label class="form-check-label" for="inlineCheckbox2">Holiday</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "serviceType" (ngModelChange)="onSelect(serviceType)" name="serviceType" value="TRF">
                                            <label class="form-check-label" for="inlineCheckbox2">Transfer</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "serviceType"  (ngModelChange)="onSelect(serviceType)" name="serviceType" value="SSG">
                                            <label class="form-check-label" for="inlineCheckbox2">Sightseening</label>
                                        </div>
                                        <br>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "bookingStatus" (ngModelChange)="onSelect(bookingStatus)" name="bookingStatus" value="All" checked>
                                            <label class="form-check-label" for="inlineCheckbox3">All</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "bookingStatus" (ngModelChange)="onSelect(bookingStatus)" name="bookingStatus"  value="Confirmed">
                                            <label class="form-check-label" for="inlineCheckbox4">Confirmed</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "bookingStatus" (ngModelChange)="onSelect(bookingStatus)" name="bookingStatus"  value="Unconfirmed">
                                            <label class="form-check-label" for="inlineCheckbox5">Unconfirmed</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "bookingStatus" (ngModelChange)="onSelect(bookingStatus)"  name="bookingStatus"  value="OnRequest">
                                            <label class="form-check-label" for="inlineCheckbox5">OnRequest</label>
                                        </div>
                                        
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" [(ngModel)] = "bookingStatus" (ngModelChange)="onSelect(bookingStatus)" name="bookingStatus"  value="Cancelled">
                                            <label class="form-check-label" for="inlineCheckbox6">Cancelled</label>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <input type="text" class="form-control"  [(ngModel)]="filterText"  placeholder="Enter ref. no or service Name">
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="totalBookings!=0" class="bk-sort">
                                <h3>Sort by:</h3>
                                <div class="sort-btn">
                                    <a (click)="sortBy('StartDate')"  [ngClass]="{'active':column=='StartDate'}">Service Date</a>
                                    <a (click)="sortBy('BookingDate')"  [ngClass]="{'active':column=='BookingDate'}">Booking Date</a>
                                </div>
                                <h4>Total {{totalBookings}}</h4>
                            </div>
                            
                            <div class="bkng-box"   *ngFor="let booking of allBookingList 
                            | filter:{ ServiceName: filterText, ReservationRef: filterText,StatusText:bookingStatus,ServiceType:serviceType}  
                                | orderBy: {property:column, direction: direction}">
                                
                                <div class="clndr-box" [ngClass]="booking.Status == 'HK' ? 'clearfix' : 'cancelled'">
                                    <span [ngClass]="booking.Status == 'HK' ? 'year' : 'yeardisable'">{{booking.StartDate | date : 'MMM yyyy'}}</span>
                                    <span  [ngClass]="booking.Status == 'HK' ? 'date' : 'datedisable'">{{booking.StartDate | date : 'dd'}}</span>
                                    <span class="day" >{{booking.StartDate | date : 'EEE'}}</span>
                                    <span class="srv-dte">Service Date</span>
                                </div>
                                <div class="htl-info mrtop" [ngClass]="booking.Status == 'HK' ? 'clearfix' : 'cancelled'">
                                    <h4 class="box-title" style="width:200px;font-size: 14px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;">
                                    
                                            <i *ngIf="booking.ServiceType == 'PKG'" class="fas fa-suitcase"></i>
                                            <i *ngIf="booking.ServiceType == 'HTL'" class="icon circle fas fa-bed blue-color"></i> 
                                            <i *ngIf="booking.ServiceType == 'SSG'" class="icon circle soap-icon-beach green-color"></i>
                                            <i *ngIf="booking.ServiceType == 'AIR'" class="fa fa-plane takeoff-effect icon circle yellow-color"></i>
                                            <i *ngIf="booking.ServiceType == 'CAR'" class="icon circle soap-icon-car red-color"></i>
                                    <h3>{{booking.ServiceName}}</h3>

                                    <span>Status: <span [ngClass]="booking.StatusText =='CONFIRMED'?'statusconfirm':'statusunconfirm'">{{booking.StatusText}}</span></span>
                                    </h4>
                                </div>
                                <div class="pax-name mrtop" [ngClass]="booking.Status == 'HK' ? 'clearfix' : 'cancelled'">
                                    <h3><i class="fas fa-user"></i>{{booking.TravelerName}}</h3>
                                </div>
                                <div class="vw-bkg-btn mrtop" [ngClass]="booking.Status == 'HK' ? 'clearfix' : 'cancelled'">
                                    <a href="javascript:void(0)" [ngClass]="booking.Status == 'HK' ? '' : 'viewbookingdesable'" (click)="getBookingDetail(booking.ReservationRef,booking.ServiceType)">View Booking</a>
                                </div>
                                <div class="info-bx" [ngClass]="booking.Status == 'HK' ? 'clearfix' : 'cancelled'">
                                    <span [ngClass]="booking.Status == 'HK' ? 'bld' : 'cancelled'">Reference No.</span>
                                    <span>{{booking.ReservationRef}}</span>
                                    <span [ngClass]="booking.Status == 'HK' ? 'bld' : 'cancelled'">Booked on</span>
                                    <span>{{booking.BookingDate | date : 'dd MMM yyyy'}}</span>
                                </div>
                                
                             
                            </div>
                            <div *ngIf="allBookingList!=null && allBookingList.length==0">
                                <div class="flight-list listing-style3 flight nofilterresult">
                                    <article class="box">
                                        <div class="row">
                                            <div class="col-sm-2">
                                                <i class="fa fa-chain-broken"></i>
                                            </div>
                                            <div class="col-sm-10 alignRight">
                                             <h3>We could not find any booking as per your selected combination of filters.</h3>
                                             <div class="txt">Please reset. <a href="javascript:void(0);" [ngStyle]="{'text-decoration': 'none','color':'#212121','font-size':'14px'}" ng-click="ClearBookingFilter()">Clear all filters</a></div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                          
                           </div>

                            <div class="bkg-detils" *ngIf="showBookingDetail">
                                <div class="header rel-class">
                                    <h4 class="title">Booking Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Reference No</th>
                                        <th>Booking Date</th>
                                        <th>User Name</th>
                                      <th>Booking Status</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.BookingDetail.ReservationRef}}</td>
                                        <td>{{bookingDetail.BookingDetail.BookingDate | date : 'dd MMM yyyy'}}</td>
                                        <td>{{userDetails.FirstName}} {{userDetails.LastName}}</td>
                                      <td><h3 [ngClass]="bookingDetail.HotelBooking[0].BookingStatus =='HK'?'statusconfirm':'statusunconfirm'"><i [ngClass]="bookingDetail.HotelBooking[0].BookingStatus =='HK'?'fas fa-thumbs-up txt-black':'fa fa-frown-o'"></i> {{BookingStatus(bookingDetail.HotelBooking[0].BookingStatus)}}</h3></td>
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Hotel Details</h4>
                                </div>
                                <table class="table table-bordered tb-hdels tmb">
                                    <tr>
                                        <td><img [src]="bookingDetail.HotelBooking[0].HotelDetail.ImageUrl" class="img-fluid"></td>
                                        <td>
                                            <label>{{bookingDetail.HotelBooking[0].HotelDetail.HotelName}}</label>
                                            <span [innerHtml]="bookingDetail.HotelBooking[0].HotelDetail.Address"></span>
                                            <h3 [ngClass]="bookingDetail.HotelBooking[0].BookingStatus =='HK'?'statusconfirm':'statusunconfirm'"><i [ngClass]="bookingDetail.HotelBooking[0].BookingStatus =='HK'?'fas fa-thumbs-up txt-black':'fa fa-frown-o'"></i>{{BookingStatus(bookingDetail.HotelBooking[0].BookingStatus)}}</h3>
                                        </td>
                                        <td><label for="">Pax</label>
                                            <span>Adult {{bookingDetail.HotelBooking[0].HotelDetail.Adult}}, Child {{bookingDetail.HotelBooking[0].HotelDetail.Child}}</span></td>
                                        <td>
                                            <label for="">Lead Pax Name</label>
                                            <span>{{bookingDetail.HotelBooking[0].HotelDetail.LeadPaxName}}</span>
                                            <label for="">Lead Pax Tel No</label>
                                            <span>{{bookingDetail.HotelBooking[0].HotelDetail.LeadPaxContactNo}}</span>
                                        </td>

                                    </tr>

                                </table>
                                <table class="table table-bordered tb-hdels">
                                    <tr>
                                        <td>
                                            <div class="chk-tmng">
                                                <div class="chk-tmng-one">
                                                    <label style="text-align: left">Check In</label>
                                                    <span>{{bookingDetail.HotelBooking[0].HotelDetail.CheckIn}}</span>
                                                </div>
                                                <div class="chk-tmng-two">
                                                    <i class="far fa-clock"></i>
                                                    <span>{{bookingDetail.HotelBooking[0].HotelDetail.TotalNight}} Night </span>
                                                </div>
                                                <div class="chk-tmng-three">
                                                    <label>Check Out</label>
                                                    <span>{{bookingDetail.HotelBooking[0].HotelDetail.CheckOut}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <label>No of Rooms:</label>
                                            <span>{{bookingDetail.HotelBooking[0].HotelDetail.NoOfRoom}}</span>
                                        </td>
                                        <td>
                                            <label>Category</label>
                                            <span [innerHtml]="bookingDetail.HotelBooking[0].HotelDetail.RoomCategory"></span>
                                        </td>
                                        <td>
                                            <label>Meal Plan:</label>
                                            <span [innerHtml]="bookingDetail.HotelBooking[0].HotelDetail.MealPlan"></span>
                                        </td>
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Passenger and Payment Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Payment Status</th>
                                        <th>Payment Mode</th>
                                        <th>Paid Amount</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.HotelBooking[0].PaymentDetail.PaymentStatus}}</td>
                                        <td>Online Payment</td>
                                        <td>{{bookingDetail.HotelBooking[0].PaymentDetail.TotalPrice}}</td>
                                    </tr>
                                </table>
                                <div class="btns-box">
                                  <a *ngIf="bookingDetail.HotelBooking[0].BookingStatus =='HK'" href="javascript.void(0)" data-toggle="modal" data-target="#cancelbooking" (click)="CancellationCharge(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId)">Cancel Booking</a>
                                  <a href="javascript.void(0)" data-toggle="modal" data-target="#invoiceModel" (click)="getInvoice(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId)">Invoice</a>
                                  <a href="javascript.void(0)" data-toggle="modal" data-target="#voucherModel" (click)="getVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId)">Voucher</a>
                                  <a *ngIf="bookingDetail.HotelBooking[0].BookingStatus =='HK' && bookingDetail.HotelBooking[0].HotelDetail.CancelationPolicy!=null" href="javascript.void(0)" data-toggle="modal" data-target="#CancellationPolicyModel" (click)="getCancellationPoloicy(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId,bookingDetail.HotelBooking[0].HotelDetail.CancelationPolicy,'HTL')">Cancellation Policy</a>
                                  <a *ngIf="bookingDetail.HotelBooking[0].BookingStatus =='HK'" href="javascript.void(0)" data-toggle="modal" data-target="#emailVoucher" (click)="getEmailvoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId)">Email Voucher</a>
                                  <a (click)="showBookingDetail=false" style="color:#fff;cursor:pointer">Back</a>
                                </div>
                            
                            </div>

                              <div class="bkg-detils" *ngIf="showBookingFlightDetail">
                                <div class="header rel-class">
                                    <h4 class="title">Booking Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Reference No</th>
                                        <th>Booking Date</th>
                                        <th>User Name</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.BookingDetail.ReservationRef}}</td>
                                        <td>{{bookingDetail.BookingDetail.BookingDate | date : 'dd MMM yyyy'}}</td>
                                        <td>{{userDetails.FirstName}} {{userDetails.LastName}}</td>
                                    </tr>
                                </table>
                                <div *ngIf="isBookingNotFound">

                                  <!--ngIf="bookingDetail.FlightBooking[0].IsShowVoucher
                                  && bookingDetail.FlightBooking[0].BookingStatus=='HK' &&
                                  (!isTicketIssued && !isTicketVoid && !isRefundedFromPG) && bookingDetail.FlightBooking[0].Gds != 'MF'"-->

                                  <table class="table table-bordered" *ngIf="bookingDetail.FlightBooking[0].IsShowVoucher
                                && bookingDetail.FlightBooking[0].BookingStatus=='HK'">
                                    <tr>
                                      <th><label style="color:red; text-align:justify">Your booking is confirmed but ticket(s) not issued because of this you are not able to print / email your ticket(s). Kindly contact us to get the ticket(s) issued.</label></th>
                                    </tr>
                                  </table>
                                  <table class="table table-bordered" *ngIf="bookingDetail.FlightBooking[0].IsShowVoucher && isUnConfirmed && !isRefundedFromPG">
                                    <tr>
                                      <th><label style="color:red; text-align:justify">Sorry for the inconvenience caused. Your booking considered as a <strong>PAID BOOKING</strong> with some tiny issues. One of our travel consultant agents will get in touch with you shortly. In case of urgency please feel free to call us <strong>+91 2242225333</strong> and we'll be more than happy to support you.</label></th>
                                    </tr>
                                  </table>

                                  <table class="table table-bordered" *ngIf="bookingDetail.FlightBooking[0].IsShowVoucher &&
                                bookingDetail.FlightBooking[0].BookingStatus == 'HK' && bookingDetail.FlightBooking[0].Gds == 'MF'">
                                    <tr>
                                      <th>
                                        <label style="text-align:justify">
                                          Thank you for choosing Haboob.sd for your air ticket purchase. You should get your e - ticket within 4 business hours.
                                          &nbsp;For further information or follow up, please feel free to call us <strong>+91 2242225333</strong>.
                                        </label>
                                      </th>
                                    </tr>
                                  </table>

                                  <div class="header rel-class">
                                    <h4 class="title">Flight Details</h4>
                                  </div>
                                  <table class="table table-bordered tb-flightdet tmb">
                                    <tr *ngFor="let booking of bookingDetail.FlightBooking[0].FlightSegment">
                                      <td>{{booking.AirlineCode}}-{{booking.FlightNo}} {{booking.CabinClass}}</td>
                                      <td>{{booking.FromCity}} ({{booking.FromAirport}}) to {{booking.ToCity}} ({{booking.ToAirport}})<br>Status {{booking.Status=="HK"?"CONFIRMED":booking.Status=="RQ"?"ONREQUEST":booking.Status=="UC"?"UNCONFIRMED":booking.Status=="CL"?"CANCELLED":booking.Status}} </td>
                                      <td>Takeoff<br>{{booking.Departure}} <br>Terminal {{booking.DepTerminal}}</td>
                                      <td>{{booking.Duration}}</td>
                                      <td>LANDING<br>{{booking.Arrival}} <br>Terminal {{booking.ArrTerminal}}</td>
                                    </tr>
                                  </table>

                                  <div class="header rel-class">
                                    <h4 class="title">Passenger and Payment Details</h4>
                                  </div>

                                  <table class="table table-bordered tb-flightdet tmb">
                                    <tr>
                                      <th>Name</th>
                                      <th>Ticket No</th>
                                      <th>Ticket Status</th>
                                      <th>Payment Status</th>
                                      <th *ngIf="bookingDetail.FlightBooking[0].IsShowVoucher">
                                        <label>TotalPrice<br>{{bookingDetail.FlightBooking[0].PaymentDetail.TransCurr}}</label>
                                      </th>

                                      <th *ngIf="!bookingDetail.FlightBooking[0].IsShowVoucher">
                                        <label>Amount ToBePaid<br>{{bookingDetail.FlightBooking[0].PaymentDetail.TransCurr}}</label>
                                      </th>

                                      <th *ngIf="isRefundedFromPG">
                                        <label>Refund Amount <br />{{bookingDetail.FlightBooking[0].PaymentDetail.TransCurr}}</label>
                                      </th>

                                      <th>Baggage</th>

                                    </tr>
                                    <tr *ngFor="let booking of bookingDetail.FlightBooking[0].PassengerDetail">
                                      <td>{{booking.PaxName}} ({{booking.PaxType}})</td>
                                      <td>{{booking.TicketNo}}</td>
                                      <td>{{booking.TicketStatus}}</td>
                                      <td *ngIf="booking.IsRefundedFromPG"><span>{{booking.RefundStatus}}</span></td>
                                      <td *ngIf="!booking.IsRefundedFromPG"><span>{{booking.PaymentStatus}}</span></td>
                                      <td>{{booking.TotalAmount*bookingDetail.FlightBooking[0].TransCurrROE}}</td>
                                      <td *ngIf="isRefundedFromPG && booking.IsRefundedFromPG"><span>{{booking.RefundedAmount}}*{{bookingDetail.FlightBooking[0].TransCurrROE}}</span></td>
                                      <td *ngIf="!isRefundedFromPG && booking.IsRefundedFromPG"><span>0</span></td>
                                      <td *ngIf="booking.Baggage!=''">{{booking.Baggage}}</td>
                                      <td *ngIf="booking.Baggage==''">NA</td>
                                    </tr>

                                  </table>
                                  <div class="btns-box">
                                    <br>
                                    <!--<a href="javascript.void(0)" *ngIf="!isCancelled && !isUnConfirmed && bookingDetail.FlightBooking[0].IsShowVoucher && isTicketIssued" data-toggle="modal" data-target="#voucherModel" (click)="getVoucher(bookingDetail.BookingDetail.ReservationRef, bookingDetail.FlightBooking[0].ProductId)">PrintTicket</a>
  <a href="javascript.void(0)" *ngIf="!isCancelled && !isUnConfirmed && bookingDetail.FlightBooking[0].IsShowVoucher && isTicketIssued" data-toggle="modal" data-target="#cancelbooking" (click)="CancellationCharge(bookingDetail.BookingDetail.ReservationRef, bookingDetail.FlightBooking[0].ProductId)">EmailTicket</a>-->
                                    <a href="javascript.void(0)" *ngIf="!isCancelled && !isUnConfirmed && bookingDetail.FlightBooking[0].IsShowVoucher" data-toggle="modal" data-target="#voucherModel" (click)="getVoucher(bookingDetail.BookingDetail.ReservationRef, bookingDetail.FlightBooking[0].ProductId)">PrintTicket</a>
                                    <a href="javascript.void(0)" *ngIf="!isCancelled && !isUnConfirmed && bookingDetail.FlightBooking[0].IsShowVoucher" data-toggle="modal" data-target="#cancelbooking" (click)="CancellationCharge(bookingDetail.BookingDetail.ReservationRef, bookingDetail.FlightBooking[0].ProductId)">EmailTicket</a>

                                    <a href="javascript.void(0)" data-toggle="modal" data-target="#emailVoucher" (click)="getEmailvoucher(bookingDetail.BookingDetail.ReservationRef, bookingDetail.FlightBooking[0].ProductId)">Email Voucher</a>
                                    <a (click)="showBookingFlightDetail=false;isBookingNotFound=false;" style="color:#fff;cursor:pointer">Back</a>
                                  </div>
                                </div>
                            
                            </div>

                            <div class="bkg-detils" *ngIf="showBookingPackage">
                                <div class="header rel-class">
                                    <h4 class="title">Booking Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Reference No</th>
                                        <th>Booking Date</th>
                                        <th>User Name</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.BookingDetail.ReservationRef}}</td>
                                        <td>{{bookingDetail.BookingDetail.BookingDate | date : 'dd MMM yyyy'}}</td>
                                        <td>{{userDetails.FirstName}} {{userDetails.LastName}}</td>
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Package Details</h4>
                                </div>
                                <table class="table table-bordered tb-hdels tmb">
                                    <tr>
                                        <td><img [src]="bookingDetail.PackageBooking[0].PackageDetail.ImageUrl" class="img-fluid"></td>
                                        <td>
                                            <label>{{bookingDetail.PackageBooking[0].PackageDetail.PackageName}}</label>

                                            <h3><i class="fas fa-thumbs-up"></i>CONFIRMED</h3>
                                        </td>
                                        <td><label for="">Duration</label>
                                            <span *ngIf="bookingDetail.PackageBooking[0].PackageDetail.TotalDuration>1">{{bookingDetail.PackageBooking[0].PackageDetail.TotalDuration}} Days</span>
                                            <span *ngIf="bookingDetail.PackageBooking[0].PackageDetail.TotalDuration==1">{{bookingDetail.PackageBooking[0].PackageDetail.TotalDuration}} Days</span>
                                            <span *ngIf="(bookingDetail.PackageBooking[0].PackageDetail.TotalDuration-1)>1">{{bookingDetail.PackageBooking[0].PackageDetail.TotalDuration-1}} Night</span>
                                            <span *ngIf="(bookingDetail.PackageBooking[0].PackageDetail.TotalDuration-1)==1">{{bookingDetail.PackageBooking[0].PackageDetail.TotalDuration-1}} Night</span>
                                            
                                        </td>
                                        <td>
                                            <label for="">DepartureDate</label>
                                            <span>{{bookingDetail.PackageBooking[0].PackageDetail.DepartureDate}}</span>
                                            
                                        </td>

                                    </tr>

                                </table>
                                <table class="table table-bordered tb-hdels">
                                    <tr>
                                      
                                            
                                                <td class="chk-tmng-one">
                                                    <label>Pax</label><br />
                                                    <span>Adult {{bookingDetail.PackageBooking[0].PackageDetail.Adult}},</span>
                                                    <span>Child {{bookingDetail.PackageBooking[0].PackageDetail.Child}},</span>
                                                    <span>Infant {{bookingDetail.PackageBooking[0].PackageDetail.Infant}}</span>
                                                </td>
                                                <td class="chk-tmng-two">
                                                    <label>Lead Pax Name</label><br />
                                                    <span>{{bookingDetail.PackageBooking[0].PackageDetail.LeadPaxName}} </span>
                                                </td>
                                                <td class="chk-tmng-three">
                                                    <label>Lead Pax Tel No</label>
                                                    <span>{{bookingDetail.PackageBooking[0].PackageDetail.LeadPaxContactNo}}</span>
                                                </td>
                                           
                                       
                                        
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Passenger and Payment Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Payment Status</th>
                                        <th>Payment Mode</th>
                                        <th *ngIf="bookingDetail.PackageBooking[0].PaymentDetail.CouponCode!=''">Promo Code</th>
                                        <th *ngIf="bookingDetail.PackageBooking[0].PaymentDetail.CouponCode!=''">Promo Discount</th>
                                        <th>TotalPrice</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.PackageBooking[0].PaymentDetail.PaymentStatus}}</td>
                                        <td>{{bookingDetail.PackageBooking[0].PaymentDetail.PaymentMode}}</td>
                                    
                                        <td *ngIf="bookingDetail.PackageBooking[0].PaymentDetail.CouponCode!=''">{{bookingDetail.PackageBooking[0].PaymentDetail.CouponCode}}</td>
                                        <td *ngIf="bookingDetail.PackageBooking[0].PaymentDetail.CouponCode!=''">{{bookingDetail.PackageBooking[0].PaymentDetail.DiscountAmt}}</td>
                                        <td>{{bookingDetail.PackageBooking[0].PaymentDetail.TotalPrice}}</td>
                                    </tr>
                                </table>
                                <div class="btns-box">
                                    <a href="javascript.void(0)" data-toggle="modal" data-target="#cancelbooking" (click)="CancellationCharge(bookingDetail.BookingDetail.ReservationRef,bookingDetail.PackageBooking[0].ProductId)">Cancel Booking</a>
                                    <a href="javascript.void(0)"   data-toggle="modal" data-target="#voucherModel" (click)="getVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.PackageBooking[0].ProductId)"> Print Voucher</a>
                                    <a href="javascript.void(0)" data-toggle="modal" data-target="#emailVoucher" (click)="getEmailvoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.PackageBooking[0].ProductId)">Email Voucher</a>
                                  
                                    <a (click)="showBookingPackage=false;isBookingNotFound=false;" style="color:#fff;cursor:pointer">Back</a>
                                </div>
                            
                            </div>
                            <div class="bkg-detils" *ngIf="showBookingSeightSeeing">
                                <div class="header rel-class">
                                    <h4 class="title">Booking Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Reference No</th>
                                        <th>Booking Date</th>
                                        <th>User Name</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.BookingDetail.ReservationRef}}</td>
                                        <td>{{bookingDetail.BookingDetail.BookingDate | date : 'dd MMM yyyy'}}</td>
                                        <td>{{userDetails.FirstName}} {{userDetails.LastName}}</td>
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Activity Details</h4>
                                </div>
                                <table class="table table-bordered tb-hdels tmb">
                                    <tr>
                                        <td><img [src]="bookingDetail.ActivityBooking[0].ActivityDetail.ImageUrl" class="img-fluid"></td>
                                        <td>
                                            <label>{{bookingDetail.ActivityBooking[0].ActivityDetail.ActivityName}}</label>
                                            <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.Address}}</span>
                                            <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.City}} {{bookingDetail.ActivityBooking[0].ActivityDetail.Country}}</span>
                                            <h3><i class="fas fa-thumbs-up"></i>{{bookingDetail.ActivityBooking[0].BookingStatus}}</h3>
                                        </td>
                                        <td>
                                            <label>Pax</label>
                                            <span>Adult {{bookingDetail.ActivityBooking[0].ActivityDetail.Adult}}</span>
                                            <span>Child {{bookingDetail.ActivityBooking[0].ActivityDetail.Child}}</span>
                                
                                        </td>
                                        <td>
                                            <label>Lead Pax Name</label>
                                            <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.LeadPaxName}}</span>
                                            <label>Lead Pax Tel No</label>
                                            <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.LeadPaxContactNo}}</span>
                                            
                                        </td>
                                        <td>
                                            <label>Tour Date</label>
                                            <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.TourDate}}</span>
                                        </td>

                                    </tr>

                                </table>
                                <table class="table table-bordered tb-hdels">
                                    <tr>
                                      
                                                <td class="chk-tmng-one">
                                                    <label>Tour Name</label>
                                                    <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.TourName}}</span>
                                                </td>
                                                <td class="chk-tmng-two">
                                                    <label>Tour Type</label>
                                                    <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.TourType}}</span>
                                                </td>
                                                <td class="chk-tmng-three">
                                                    <label>Tour Duration</label>
                                                    <span>{{bookingDetail.ActivityBooking[0].ActivityDetail.TourDuration}}</span>
                                                </td>
                                        
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Passenger and Payment Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Payment Status</th>
                                        <th>Payment Mode</th>
                                        <th *ngIf="bookingDetail.ActivityBooking[0].PaymentDetail.CouponCode!=''">Promo Code</th>
                                        <th *ngIf="bookingDetail.ActivityBooking[0].PaymentDetail.CouponCode!=''">Promo Discount</th>
                                        <th>TotalPrice</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.ActivityBooking[0].PaymentDetail.PaymentStatus}}</td>
                                        <td>{{bookingDetail.ActivityBooking[0].PaymentDetail.PaymentMode}}</td>
                                    
                                        <td *ngIf="bookingDetail.ActivityBooking[0].PaymentDetail.CouponCode!=''">{{bookingDetail.ActivityBooking[0].PaymentDetail.CouponCode}}</td>
                                        <td *ngIf="bookingDetail.ActivityBooking[0].PaymentDetail.CouponCode!=''">{{bookingDetail.ActivityBooking[0].PaymentDetail.DiscountAmt}}</td>
                                        <td>{{bookingDetail.ActivityBooking[0].PaymentDetail.TotalPrice}}</td>
                                    </tr>
                                </table>
                                <div class="btns-box">
                                    <a href="javascript.void(0)" data-toggle="modal" data-target="#cancelbooking" (click)="CancellationCharge(bookingDetail.BookingDetail.ReservationRef,bookingDetail.ActivityBooking[0].ProductId)">Cancel Booking</a>
                                    <a href="javascript.void(0)"   data-toggle="modal" data-target="#voucherModel" (click)="getVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.ActivityBooking[0].ProductId)"> Print Voucher</a>
                                    <a href="javascript.void(0)" data-toggle="modal" data-target="#emailVoucher" (click)="getEmailvoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.ActivityBooking[0].ProductId)">Email Voucher</a>
                                  
                                    <a (click)="showBookingSeightSeeing=false;isBookingNotFound=false;" style="color:#fff;cursor:pointer">Back</a>
                                </div>
                            
                            </div>
                            
                            <div class="bkg-detils" *ngIf="showBookingTransfer">
                                <div class="header rel-class">
                                    <h4 class="title">Booking Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Reference No</th>
                                        <th>Booking Date</th>
                                        <th>User Name</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.BookingDetail.ReservationRef}}</td>
                                        <td>{{bookingDetail.BookingDetail.BookingDate | date : 'dd MMM yyyy'}}</td>
                                        <td>{{userDetails.FirstName}} {{userDetails.LastName}}</td>
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Transfer Details</h4>
                                </div>
                                <table class="table table-bordered tb-hdels tmb">
                                    <tr>
                                        <td><img [src]="bookingDetail.TransferBooking[0].TransferDetail.ImageUrl" class="img-fluid"></td>
                                        <td>
                                            
                                            <span>{{bookingDetail.TransferBooking[0].TransferDetail.PickUpAddress}}</span>
                                            <span>{{bookingDetail.TransferBooking[0].TransferDetail.City}} {{bookingDetail.TransferBooking[0].TransferDetail.Country}}</span>
                                            <h3><i class="fas fa-thumbs-up"></i>{{bookingDetail.TransferBooking[0].BookingStatus=="HK"?"CONFIRMED":bookingDetail.TransferBooking[0].BookingStatus=="RQ"?"ONREQUEST":bookingDetail.TransferBooking[0].BookingStatus=="UC"?"UNCONFIRMED":bookingDetail.TransferBooking[0].BookingStatus=="CL"?"CANCELLED":bookingDetail.TransferBooking[0].BookingStatus}}</h3>
                                        </td>
                                        <td>
                                            <label>Pax</label>
                                            <span>Adult {{bookingDetail.TransferBooking[0].TransferDetail.Adult}}</span>
                                            <span>Child {{bookingDetail.TransferBooking[0].TransferDetail.Child}}</span>
                                
                                        </td>
                                        <td>
                                            <label>Lead Pax Name</label>
                                            <span>{{bookingDetail.TransferBooking[0].TransferDetail.LeadPaxName}}</span>
                                            <label>Lead Pax Tel No</label>
                                            <span>{{bookingDetail.TransferBooking[0].TransferDetail.LeadPaxContactNo}}</span>
                                            
                                        </td>
                                        <td>
                                            <label>Transfer Date</label>
                                            <span>{{bookingDetail.TransferBooking[0].TransferDetail.TransferDate}}</span>
                                        </td>

                                    </tr>

                                </table>
                                <table class="table table-bordered tb-hdels">
                                    <tr>
                                      
                                            
                                                <td class="chk-tmng-one">
                                                    <label>Transfer Name</label>
                                                    <span>{{bookingDetail.TransferBooking[0].TransferDetail.TransferName}}</span>
                                                </td>
                                                <td class="chk-tmng-two">
                                                    <label>Transfer Type</label>
                                                    <span>{{bookingDetail.TransferBooking[0].TransferDetail.TransferType}}</span>
                                                </td>
                                                <td class="chk-tmng-three">
                                                    <label>Transfer Duration</label>
                                                    <span>{{bookingDetail.TransferBooking[0].TransferDetail.Duration}}</span>
                                                </td>
                                           
                                       
                                        
                                    </tr>
                                </table>
                                <div class="header rel-class">
                                    <h4 class="title">Passenger and Payment Details</h4>
                                </div>
                                <table class="table table-bordered">
                                    <tr>
                                        <th>Payment Status</th>
                                        <th>Payment Mode</th>
                                        <th *ngIf="bookingDetail.TransferBooking[0].PaymentDetail.CouponCode!=''">Promo Code</th>
                                        <th *ngIf="bookingDetail.TransferBooking[0].PaymentDetail.CouponCode!=''">Promo Discount</th>
                                        <th>TotalPrice</th>
                                    </tr>
                                    <tr>
                                        <td>{{bookingDetail.TransferBooking[0].PaymentDetail.PaymentStatus}}</td>
                                        <td>{{bookingDetail.TransferBooking[0].PaymentDetail.PaymentMode}}</td>
                                    
                                        <td *ngIf="bookingDetail.TransferBooking[0].PaymentDetail.CouponCode!=''">{{bookingDetail.TransferBooking[0].PaymentDetail.CouponCode}}</td>
                                        <td *ngIf="bookingDetail.TransferBooking[0].PaymentDetail.CouponCode!=''">{{bookingDetail.TransferBooking[0].PaymentDetail.DiscountAmt}}</td>
                                        <td>{{bookingDetail.TransferBooking[0].PaymentDetail.TotalPrice}}</td>
                                    </tr>
                                </table>
                                <div class="btns-box">
                                    <a href="javascript.void(0)" data-toggle="modal" data-target="#cancelbooking" (click)="CancellationCharge(bookingDetail.BookingDetail.ReservationRef,bookingDetail.TransferBooking[0].ProductId)">Cancel Booking</a>
                                    <a href="javascript.void(0)"   data-toggle="modal" data-target="#voucherModel" (click)="getVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.TransferBooking[0].ProductId)"> Print Voucher</a>
                                    <a href="javascript.void(0)"  data-toggle="modal" data-target="#emailVoucher" (click)="getEmailvoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.TransferBooking[0].ProductId)">Email Voucher</a>
                                  
                                    <a (click)="showBookingTransfer=false;isBookingNotFound=false;" style="color:#fff;cursor:pointer">Back</a>
                                </div>
                            
                            </div>


                        </div>
                        <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                            <div class="header rel-class">
                                <h4 class="title">Change Password</h4>
                            </div>

                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">User Name</label>
                                        <input type="text" class="form-control" name="userName" [(ngModel)]="userDetails.Email">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Old Password</label>
                                        <input type="text" class="form-control" id="" value="">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">New Password</label>
                                        <input type="text" class="form-control" id="" value="">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label for="">Confirm Password</label>
                                        <input type="text" class="form-control" id="" value="">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-updte">Change Password</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>



        </div>

    </div>
</div>

<app-loader *ngIf="loader"></app-loader>

    <!--Cancel-Booking-popup-->
    <div class="modal-box">
        <div id="cancelbooking" class="modal fade">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div class="modal-body">
                        <div class="lr-box" *ngIf="isAllowedCancellation">
                            <h3>Are you sure you want to cancel this booking ?</h3>
                            <ul>
                                <li *ngIf="CancellationChargedata && CancellationChargedata.CancellationResponse.Header.Status=='SUCCESS'">You are placing a cancellation request for booking Reference {{bookingDetail.BookingDetail.ReservationRef}}</li>
                                <li *ngIf="CancellationChargedata && CancellationChargedata.CancellationResponse.Header.Status=='SUCCESS'">Please note that you will be charged of amount {{CompanySetting.Currency}} {{CancellationChargedata.CancellationResponse.ResponseBody.Cancellation.CancellationCharge}} which is the penalty for cancellation.</li>
                                <li *ngIf="CancellationChargedata && CancellationChargedata.CancellationResponse.Header.Status=='UNSUCCESS'">For cancellation of your hotel booking Reference Number- {{bookingDetail.BookingDetail.ReservationRef}}, please contact to our customer care at {{MobileNo}} or Email to : {{Email}}</li>
                            </ul>
                            <button *ngIf="CancellationChargedata && CancellationChargedata.CancellationResponse.Header.Status=='SUCCESS'" type="button" class="btn btn-sub btn-cancel" (click)="CancelBooking(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId)">
                                Cancel Booking
                            </button>
                            <button class="btn btn-sub" data-dismiss="modal">
                                Back to page
                            </button>
                        </div>
                        <div class="lr-box" *ngIf="!isAllowedCancellation">
                            <h3>Cancel Booking ?</h3>
                            <ul>
                                
                                <li *ngIf="bookingDetail">For cancellation of your hotel booking Reference Number- {{bookingDetail.BookingDetail.ReservationRef}}, please contact to our customer care at {{MobileNo}} or Email to : {{Email}}</li>
                            </ul>
                           
                            <button class="btn btn-sub" data-dismiss="modal">
                                Back to page
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-box">
        <div id="emailVoucher" class="modal fade">
            <div class="modal-dialog" role="document" *ngIf="isEmailVoucher">
                <div class="modal-content">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <div class="modal-body">
                        <div class="lr-box">
                            <h3>Send voucher / Ticket</h3>
                            <ul>
                                <li >Hotel voucher with booking reference no {{bookingDetail.BookingDetail.ReservationRef}}  will be send to</li>
                                <li >Do you want to send this voucher / ticket to some one?</li>
                                <li><input type="text" class="form-control"  placeholder="Email Id" id="emailId" name="emailId" value="" #emailId></li>
                            </ul>
                            <button type="button" *ngIf="showBookingDetail" class="btn btn-sub btn-cancel" (click)="EmailVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.HotelBooking[0].ProductId,emailId.value)">
                                Send
                            </button>
                            <button type="button" *ngIf="showBookingFlightDetail" class="btn btn-sub btn-cancel" (click)="EmailVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.FlightBooking[0].ProductId,emailId.value)">
                                Send
                            </button>
                            <button type="button" *ngIf="showBookingPackage" class="btn btn-sub btn-cancel" (click)="EmailVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.PackageBooking[0].ProductId,emailId.value)">
                                Send
                            </button>
                            <button type="button" *ngIf="showBookingSeightSeeing" class="btn btn-sub btn-cancel" (click)="EmailVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.ActivityBooking[0].ProductId,emailId.value)">
                                Send
                            </button>
                            <button type="button" *ngIf="showBookingTransfer" class="btn btn-sub btn-cancel" (click)="EmailVoucher(bookingDetail.BookingDetail.ReservationRef,bookingDetail.TransferBooking[0].ProductId,emailId.value)">
                                Send
                            </button>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>

<div id="voucherModel" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
            <h4 class="modal-title">Voucher</h4>
          <button type="button" class="close-v" data-dismiss="modal" (click)="showVoucher=!showVoucher">&times;</button>
          
        </div>
        <div class="modal-body">
            <div *ngIf="showVoucher" [innerHTML]="htmlStr"></div>
            <div *ngIf="!showVoucher">Loading...</div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="showVoucher=!showVoucher">Close</button>
        </div> -->
      </div>
  
    </div>
  </div>

  <div id="invoiceModel" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header pading-top4">
            <h4 class="modal-title">Invoice</h4>
            
          <button type="button" class="close-v" data-dismiss="modal" (click)="showInvoice=!showInvoice">&times;</button>
        
        </div>
        <div class="modal-body"> 
            <div *ngIf="showInvoice" [innerHTML]="htmlStrInvoice"></div>
            <div *ngIf="!showInvoice">Loading...</div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal" (click)="showInvoice=!showInvoice">Close</button>
        </div> -->
      </div>
  
    </div>
  </div>
  <div id="CancellationPolicyModel" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header pading-top4">
          <h4 class="modal-title">Cancellation Policy</h4>
            <button type="button" class="close-v" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">
          <div [innerHTML]="htmlStrCanxPolicy"></div>
          <div>Loading...</div>
        </div>
      </div>
</div>
  </div>
