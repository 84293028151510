import { CompanySettingService } from "../Service/company-setting.service";

export class HotelFeatureModel{
    HotelFeatureRequest:HotelFeatureRequest;
}
    export class HotelFeatureRequest {
        Master: HotelFeatureMaster;
        Authentication: HotelFeatureAuthentication;
        RequestDetails: HotelFeatureRequestDetails;
    }

    export class HotelFeatureMaster {
        CountryCode: string = "IN";
        CountryName: string = "India";
        CityCode: string;
        CityName: string;
        DisplayCountryCityName: string;
        CheckIn: Date;
        CheckOut: Date;
        Nationality: string = "IN";
        NationalityName: string = "Indian";
        Residence: string;
        ResidenceName: string;
        Mode: string = "system";
        UserAgent: string = "Mozila 5.0/Windows 7";
        AdditionalMarkup: string = "0";
        GrossCurrSym: string;
        AreaFlag: boolean;
        AreaCode: string;
        Duration: string;
        Comp_Curr: string = CompanySettingService.Currency;
        Agent_Curr: string = CompanySettingService.Currency;
        Gross_Curr: string = CompanySettingService.Currency;
        LangCode: string = "en";
        Agent_ROE: number = 0;
        Gross_ROE: number = 0;
        IP: string = "10.0.50.1";
        SessionId: string;
    }


    export class HotelFeatureAuthentication {
        AuthenticationKey: string = "2540D49A-FCFE-4422-9C4B-FBF2DA50B0BE";
        Channel: string = CompanySettingService.Channel;
        OnBehalfBooking: boolean = false;
        SubAgent: HotelFeatureSubAgent;
        CompanyId: string = CompanySettingService.CompanyCode;
        ServiceType: string = "Hotel";
    }
    export class HotelFeatureSubAgent {
        Id: number = 0;
        UserId: number = 0;
        UserName: string = "";
        BranchId: number = 0;
        SaBranchId: number = 0;
    }

    export class HotelFeatureRequestDetails {        
        Filter: HotelFeatureFilter;
    }
  
    export class HotelFeatureFilter {        
        ProductId: string="0";
        HotelId: string;        
        Provider: string="";       
    }

   

