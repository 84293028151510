import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any, filter: any): any {    
    if (filter && Array.isArray(items)) {            
      let filterKeys = Object.keys(filter);
  
        var result= items.filter(item => {
          return filterKeys.some((keyName) => {
            return new RegExp(filter[keyName], 'gi').test(item[keyName]) || filter[keyName] === "";
          });
        });
        if(filter.ServiceType!="" && filter.ServiceType!="All"){
        result=result.filter(item=>{
            return new RegExp(filter.ServiceType, 'gi').test(item['ServiceType']) || filter.ServiceType === "";
        });
    }
    if(filter.StatusText!="" && filter.StatusText!="All"){
        result=result.filter(item=>{
            return new RegExp(filter.StatusText, 'gi').test(item['StatusText']) || filter.StatusText === "";
        });
      
    }
    return result;
      
    } else {
      return items;
    }
  }
}
