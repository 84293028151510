<div class="breadcrumbs hotel_banner" style="padding-top: 80px;">
    <div class="container">
      <div class="text-left breadcrumbs-item" style="padding-top: 20px; padding-bottom: 20px;"> 
      
        <h2><span>ABOUT</span> US</h2>
        <div style="content: '';display: block;width: 100px;height: 3px;background: #06608c;border-radius: 25%;margin-top: 15px;margin-bottom: 5px;"></div>
      </div>
  
    </div>
  </div>
  
  <div class="main-body" style="padding-bottom: 30px;">
    <div class="container">
      <img src="assets/images/about-bg.jpg" style="padding-top: .5rem;padding-left:60px;" class="float-right img-fluid" alt="web development">
      <p><span>Source My Trip Pvt. Ltd.</span> brings a new dimension in travel trade and one of the fastest growing
        companies in travel trade. We provide full logistical support to our valuable customers around the world. We are
        offering a wide range of travel related services. Basically, we have provided a Whole sale booking system.
  
        we have developed a world class Booking Engine. We are offering to our valued clients More than
        5 lakhs hotels over 150 countries in 5 continents who can book our services online & get instant confirmation. We
        have taken considerable efforts to offer best possible price. We are always working towards providing value added
        services in order to generate extra revenue for our valuable customers.</p>
      <p>We are continuously providing various incentives plan to support our customers. We creating a landmark in hotel &
        holidays booking system. Our vision to work as one-stop-shop for tourism related services. We are investing our
        full potential and commitment in this project to provide maximum profit to our customers. With our highly
        experienced and talented staff we are confident to deliver the best to our customers.
  
        Our aim is to help people to enjoy the best of our services.
  
        We are pleased to offer unlimited services around the world </p>
    </div>
  </div>