<header class="top-header">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light nav-main">
            <a class="navbar-brand " routerLink="/">
                <img src="assets/images/logo-main.png" class="img-fluid nav-img"></a>
            <button class="navbar-toggler" data-target="#collapsibleNavbar" data-toggle="collapse" type="button">
                <i class="fa fa-bars"></i>
            </button>

            <div class="collapse navbar-collapse" data-target=".navbar-collapse.show" data-toggle="collapse"
                id="collapsibleNavbar">
                <ul class="navbar-nav justify-content-start w-100">
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active':product==='flight'}"
                            (click)="ChangeProduct('flight')"><img src="assets/images/plane.png">Flight <span
                                [ngClass]="{'active-br':product==='flight'}"></span></a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active':product==='hotel'}"
                            (click)="ChangeProduct('hotel')"><img src="assets/images/bed.png">Hotel <span
                                [ngClass]="{'active-br':product==='hotel'}"></span></a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active':product==='holiday'}"
                            (click)="ChangeProduct('holiday')"><img src="assets/images/beach.png">Holiday <span
                                [ngClass]="{'active-br':product==='holiday'}"></span></a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active':product==='transfer'}"
                            (click)="ChangeProduct('transfer')"><img src="assets/images/taxi.png">Transfer <span
                                [ngClass]="{'active-br':product==='transfer'}"></span></a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0)" class="nav-link" [ngClass]="{'active':product==='sightseeing'}"
                            (click)="ChangeProduct('sightseeing')"><img src="assets/images/binocular.png">Sightseening
                            <span [ngClass]="{'active-br':product==='sightseeing'}"></span></a>
                    </li>

                    

                </ul>
                <span class="login-nav">
                    <ul>
                        <li class="login-nav-inner-one" *ngIf="sharedServiceService.isShownCurrency" >
                            <a href="javascript:void(0)" class="login-nav-inner-one-btn" (click)="CurrencyShowPopup()">
                              <span *ngIf="expectedCurrency ==''">Currency</span> <span *ngIf="expectedCurrency !=''"><img 
                                src="assets/images/SMT/MappedCountryCode/{{expectedCurrencyCode}}.png" alt=""> | {{mappedCountryName}} | {{expectedCurrency}}</span>  <i class="fas fa-caret-down"></i> 
                            </a>
                            <div class="drp-one"  style="min-width:205px ;"  [ngStyle]="{'display':hideCurrencyPopup === true ? 'block' : 'none' }" >
                              
                                <a href="javascript:void(0)"  style="border-bottom: 1px dotted #bbb5b5;padding-bottom: 5px;padding-top: 5px;" *ngFor="let curr of CurrencyModelArray" (click)="CurrencyChange(curr.CurrencyCode+'-'+curr.currencySymbol+'-'+curr.MappedCountryCode+'-'+curr.MappedCountryName)">
                                    <img 
                        src="assets/images/SMT/MappedCountryCode/{{curr.MappedCountryCode}}.png" alt=""> | {{curr.MappedCountryName}} | {{curr.CurrencyCode}}</a>
                                <!-- <a href="javascript:void(0)">AUD</a>
                                <a href="javascript:void(0)">EUR</a>
                                <a href="javascript:void(0)">GBP</a>
                                <a href="javascript:void(0)" class="active">INR</a>
                                <a href="javascript:void(0)">KWD</a>
                                <a href="javascript:void(0)">SAR</a>
                                <a href="javascript:void(0)">USD</a> -->
                                <!-- <select  (change)="CurrencyChange($event.target.value)"   style="width:125px;">  
                                    <option *ngFor="let curr of CurrencyModelArray" [selected]="curr.CurrencyCode==sharedServiceService.selectedCurrency"  value="{{curr.CurrencyCode}}-{{curr.currencySymbol}}">{{curr.CurrencyCode}} </option>  
                                </select>   -->
                           
                            </div>
                        </li>
                        <li class="login-nav-inner-one">
                            <a href="javascript:void(0)" class="login-nav-inner-one-btn">
                                <i class="fas fa-headphones"></i>24x7 Helpline<i class="fas fa-caret-down"></i>
                            </a>
                            <div class="drp-one">
                                <a href="javascript:void(0)">011-41040505</a>
                                <a href="javascript:void(0)">chandra@sourcemytrip.com</a>
                            </div>
                        </li>
                        <li class="login-nav-inner-two">
                            <a href="javascript:void(0)" class="login-nav-inner-two-btn">
                                <i class="far fa-user"></i>My Account<i class="fas fa-caret-down"></i>
                            </a>
                            <div class="drp-two">
                                <!-- <a routerLink="/account/login" *ngIf="!isLogin">Sign In</a>
                              <a routerLink="/account/register" *ngIf="!isLogin">Register</a> -->
                                <a (click)="SignInRegisterclick('Sign In')" *ngIf="!isLogin">Sign In</a>
                                <a (click)="SignInRegisterclick('Register')" *ngIf="!isLogin">Register</a>
                                <a routerLink="/account/MyBookings" *ngIf="isLogin">My Bookings</a>
                                <a routerLink="/account/profile" *ngIf="isLogin">My Profile</a>
                                <a (click)="Logout()" *ngIf="isLogin">Logout</a>
                            </div>
                        </li>

                    </ul>
                </span>

            </div>
        </nav>
    </div>
</header>
<div style="width:80% !important;">
<ng-template #SignInRegisterPopup>
   
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{SignInRegister}}</h4>
        <button type="button" class="close pull-right" style="background: none;" aria-label="Close"
            (click)="modalRef.hide(); SignInRegister='';">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-account *ngIf="SignInRegister=='Sign In'"></app-account>
        <app-register *ngIf="SignInRegister=='Register'"></app-register>
        <app-forget-password *ngIf="SignInRegister=='Forget Password'"></app-forget-password>
    </div>

</ng-template>
</div>

