import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService implements CanActivate {

  constructor(public router: Router) { }
  
  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }


  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    return token!=null && token!=undefined ? true:false;
  }
}
