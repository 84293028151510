import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-declined',
  templateUrl: './payment-declined.component.html',
  styleUrls: ['./payment-declined.component.css']
})
export class PaymentDeclinedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
