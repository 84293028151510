import { CompanySettingService } from "../Service/company-setting.service";

export class HotelSearchModel{
    Request:Request=new Request();
}
    export class Request {
        Master: Master = new Master();;
        Authentication: Authentication=new Authentication();
        RequestDetails: RequestDetails=new RequestDetails();
    }

    export class Master {
        CountryCode: string = "IN";
        CountryName: string = "India";
        CityCode: string;
        CityName: string;
        DisplayCountryCityName: string;
        CheckIn:string;//  Date;
        CheckOut:string;//  Date;
        Nationality: string = "IN";
        NationalityName: string = "Indian";
        Residence: string;
        ResidenceName: string;
        Mode: string = "system";
        UserAgent: string = "Mozila 5.0/Windows 7";
        AdditionalMarkup: string = "0";
        GrossCurrSym: string;
        AreaFlag: boolean;
        AreaCode: string;
        Duration: string;
        Comp_Curr: string = CompanySettingService.Currency;
        Agent_Curr: string = CompanySettingService.Currency;
        Gross_Curr: string = CompanySettingService.Currency;
        LangCode: string = "en";
        Agent_ROE: number = 0;
        Gross_ROE: number = 0;
        IP: string = "10.0.50.1";
        SessionId: string;
    }


    export class Authentication {
        AuthenticationKey: string = "2540D49A-FCFE-4422-9C4B-FBF2DA50B0BE";
        Channel: string = CompanySettingService.Channel;
        OnBehalfBooking: boolean = false;
        SubAgent: SubAgent=new SubAgent();
        CompanyId: string = CompanySettingService.CompanyCode;
        ServiceType: string = "Hotel";
    }
    export class SubAgent {
        Id: number = 0;
        UserId: number = 0;
        UserName: string = "";
        BranchId: number = 0;
        SaBranchId: number = 0;
    }

    export class RequestDetails {
        Search: Search=new Search();
        Filter: Filter=new Filter();
        TripAdvisorDetailRequest: string;
        DistanceRequest: string;
    }
    export class Search {
        Rooms: RoomDetails=new RoomDetails();
    }
    export class RoomDetails{
        Room: HotelRoom[]=new Array<HotelRoom>();  
    }
    export class Filter {
        StarRating: string = "all";
        HotelName: string;
        OnRequest: boolean = true;
        NoOfResults: string;
        HotelDesc: boolean = false;
        GeoCode: boolean = true;
        PropertyType: string = "Hotel";
        Suppliers: number = 3;
        ProductId: string;
        HotelId: string;
        RoomId: string;
        Provider: string;
        Token: string;
        SpecialRequest: string;
        CountryCode: string;
        CityCode: string;
        CountryName: string;
        CityName: string;
        BedTypeId: string;
        SmokingId: string;
        PriceBreakup: boolean = false;
        FirstSupplierOnly: boolean = false;
        Type: string;
        HotelPrice: string;
    }

    export class HotelRoom {
        Adult: number = 2;
        Child: number = 0;
        ChildCountArray:any[]=[];
        ChildAge: number[]=[];
    }

