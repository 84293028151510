import { AccountService } from '../../Service/account.service';
import { loginRegisterModel, UserRQ, Authentication, RequestHeader, RequestBody, UserDetails } from '../../Model/loginRegisterModel';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationService } from 'src/app/Service/navigation.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  loader: boolean;
  registerModel: loginRegisterModel;
  @ViewChild("registerbtn", { static: false }) registerbtn: ElementRef;
  constructor(private accountService: AccountService, private navigationService: NavigationService
    , private toastr: ToastrService) { }

  ngOnInit() {
    this.registerModel = new loginRegisterModel();
    this.registerModel.UserRQ = new UserRQ();
    this.registerModel.UserRQ.Authentication = new Authentication();
    this.registerModel.UserRQ.RequestHeader = new RequestHeader();
    this.registerModel.UserRQ.RequestHeader.Method = "Registration";
    this.registerModel.UserRQ.RequestBody = new RequestBody();
    this.registerModel.UserRQ.RequestBody.UserDetails = new UserDetails();
  }

  myFormSubmitHandler() {
    let el: HTMLElement = this.registerbtn.nativeElement;
    el.click();
  }

  RegisterUser(valid) {
    if (valid) {
      this.loader = true;
      this.registerModel.UserRQ.RequestBody.UserDetails.UserName = this.registerModel.UserRQ.RequestBody.UserDetails.Email;
      this.registerModel.UserRQ.RequestBody.UserDetails.NewPassword = this.registerModel.UserRQ.RequestBody.UserDetails.Password;
      this.registerModel.UserRQ.RequestBody.UserDetails.ISDCode = "+91" + this.registerModel.UserRQ.RequestBody.UserDetails.MobileNo;

      this.accountService.register(this.registerModel).subscribe(data => {
        console.log(data);
        var alldata = data as any;
        if (alldata.UserRS.ResponseHeader.Status == "SUCCESS") {
          this.toastr.success('Registration successful');
          this.navigationService.SetLoginRegister('Registration');
        }
        else {
          this.toastr.warning(alldata.UserRS.ResponseHeader.Message);
        }
        this.loader = false;
      }, error => { this.toastr.error('Contact Admin \n' + error); this.loader = false; })
    }
  }
  gotologin() {
    this.navigationService.SetLoginRegister('Login popup show');
  }
}
