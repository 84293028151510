import { Injectable,EventEmitter } from '@angular/core'; 
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }
  private isFlightOpen = new BehaviorSubject('flight');
  currentSelected = this.isFlightOpen.asObservable();
  public isLoginRegister = new BehaviorSubject('');

  ChangeSelection(isShow: string) {
    this.isFlightOpen.next(isShow)
    
  }
  SetLoginRegister(val: string) {
    this.isLoginRegister.next(val)
    
  }
}
