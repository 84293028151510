import { RegisterComponent } from './register/register.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AccountComponent } from './account.component';
import { MyProfileComponent } from '../my-profile/my-profile.component';
import { AuthServiceService } from '../Service/auth-service.service';
import { MyBookingsComponent } from '../my-bookings/my-bookings.component';

const routes: Routes = [
{path:'profile',component:MyProfileComponent,canActivate :[AuthServiceService]},
{path:'MyBookings',component:MyProfileComponent,canActivate :[AuthServiceService]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
