<div class="result-area padding-7">
  <div class="container">
    <div class="row">
      <div class="loader-hotel" style="width:100% !important;">
        <div class="res-no-data error-four-main">
          <div class="res-no-data">
            <div class="four-img">
              <img src="assets/images/what-processing.gif"  class="process-img" />
            </div>
            <div class="four-page-not orange-clr">
              <span>Processing Your Booking..</span>
            </div>
            <div class="nf-details">
              Thank you for your booking.

            </div>
            <div class="nf-details1">
              Please wait while we process your booking.<br /><br /><br />
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
