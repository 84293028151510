<section class="hero-banner hero-banner-hotelv1">
    <div class="hero-content mb-0">
        <div class="container">
            <div class="text-center text-white mt-3">
                <h1 class="mb-2">Your New Journey Begins here</h1>
                <p>Hotels worldwide at your fingertips!</p>
            </div>
            <div class="row srch-bx">
                <form (ngSubmit)="SearchHotel(searchForm.form.valid)" #searchForm="ngForm" novalidate id="hsearchForm"
                    style="width: 100%;">
                    <div class="col-xl-12 col-lg-12 col-md-12 d-block mx-auto">
                        <div class="search-background mt-2">
                            <div class="form row no-gutters rw-sr">
                                <div class="form-group  col-xl-3 col-lg-3 col-md-12 mb-0">
                                    <img src="assets/images/placeholder.png" class="icon-sb" />

                                    <input [(ngModel)]="DestinationCity" [typeahead]="hotelResulList"
                                        [isAnimated]="true" typeaheadWaitMs="1000" [typeaheadScrollable]="true"
                                        [typeaheadOptionsInScrollableView]="20" placeholder="Destination or Hotel name"
                                        [typeaheadItemTemplate]="hotelSearchTemplate" autocomplete="off"
                                        [ngClass]="{'is-invalid': searchForm.submitted && hotelFromCity.invalid || !fromCityValid}"
                                        (typeaheadOnSelect)="formatHotelCity($event)"
                                        class="form-control location-input border-right br-tr-0 br-br-0 input-lg"
                                        name="hotelFromCity" (click)="$event.target.select()" #hotelFromCity="ngModel"
                                        required>
                                    <ng-template #hotelSearchTemplate let-model="item" let-index="index">
                                        <div class=" fxLayout" fxLayout.xs="column" fxLayoutAlign="center"
                                            fxLayoutGap="0">
                                            <div class="item item-1" fxFlex="85%">
                                                <div class="SearchAirportcity">
                                                    <i class="fas fa-map-marker-alt"></i>
                                                    {{ model.CityName }} ({{model.CityCode}}), <span>
                                                        {{model.CountryName}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>

                                <div class="form-group  col-xl-2 col-lg-2 col-md-12 mb-0 select2-lg border-right bg-light-30"
                                    (click)="checkin.show()">
                                    <img src="assets/images/calendar.png" class="icon-sb" />
                                    <input name="checkINDate" style="position: absolute; z-index: -1;"
                                        class="adatepicker" #checkin="bsDatepicker" bsDatepicker [outsideClick]="true"
                                        [(ngModel)]="HotelSearchModel.Request.Master.CheckIn"
                                        [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'YYYY-MM-DD' }"
                                        (bsValueChange)="HotelFromDateChange($event);" [minDate]="minDate"
                                        [maxDate]="fromMax">
                                    <span class="form-control location-input border-right br-tr-0 br-br-0 input-lg">{{
                                        HotelSearchModel.Request.Master.CheckIn | date:'dd-MMM-yyyy'}}</span>
                                </div>
                                <div class="form-group  col-xl-2 col-lg-2 col-md-12 mb-0 select2-lg bg-light-30"
                                    (click)="checkout.show()">
                                    <img src="assets/images/calendar.png" class="icon-sb" />

                                    <input name="checkOutDate" class="adatepicker"
                                        style="position: absolute; z-index: -1;" #checkout="bsDatepicker" bsDatepicker
                                        [outsideClick]="true" [(ngModel)]="HotelSearchModel.Request.Master.CheckOut"
                                        [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'YYYY-MM-DD' }"
                                        [minDate]="minCheckoutDate">
                                    <span class="form-control location-input border-right br-tr-0 br-br-0 input-lg">{{
                                        HotelSearchModel.Request.Master.CheckOut | date:'dd-MMM-yyyy'}}</span>
                                </div>
                                <div
                                    class="form-group  col-xl-3 col-lg-3 col-md-12 mb-0 select2-lg bg-light-30 dro-trv-main">
                                    <img src="assets/images/user.png" class="icon-sb" />

                                    <button type='button' class="form-control location-input border-right br-tr-0 br-br-0 input-lg">
                                        {{HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length}} Rooms {{GuestCount}} Guest</button>
                                    <div class="drop-trv">
                                        <div
                                            *ngFor="let room of HotelSearchModel.Request.RequestDetails.Search.Rooms.Room;let i=index;">
                                            <div class="rm-txt">
                                                Room {{i+1}} :
                                            </div>
                                            <div class="left-box">
                                                <span class="txt">
                                                    <span id="txt-innr">Adult <em>(Above 12 years)</em></span>
                                                </span>
                                            </div>
                                            <div class="right-box">
                                                <div class="pls-mns-row">
                                                    <a type="button" id="adt-min" class="sub" *ngIf="room.Adult>1"
                                                        (click)="DecreaseGuest('A',i)">-</a>
                                                    <span id="adlt-nbr" class="adlt-number">
                                                        {{room.Adult}}
                                                    </span>
                                                    <a type="button" id="adt-plus" class="plus" *ngIf="room.Adult<4"
                                                        (click)="IncreaseGuest('A',i)">+</a>
                                                </div>
                                            </div>
                                            <div class="space-rw">
                                            </div>
                                            <div class="left-box">
                                                <span class="txt">
                                                    <span id="txt-innr">Child <em>(Below 12 years)</em></span>
                                                </span>
                                            </div>
                                            <div class="right-box">
                                                <div class="pls-mns-row">
                                                    <a type="button" id="adt-min" class="sub" *ngIf="room.Child>0"
                                                        (click)="DecreaseGuest('C',i)">-</a>
                                                    <span id="adlt-nbr" class="adlt-number">
                                                        {{room.Child}}
                                                    </span>
                                                    <a type="button" id="adt-plus" class="plus" *ngIf="room.Child<4"
                                                        (click)="IncreaseGuest('C',i)">+</a>
                                                </div>
                                            </div>

                                            <div class="space-rw">
                                            </div>

                                            <div class="right-box" *ngIf="room.Child>0">
                                                <div class="pls-mns-row">
                                                    <div
                                                        *ngFor="let age of HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray;let c=index;">
                                                        <select
                                                            [(ngModel)]="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[c]"
                                                            name="age{{i}}{{c}}">
                                                            <option>1</option>
                                                            <option>2</option>
                                                            <option>3</option>
                                                            <option>4</option>
                                                            <option>5</option>
                                                            <option>6</option>
                                                            <option>7</option>
                                                            <option>8</option>
                                                            <option>9</option>
                                                            <option>10</option>
                                                            <option>11</option>
                                                            <option>12</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="clear"></div>
                                        <a class="add-room"
                                            *ngIf="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length<5"
                                            (click)="AddRoom()">Add Room
                                        </a>
                                        <a class="add-room"
                                            *ngIf="HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length>1"
                                            (click)="RemoveRoom()">Remove Room
                                        </a>
                                        <a href="javascript:void(0)" class="done-btn">
                                            Done
                                        </a>
                                    </div>
                                </div>
                                <div class="form-group  col-xl-2 col-lg-2 col-md-12 mb-0 select2-lg bg-light-30">
                                    <button #searchButton type="submit" hidden></button>
                                    <a (click)="myFormSubmitHandler()" href="javascript:void(0)" class="btn">Search</a>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<main>
    <section class="reason">
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-2 br-r1">
                    <div class="r-text">
                        <h3>REASON TO</h3>
                        <h4>BOOK WITH US</h4>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 br-r1 pl-3">
                    <div class="rbx">
                        <div class="icon-rb"> <img src="assets/images/price-tag.png"> </div>
                        <div class="icon-rb-txt"> BEST PRICE
                            <br>GUARANTEE
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 br-r1 pl-3">
                    <div class="rbx">
                        <div class="icon-rb"> <img src="assets/images/customer-service.png"> </div>
                        <div class="icon-rb-txt"> 24X7 LIVE CHAT
                            <br>SUPPORT
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 br-r1 pl-3">
                    <div class="rbx">
                        <div class="icon-rb"> <img src="assets/images/click.png"> </div>
                        <div class="icon-rb-txt"> EASY ONLINE
                            <br>TRANSACTION
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 br-r1 pl-3">
                    <div class="rbx">
                        <div class="icon-rb"> <img src="assets/images/secure-payment.png"> </div>
                        <div class="icon-rb-txt"> SECURE ONLINE
                            <br>TRANSACTION
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2  pl-3">
                    <div class="rbx">
                        <div class="icon-rb"> <img src="assets/images/customer-satisfaction.png"> </div>
                        <div class="icon-rb-txt"> GUEST REVIEWS
                            <br>& RATINGS
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="pop-destination">
        <div class="container" *ngIf="PopularDestinations!=null && PopularDestinations.length>0">
            <h2>Popular Destinations </h2>
            <p>Top destinations in the world. Best places to travel in 2021.</p> <span class="bar"></span>
            <div class="row pop-mt">
                <div class="col-md-7 on-flx" *ngIf="PopularDestinations.length>0">
                    <div class="box-des" (click)="scrollTosearch(PopularDestinations[0],'City');"> <img src="{{PopularDestinations[0].FrontImage}}">
                        <div class="box-content">
                            <p>{{PopularDestinations[0].CityName}} </p>
                            <h3>{{PopularDestinations[0].CountryName}}</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 tw-flx">
                    <div class="box-des" (click)="scrollTosearch(PopularDestinations[1],'City');" *ngIf="PopularDestinations.length>1"> <img src="{{PopularDestinations[1].FrontImage}}">
                        <div class="box-content">
                            <p>{{PopularDestinations[1].CityName}} </p>
                            <h3>{{PopularDestinations[1].CountryName}}</h3>
                        </div>
                    </div>
                    <div class="mr-spcr"></div>
                    <div class="box-des" (click)="scrollTosearch(PopularDestinations[2],'City');" *ngIf="PopularDestinations.length>2"> <img src="{{PopularDestinations[2].FrontImage}}">
                        <div class="box-content">
                            <p>{{PopularDestinations[2].CityName}} </p>
                            <h3>{{PopularDestinations[2].CountryName}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mr-spcr"></div>
            <div class="row">
                <div class="col-md-4 bl-flx" *ngIf="PopularDestinations.length>3">
                    <div class="box-des" (click)="scrollTosearch(PopularDestinations[3],'City');"> <img src="{{PopularDestinations[3].FrontImage}}">
                        <div class="box-content">
                            <p>{{PopularDestinations[3].CityName}} </p>
                            <h3>{{PopularDestinations[3].CountryName}}</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 bl-flx" *ngIf="PopularDestinations.length>4">
                    <div class="box-des" (click)="scrollTosearch(PopularDestinations[4],'City');"> <img src="{{PopularDestinations[4].FrontImage}}">
                        <div class="box-content">
                            <p>{{PopularDestinations[4].CityName}} </p>
                            <h3>{{PopularDestinations[4].CountryName}}</h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-4" *ngIf="PopularDestinations.length>5">
                    <div class="box-des" (click)="scrollTosearch(PopularDestinations[5],'City');"> <img src="{{PopularDestinations[5].FrontImage}}">
                        <div class="box-content">
                            <p>{{PopularDestinations[5].CityName}} </p>
                            <h3>{{PopularDestinations[5].CountryName}}</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="hotel-hm">
        <div class="container">
            <h2>Hotels in Popular Destinations </h2>
            <p>Book Top rated and best in class hotels</p> <span class="bar"></span>
            <div class="pop-mt" *ngIf="PopularHotels">
                <owl-carousel [options]="CustomOptions" [items]="PopularHotels"
                    [carouselClasses]="['row', 'owl-carousel']">
                    <div class="ol-itm" *ngFor="let hotel of PopularHotels;let i=index;">
                        <div class="pop-hbx" (click)="scrollTosearch(hotel,'Hotel');"> <img src="{{hotel.FrontImage}}" class="w-100"> <a href="javascript:void(0)">Book Now</a>
                            <div class="ph-txt"> {{hotel.Title}} </div>
                        </div>
                    </div>
                    
                </owl-carousel>
            </div>
        </div>
    </section>
    <section class="airlines">
        <h2>Top Hotels </h2>
        <p class="pop-p">World's best hotel</p> <span class="bar"></span>
        <div class="pop-mt"></div>
        <div class="container">
            <owl-carousel [options]="CustomOptions1"
                [carouselClasses]="['air-img', 'owl-carousel','sliding']">
                <div> <img src="assets/images/hotel/ibis-logo.svg"> </div>
                <div> <img src="assets/images/hotel/novotel-logo.svg"> </div>
                <div> <img src="assets/images/hotel/sheraton-logo.svg"> </div>
                <div> <img src="assets/images/hotel/choice-logo.svg"> </div>
                <div> <img src="assets/images/hotel/hyatt-logo.svg"> </div>
                <div> <img src="assets/images/hotel/whotels-logo.svg"> </div>
                <div> <img src="assets/images/hotel/ihg-logo.svg"> </div>
                <div> <img src="assets/images/hotel/crowneplaza-logo.svg"> </div>
                <div> <img src="assets/images/hotel/intercontinental-logo.svg"> </div>
                <div> <img src="assets/images/hotel/kempinski-logo.svg"> </div>
                <div> <img src="assets/images/hotel/swissotel-logo.svg"> </div>
                <div> <img src="assets/images/hotel/accor-logo.svg"> </div>
            </owl-carousel>
        </div>
    </section>
</main>