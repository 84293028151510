import { ApplyPromoCodeModel, Coupon, CouponDetails, CouponRQ, GeneralInfo, RequestBody, UserRQ } from './../Model/BookHotelModel';
import { Router } from '@angular/router';
import { HotelService } from './../Service/hotel.service';
import { Passenger } from './../Model/PassengerModel';
import { Request, Authentication, Filter } from './../Model/HotelSearchModel';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BookingModel, Rooms, Room } from '../Model/BookingModel';
import { BookHotelModel, BookingXML, Client, HotelInfo, PassengerDetails, PaymentDetail, Product, Item, Hotel_Booking_RQ, Payment, Master } from '../Model/BookHotelModel';
import { NavigationService } from '../Service/navigation.service';
import { AppSettings } from 'src/app/shared/appsettings';
import { AppSettingsService } from 'src/app/shared/appSettings.service';
import { environment } from 'src/environments/environment';
import { CompanySettingService } from '../Service/company-setting.service';
import { Subscription, timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { SharedServiceService } from '../Service/shared-service.service';

@Component({
  selector: 'app-hotel-passenger',
  templateUrl: './hotel-passenger.component.html',
  styleUrls: ['./hotel-passenger.component.css']
})
export class HotelPassengerComponent implements OnInit {
  PaymentURL: string = '';
  loader: boolean;
  hotelDetails: any;
  allHotelData: any;
  rooms: any;
  searchRequest: any;
  SelectedRoom: any;
  bookingModel: BookingModel;
  bookHotelModel: BookHotelModel;
  applyPromoCodeModel:ApplyPromoCodeModel;
  preBookReq: any;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  private settings: AppSettings;
  PaymentGateway: string = environment.defaultGateway;
  userdetails: any;
  IsProdution: boolean = environment.production;
  CompanySetting = CompanySettingService;
  countDown!: Subscription;
  counter = 1200;
  tick = 1000;
  IsSessionPopUp: boolean = false;
  modalRef: BsModalRef;
  totalPax:number;
  coupanCode:string;
  coupanStatus:string;
  coupanStatusText:string;
  CoupanAmount:number=0;
  IsCoupanAmount:boolean=true;
  coupon:Coupon;
  loadercoupon: boolean;
  constructor(private appSettingsService: AppSettingsService, private _hotelService: HotelService, private route: Router
    , private _navigation: NavigationService,private toastr: ToastrService,private modalService: BsModalService,public sharedServiceService:SharedServiceService) {
    this._navigation.ChangeSelection('hotel');
    this.PaymentURL = environment.PaymentURL;
    this.sharedServiceService.isShownCurrency=false;
    sessionStorage.setItem("currencySymbals", JSON.stringify(this.sharedServiceService.currencySymbol));
    sessionStorage.setItem("currencyConversionRate", JSON.stringify(this.sharedServiceService.currencyConversionRate));
    sessionStorage.setItem("selectedCurrency", JSON.stringify(this.sharedServiceService.selectedCurrency));
  }
  @ViewChild("termcondition", { static: false }) termcondition: TemplateRef<any>;

  ngOnInit() {
    // this.countDown = timer(0, this.tick).subscribe(() => {
    //   if (this.counter > 0)
    //     --this.counter;
    //   else {
    //     this.IsSessionPopUp = true;
    //     this.countDown.unsubscribe();
    //     this.toastr.error('Session Expired !');
    //   }
    // });
    this.startTimer();
    this.appSettingsService.getSettings().subscribe(settings => this.settings = settings);
    var hotelDetailJSON = sessionStorage.getItem("HotelDetailsRS");
    if (hotelDetailJSON != undefined && hotelDetailJSON != null) {
      this.allHotelData = JSON.parse(hotelDetailJSON);
      this.hotelDetails = this.allHotelData.Response.ResponseDetails.GetRoom.Hotels.Hotel[0];
      this.searchRequest = this.allHotelData.Response.Master;
      var SelectedRoomJSON = sessionStorage.getItem("SelectedRoom");
      if (SelectedRoomJSON != undefined && SelectedRoomJSON != null) {
        this.SelectedRoom = JSON.parse(SelectedRoomJSON);
      } else {
        this.route.navigate(['/hotel']);
      }

      var preBookJSON = sessionStorage.getItem("PreBookRQ");
      if (preBookJSON != undefined && preBookJSON != null) {
        this.preBookReq = JSON.parse(preBookJSON);
        this.rooms = this.preBookReq.Request.RequestDetails.PreBook.Rooms.Room;
        this.bookingModel = new BookingModel();
        this.bookingModel.Rooms = new Rooms();
        this.bookingModel.Rooms.Room = new Array<Room>();

        this.BookingRequest();

        this.rooms.forEach(data => {
          var room = new Room();
          room.Adult = new Array<Passenger>();
          room.Child = new Array<Passenger>();
          var passenger = new Passenger();

          var AdultArray = this.initilizeArray(data.Adult);
          var ChildArray = this.initilizeArray(data.Child);

          for (var i = 0; i < AdultArray.length; i++) {
            room.Adult.push(passenger);
          }
          for (var i = 0; i < ChildArray.length; i++) {
            room.Child.push(passenger);
          }
          this.bookingModel.Rooms.Room.push(room);
          this.totalPax=data.Adult+data.Child;
        });
        console.log(this.bookingModel);
      } else {
        this.route.navigate(['/hotel']);
      }
    }
    else {
      this.route.navigate(['/hotel']);
    }
    let userdetailsres = window.localStorage.getItem('UserDetails');
    if (userdetailsres) {
      this.userdetails = JSON.parse(userdetailsres);
      this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].EMailID=this.userdetails!=undefined?this.userdetails.Email:"";
      this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].MobileNumber=this.userdetails!=undefined?this.userdetails.MobileNo:"";
    } else {
      setTimeout(() => {
        this._navigation.SetLoginRegister('Login popup show');
      }, 1000);
    }
  }

  BookingRequest() {
    this.bookHotelModel = new BookHotelModel();
    this.bookHotelModel.BookingXML = new BookingXML();
    this.bookHotelModel.BookingXML.Client = new Client();
    this.bookHotelModel.BookingXML.HotelInfo = new HotelInfo();
    this.bookHotelModel.BookingXML.PassengerDetails = new PassengerDetails();
    this.bookHotelModel.BookingXML.PaymentDetail = new PaymentDetail();
    this.bookHotelModel.BookingXML.Product = new Product();

    this.bookHotelModel.BookingXML.Product.Item = new Item();
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ = new Hotel_Booking_RQ();
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Authentication = new Authentication();
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Filter = new Filter();
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master = new Master();
    this.bookHotelModel.BookingXML.PassengerDetails.Passenger = new Array<Passenger>();
    this.bookHotelModel.BookingXML.PaymentDetail.Payment = new Payment();

    this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayAmount = this.SelectedRoom.GrossPrice;
    this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayCurrency = this.CompanySetting.Currency;


    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Authentication = this.preBookReq.Request.Authentication;
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master = this.preBookReq.Request.Master;
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Filter = this.preBookReq.Request.RequestDetails.Filter;
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Filter.Provider = this.preBookReq.Request.RequestDetails.Filter.Provider;

    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master.CheckIn = this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master.CheckIn.split('T')[0];
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master.CheckOut = this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master.CheckOut.split('T')[0];
    this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Filter.HotelPrice = this.SelectedRoom.GrossPrice;
    this.bookHotelModel.BookingXML.GeneralInfo = new GeneralInfo();
    


    var _tmpAdt = 0;
    var _tmpChd = 0;
    var room = 1;
    this.rooms.forEach(data => {
      _tmpAdt += data.Adult;
      _tmpChd += data.Child;
      var AdultArray = this.initilizeArray(data.Adult);
      var ChildArray = this.initilizeArray(data.Child);

      for (var i = 0; i < AdultArray.length; i++) {
        var passenger = new Passenger();
        passenger.Room = room;
        passenger.Title = "Mr";
        passenger.PaxType = "ADT";
        passenger.PaxIndex = i + 1;
        this.bookHotelModel.BookingXML.PassengerDetails.Passenger.push(passenger);
      }
      for (var i = 0; i < ChildArray.length; i++) {
        var passenger = new Passenger();
        passenger.Room = room;
        passenger.Title = "Mr";
        passenger.PaxType = "CHD";
        passenger.PaxIndex = i + 1;
        this.bookHotelModel.BookingXML.PassengerDetails.Passenger.push(passenger);
      }
      room++;
    });

    this.bookHotelModel.BookingXML.PassengerDetails.Adults = _tmpAdt;
    this.bookHotelModel.BookingXML.PassengerDetails.Childs = _tmpChd;
    this.bookHotelModel.BookingXML.PassengerDetails.Infant = 0;

  }

  initilizeArray(i: number) {
    return new Array(i);
  }

  ProcedToPay(valid) {
    if (valid) {
      let userdetailsres = window.localStorage.getItem('UserDetails');
      if (userdetailsres) {
        this.userdetails = JSON.parse(userdetailsres);
        this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Authentication.SubAgent.UserId = this.userdetails.ClientId;
        this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Authentication.SubAgent.UserName = this.userdetails.UserName;
      }
      // else {
      //   this._navigation.SetLoginRegister('Login popup show');
      //   return;
      // }
      this.loader = true;
      this.bookHotelModel.BookingXML.Client.Title = this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].Title;
      this.bookHotelModel.BookingXML.Client.FirstName = this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].FirstName;
      this.bookHotelModel.BookingXML.Client.LastName = this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].LastName;
      this.bookHotelModel.BookingXML.Client.Email = this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].EMailID;
      this.bookHotelModel.BookingXML.Client.Mobile = this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].MobileNumber;
      this.bookHotelModel.BookingXML.Client.AreaCode = "+91";
     
      if(this.CoupanAmount!=0)
      {
        this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayAmount=this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayAmount-this.CoupanAmount;
        this.bookHotelModel.BookingXML.CouponDetails=new CouponDetails();
        this.bookHotelModel.BookingXML.CouponDetails.Coupon=new Coupon();
        this.bookHotelModel.BookingXML.CouponDetails.Coupon=this.coupon;
      }
      else
      {
        this.bookHotelModel.BookingXML.CouponDetails=new CouponDetails();
        this.bookHotelModel.BookingXML.CouponDetails=null;
      }
      sessionStorage.setItem("bookHotelModel", JSON.stringify(this.bookHotelModel));
      var MiddleName = this.bookHotelModel.BookingXML.PassengerDetails.Passenger[0].MiddleName;
      MiddleName = MiddleName == null || MiddleName == undefined ? '' : MiddleName;
      var PaymentRq = '{"PaymentRq":{"AppRedirectURL":"' + environment.AppRedirectURLHotel + '","Authentication":{"AuthenticationKey":"","CompanyId":"' + this.bookHotelModel.BookingXML.GeneralInfo.CompanyId + '","IPAddress":"' + this.bookHotelModel.BookingXML.HotelInfo.Hotel_Booking_RQ.Master.IP + '","ChannelCode": "' + this.bookHotelModel.BookingXML.GeneralInfo.Channel + '","DomainUrl":null},"PaymentDetail":{"PaymentSource":"' + this.PaymentGateway + '","Amount": ' + this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayAmount + ',"RequestCurrency":"' + this.bookHotelModel.BookingXML.PaymentDetail.Payment.TxnCurrency + '","Title": "' + this.bookHotelModel.BookingXML.Client.Title + '","FirstName": "' + this.bookHotelModel.BookingXML.Client.FirstName + '","MiddleName": "' + MiddleName + '","LastName": "' + this.bookHotelModel.BookingXML.Client.LastName + '","EmailId": "' + this.bookHotelModel.BookingXML.Client.Email + '","PhoneNo": "' + this.bookHotelModel.BookingXML.Client.Mobile + '","Address": "XYZ","Remarks": ""}}}';

      //======================payment check start============================
      if (!environment.isPaymentHit) {
        this.route.navigate(['/hotel-payment'], { queryParams: { ST: 'SUCCESS', OID: 'TEST', AMT: this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayAmount, PGTRANSID: 'TEST' } });
      } else {
        this._hotelService.PaymentInitiate(PaymentRq).subscribe(data => {
          //this.loader=false;
          var alldata = data as any;
          if (alldata.d != null && alldata.d != undefined && alldata.d != '') {
            var transId = alldata.d;

            window.location.href = this.PaymentURL + this.PaymentGateway + "&OID=" + transId + "";

          }
        }, err => {
          console.log(err);
          this.loader = false;
        });
      }
      //======================payment check end============================

      // this._hotelService.BookHotel(this.bookHotelModel).subscribe(data=>{            
      //   var alldata=data as any;
      //   if(alldata.BookingXML.BookingSuccessful){
      //     sessionStorage.setItem("bookingRS",JSON.stringify(alldata));
      //     this.route.navigate(['/hotel-confirmation']);
      //   }
      //   else{
      //     this.loader=false;
      //   }
      // });
    } else {
      this.scrollToError();
      return false;
    }

  }
  TermConditionpopup(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-dialog-width' });
  }
  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  time: number = 20*60;
  display ;
  interval;

 startTimer() {
    console.log("=====>");
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.pauseTimer();
        this.IsSessionPopUp=true;
      
      } else {
        this.time--;
      }
      this.display=this.transform( this.time)
     
      
    }, 1000);
  }
  transform(value: number): string {
       const minutes: number = Math.floor(value / 60);
       return minutes + 'm:' + (value - minutes * 60)+'s';
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  ApplyCoupanCode(coupanCode) {
    if(coupanCode!="")
    {
      this.loadercoupon=true;
     this.applyPromoCodeModel=new ApplyPromoCodeModel();
     this.applyPromoCodeModel.UserRQ=new UserRQ();
     this.applyPromoCodeModel.UserRQ.Authentication=new Authentication();
     this.applyPromoCodeModel.UserRQ.RequestBody=new RequestBody();
     this.applyPromoCodeModel.UserRQ.RequestBody.CouponRQ=new CouponRQ();
     this.applyPromoCodeModel.UserRQ.RequestBody.CouponRQ.CouponCode=coupanCode;
     this.applyPromoCodeModel.UserRQ.RequestBody.CouponRQ.ServiceType="HTL";
     this.applyPromoCodeModel.UserRQ.RequestBody.CouponRQ.GrossPrice=this.bookHotelModel.BookingXML.PaymentDetail.Payment.PayAmount;
     this.applyPromoCodeModel.UserRQ.RequestBody.CouponRQ.NoofPax=this.totalPax;
     this.applyPromoCodeModel.UserRQ.RequestBody.CouponRQ.ClientId=0;
      this._hotelService.ApplyCoupanCode(this.applyPromoCodeModel).subscribe(data => {
        var alldata = data as any;
        this.coupanStatus=alldata.CouponResponse.CouponStatus;
        this.coupon=alldata.CouponResponse;
        if(alldata.CouponResponse.CouponStatus=="NOTEXIST")
        {
          this.coupanStatusText="Coupon not exist";
          
        }
        else if(alldata.CouponResponse.CouponStatus=="EXPIRED")
        {
          this.coupanStatusText="Coupon Expired";
        }
        else
        {
          this.coupanStatusText="Coupon applied";
          this.CoupanAmount=alldata.CouponResponse.DiscountAmount
          this.SelectedRoom.GrossPrice=this.SelectedRoom.GrossPrice-this.CoupanAmount;
          this.IsCoupanAmount=true;
        }
        this.loadercoupon=false;
      }, err => {
        console.log(err);
        this.loadercoupon = false;
      });
    }
    else
    {
      this.coupanStatusText="Enter Coupon";
    }
  }
 RemoveCoupanCode()
 {
  this.SelectedRoom.GrossPrice=this.SelectedRoom.GrossPrice+this.CoupanAmount;
  this.coupanStatusText="";
  this.IsCoupanAmount=false;
 }
}
