import { Request, Master, Authentication, RequestDetails, Filter, Search, HotelRoom, RoomDetails, HotelSearchModel } from './../Model/HotelSearchModel';
import { HotelService } from './../Service/hotel.service';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Observable, of, Observer, Subscriber } from 'rxjs';
import { Router } from '@angular/router';
import { OwlOptions } from 'owl.carousel';
import { NavigationService } from '../Service/navigation.service';
import { CommonService } from '../Service/common.service';
import { SharedServiceService } from '../Service/shared-service.service';

interface CityResponse {
  Type: string;
  CityCode: string;
  CityName: string;
  CountryCode: string;
  CountryName: string;
  Name:string;
  DisplayCityName:string;
  DisplayCountryName:string;
  DisplayName:string;
  Priority:number;
  Seq:number;
}

@Component({
  selector: 'app-hotel',
  templateUrl: './hotel.component.html',
  styleUrls: ['./hotel.component.css']
})
export class HotelComponent implements OnInit {

  hotelResulList: Observable<any>;
  DestinationCity:string="";
  selectedDestination:any;
  GuestCount:number;
  HotelSearchModel:HotelSearchModel;
  fromCityValid:boolean=true;
  carouselOptions:any;
  
  @ViewChild("searchButton",{static:false}) searchButton: ElementRef;

  minDate: Date;
  fromMax: Date;
  minCheckoutDate: Date;
  CustomOptions: OwlOptions = {
    items: 4,
    dots: false,
    navigation: true,
    autoplay: true,
    pagination: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      980: {
        items: 2
      },
      1199: {
        items: 4
      }
    },
    nav: true
  }
  CustomOptions1: OwlOptions = {
    items: 6,
    dots: false,
    navigation: true,
    autoplay: true,
    pagination: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 1
      },
      980: {
        items: 2
      },
      1199: {
        items: 6
      }
    },
    nav: true
  }
  PopularDestinations=[];
  PopularHotels=[];
  constructor(public _hotelService:HotelService, private route: Router,private _navigation: NavigationService
    ,private _CommonService:CommonService,public sharedServiceService:SharedServiceService) { 
      this.sharedServiceService.isShownCurrency=true;
    this.minDate = new Date();
    const todayDate = new Date();
    this.fromMax = new Date(todayDate.setFullYear(todayDate.getFullYear()+1)); 
    this._navigation.ChangeSelection('hotel');
  }

  

  ngOnInit() { 
    this.HotelSearchModel=new HotelSearchModel();   
    this.HotelSearchModel.Request=new Request(); 
    this.HotelSearchModel.Request.Master=new Master();
    this.HotelSearchModel.Request.Master.CheckIn=new Date().toString();     
    var todayDate=new Date();
    var tempcheckoutDate = new Date(todayDate.setDate(todayDate.getDate() + 1));
    this.minCheckoutDate = tempcheckoutDate 
    this.HotelSearchModel.Request.Master.CheckOut=tempcheckoutDate.toString();
    this.HotelSearchModel.Request.RequestDetails=new RequestDetails();
    this.HotelSearchModel.Request.RequestDetails.Filter=new Filter();
    this.HotelSearchModel.Request.RequestDetails.Search=new Search();
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms=new RoomDetails()
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room=new Array<HotelRoom>();
    var room=new HotelRoom();
    room.Adult=2;
    room.Child=0;
    room.ChildAge=[];

    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
    this.GetTotalGuest();

    this.hotelResulList = Observable.create((observer: Observer<any[]>) => {
      this._hotelService.GetCCity(this.DestinationCity).subscribe((responseItems: any[]) => {        
        var data =responseItems as any;
          observer.next(data.CityResponse.Destinations.DestinationItems);
      });
    });
    this.GetHotelSuppliers();
    this.GetRecommendedInfo();
  }


    formatHotelCity(result: any){       
      this.DestinationCity= result.item.DisplayName;
      this.selectedDestination = result.item;
    this.HotelSearchModel.Request.Master.CityName=this.selectedDestination.CityName;
    this.HotelSearchModel.Request.Master.CountryCode=this.selectedDestination.CountryCode;
    this.HotelSearchModel.Request.Master.CityCode=this.selectedDestination.CityCode;
    this.HotelSearchModel.Request.Master.CountryName=this.selectedDestination.CountryName;
    this.HotelSearchModel.Request.Master.DisplayCountryCityName=this.selectedDestination.DisplayCountryCityName;
    this.HotelSearchModel.Request.RequestDetails.Filter.Type = this.selectedDestination.Type;
    if (this.HotelSearchModel.Request.RequestDetails.Filter.Type == "Hotel")
      this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = this.selectedDestination.DisplayName.substring(0, this.selectedDestination.DisplayName.indexOf(','));
    else
      this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = "";
      this.fromCityValid=true;
    }
  
  HotelFromDateChange(date) {
    if (date != undefined && !isNaN(date.getTime())) {
      var tempdate = new Date(date);
      tempdate.setHours(0, 0, 0, 0);
      var tempcheckd = new Date(this.HotelSearchModel.Request.Master.CheckOut);
      tempcheckd.setHours(0, 0, 0, 0);

      if (tempdate >= tempcheckd) {
        let fromDate = new Date(date);
        let tempcheckoutDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
        this.minCheckoutDate = tempcheckoutDate;
        this.HotelSearchModel.Request.Master.CheckOut = tempcheckoutDate.toString();

      } else {
        let fromDate = new Date(date);
        let tempcheckoutDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
        this.minCheckoutDate = tempcheckoutDate;
      }
    }
  }

  GetTotalGuest(){
    var guestCount=0;
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.forEach(room => {
      guestCount+= room.Adult+room.Child;
    });
    this.GuestCount=guestCount;
  }

  AddRoom(){
    var room=new HotelRoom();
    room.Adult=2;
    room.Child=0;
    room.ChildAge=[];
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
    this.GetTotalGuest();
  }

  RemoveRoom(){
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.pop();
    this.GetTotalGuest();
  }

  IncreaseGuest(type,i){
    if(type=='A'){
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult=this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult+1;
    }
    if(type=='C'){
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child=this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child+1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child-1]=1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray = Array(this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child).fill(0).map((x,i)=>i);
    }
    this.GetTotalGuest();

  }

  DecreaseGuest(type,i){
    if(type=='A'){
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult=this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult-1;
    }
    if(type=='C'){
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child=this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child-1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge.pop();
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child-1]=1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray = Array(this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child).fill(0).map((x,i)=>i);
    }
    this.GetTotalGuest();

  }
  myFormSubmitHandler(){
    let el: HTMLElement = this.searchButton.nativeElement;
    el.click();
  }
  SearchHotel(vaild){    
    if(this.HotelSearchModel.Request.Master.CityName==undefined || this.HotelSearchModel.Request.Master.CityName==null){
      this.fromCityValid=false;
      return false;
    }
    if(vaild){
      localStorage.removeItem("hotelRequest");
      localStorage.removeItem("selectedDestination");
      this.HotelSearchModel.Request.RequestDetails.Filter.HotelId = "";

    this.HotelSearchModel.Request.Authentication=new Authentication();

    var _dt=new Date(this.HotelSearchModel.Request.Master.CheckIn);
    var _dt1=new Date(this.HotelSearchModel.Request.Master.CheckOut);  

    var _CheckIn=_dt.getFullYear()+"-"+((_dt.getMonth()+1)<10?"0"+(_dt.getMonth()+1):(_dt.getMonth()+1))+"-"+(_dt.getDate()<10?"0"+_dt.getDate():_dt.getDate());
    var _CheckOut=_dt1.getFullYear()+"-"+((_dt1.getMonth()+1)<10?"0"+(_dt1.getMonth()+1):(_dt1.getMonth()+1))+"-"+(_dt1.getDate()<10?"0"+_dt1.getDate():_dt1.getDate());
    
    this.HotelSearchModel.Request.Master.CheckIn=_CheckIn;
    this.HotelSearchModel.Request.Master.CheckOut=_CheckOut;

    let checkIn = this.HotelSearchModel.Request.Master.CheckIn;
    let checkOut = this.HotelSearchModel.Request.Master.CheckOut;
    let pax='2$0';//2$2^4,5|2$0
      for (let i = 0; i < this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length; i++) {
      let room=this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i];
        if (i == 0)
          pax = '';
        else
        pax = pax+'|'; 

        pax = pax+room.Adult + '$' + (room.Child == 0 ? '0' : room.Child + '^' + room.ChildAge.join(','));
      }
      let type=this.HotelSearchModel.Request.RequestDetails.Filter.Type;
      let hotelName='';
      if (type == "Hotel")
        hotelName = this.HotelSearchModel.Request.RequestDetails.Filter.HotelName;

      this.route.navigate(['/hotel-result'], { queryParams: { checkin: checkIn, checkout: checkOut, pax: pax, type: type, cityCode: this.HotelSearchModel.Request.Master.CityCode, cityName: this.HotelSearchModel.Request.Master.CityName, cCode: this.HotelSearchModel.Request.Master.CountryCode, cName: this.HotelSearchModel.Request.Master.CountryName, searchText: this.HotelSearchModel.Request.Master.CityName + ', ' + this.HotelSearchModel.Request.Master.CountryName,hotelName:hotelName } });
      
  // localStorage.setItem("hotelRequest",JSON.stringify(this.HotelSearchModel));
  // localStorage.setItem("selectedDestination",JSON.stringify(this.selectedDestination));
  // this.route.navigate(['/hotel-result']);
    }
  }
  GetHotelSuppliers(){
    this._hotelService.GetHotelsSuppliers().subscribe(data => {
      var allData = data as any;
    sessionStorage.setItem("hotelSuppliers",JSON.stringify(allData.ActiveSuppliers));
    });
  }
  
  scrollTosearch(data,type): void {

    let hotelName = '';
    if (type == 'Hotel')
      if (data.HotelName != '' && data.HotelName != undefined)
        hotelName = data.HotelName + ', ';
      else
        type = 'City';

    let results = {
      Type: type,
      CityCode: data.CityCode,
      CityName: data.CityName,
      CountryCode: data.CountryCode,
      CountryName: data.CountryName,
      Name: data.CityName,
      DisplayCityName: data.CityName,
      DisplayCountryName: data.CountryName,
      DisplayName: hotelName+ data.CityName + ", " + data.CountryName,
      Priority: 1,
      Seq: 0
    };
    let result = {
      item: results
    }
    this.formatHotelCity(result);
    const firstElementWithError = document.querySelector('.location-input');
    this.scrollTo(firstElementWithError);
  }
  scrollTo(el: Element): void {
    if(el) {  
     el.scrollIntoView({ behavior: 'smooth', block: 'center',inline: 'center' });     
    }
  }
  GetRecommendedInfo() {
    var recommendedInfo = window.localStorage.getItem("RecommendedInfo");
    if (recommendedInfo != null || recommendedInfo != undefined) {
      let objrecommendedInfo = JSON.parse(recommendedInfo);
      if (objrecommendedInfo.PopularDestinations != null) {
        this.PopularDestinations = objrecommendedInfo.PopularDestinations;
      }
      if (objrecommendedInfo.PopularHotels != null) {
        this.PopularHotels = objrecommendedInfo.PopularHotels;
      }
    } else {
      this._CommonService.GetRecommendedInfo().subscribe(data => {
        var allData = data as any;
        if (allData.Recommended != null) {
          window.localStorage.setItem("RecommendedInfo",JSON.stringify(allData.Recommended));

          if (allData.Recommended.PopularDestinations != null) {
            this.PopularDestinations = allData.Recommended.PopularDestinations;
          }
          if (allData.Recommended.PopularHotels != null) {
            this.PopularHotels = allData.Recommended.PopularHotels;
          }
        }
      });
    }
  }
}
