import { CompanySettingService } from "../Service/company-setting.service";

export class RoomPolicyModel{
    Request:RoomPolicyRequest;
}
    export class RoomPolicyRequest {
        Master: RoomPolicyMaster;
        Authentication: RoomPolicyAuthentication;
        RequestDetails: RoomPolicyRequestDetails;
    }

    export class RoomPolicyMaster {
        CountryCode: string = "IN";
        CountryName: string = "India";
        CityCode: string;
        CityName: string;
        DisplayCountryCityName: string;
        CheckIn: Date;
        CheckOut: Date;
        Nationality: string = "IN";
        NationalityName: string = "Indian";
        Residence: string;
        ResidenceName: string;
        Mode: string = "system";
        UserAgent: string = "Mozila 5.0/Windows 7";
        AdditionalMarkup: string = "0";
        GrossCurrSym: string;
        AreaFlag: boolean;
        AreaCode: string;
        Duration: string;
        Comp_Curr: string = CompanySettingService.Currency;
        Agent_Curr: string = CompanySettingService.Currency;
        Gross_Curr: string = CompanySettingService.Currency;
        LangCode: string = "1";
        Agent_ROE: number = 0;
        Gross_ROE: number = 0;
        IP: string = "10.0.50.1";
        SessionId: string;
    }


    export class RoomPolicyAuthentication {
        AuthenticationKey: string = "2540D49A-FCFE-4422-9C4B-FBF2DA50B0BE";
        Channel: string = CompanySettingService.Channel;
        OnBehalfBooking: boolean = false;
        SubAgent: RoomPolicySubAgent;
        CompanyId: string = "RBS";
        ServiceType: string = "Hotel";
    }
    export class RoomPolicySubAgent {
        Id: number = 0;
        UserId: number = 0;
        UserName: string = "";
        BranchId: number = 0;
        SaBranchId: number = 0;
    }

    export class RoomPolicyRequestDetails {        
        Filter: RoomPolicyFilter;
        CancellationPolicy:CancellationPolicy;
        PreBook:CancellationPolicy;
    }
  
    export class RoomPolicyFilter {        
        ProductId: string="0";
        HotelId: string;        
        Provider: string="4";    
        RoomId:string;   
        Token:string;
    }
    export class CancellationPolicy {
        Rooms: RoomPolicyRoomDetails;
    }
    export class RoomPolicyRoomDetails{
        Room: RoomPolicyHotelRoom[];  
    }
    export class  RoomPolicyHotelRoom {
        Adult: number = 2;
        Child: number = 0;
        ChildCountArray:any[];
        ChildAge: number[];
    }
   

