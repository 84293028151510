import { Header } from './VoucherModel';
import { Authentication, RequestBody } from './MyBookingModel';


export class BookingDetailModel{
    BookingDetailRequest:BookingDetailRequest;
}


export class BookingDetailRequest{
    Authentication:Authentication;
    RequestBody:RequestBody;
    Header:Header;
}