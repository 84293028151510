<!--breadcrumb--box-->
<div class="breadcrumb">
    <div class="container">
        <span>Home<i class="fas fa-chevron-right"></i>Booking Complete</span>
    </div>
</div>


<!---hotel-thankyou-->
<section class="hb-confrm">
    <div class="container">
      <div class="hb-confrm-inner">
        <div class="bkg-txt" *ngIf="allConfirmatonData.BookingXML.Error!=null && allConfirmatonData.BookingXML.Error.Status=='NOTOK'">
          <i class="fa fa-times-circle" style="font-size: 48px; color: red"></i>
          <h2>
            Oops ! You have refreshed Page or Something Technical Challenges.
            
          </h2>
          <h3>Kindly contact with administrator</h3>         
        </div>

        <div class="bkg-txt" *ngIf="allConfirmatonData.BookingXML.ReservationRef && allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ!=null && allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ.length>0">
          <i class="fas fa-check-circle"></i>
          <h3>
            Thanks {{allConfirmatonData.BookingXML.Client.FirstName}}
            {{allConfirmatonData.BookingXML.Client.LastName}}
          </h3>
          <p>Your Booking in Hotel {{hoteldetail.Name}} is <span class="orange-clr"> {{BookingStatus(allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ[0].Hotel.BookingStatus)}}</span></p>
          <span>Confirmation Number: {{allConfirmatonData.BookingXML.ReservationRef}}</span>
        </div>
        <div class="bkg-txt" *ngIf="!allConfirmatonData.BookingXML.ReservationRef && allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ!=null && allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ.length>0">
          <i class="fa fa-times-circle" style="font-size:48px;color:red"></i>
          <h3>
            Thanks {{allConfirmatonData.BookingXML.Client.FirstName}}
            {{allConfirmatonData.BookingXML.Client.LastName}}
          </h3>
          <p>Your Booking in Hotel {{hoteldetail.Name}} is <span class="red-color">{{BookingStatus(allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ[0].Hotel.BookingStatus)}}</span></p>
        </div>
        <div class="hotel-info-payment bkg-hdtl">
          <div class="hotel-img-sp">
            <img [src]="hoteldetail.Image" class="img-fluid img-thumbnail">
          </div>
          <div class="htl-info-details">
            <div class="one-title-par">
              <h1 class="one-title">
                {{hoteldetail.Name}}
                <span class="str-rw">
                  <i *ngFor="let star of ' '.repeat(allConfirmatonData.BookingXML.HotelInfo.Hotel_Booking_RQ[0].Hotel.Star).split('')" class="fas fa-star"></i>
                </span>               
              </h1>
              <div class="ld-location">
                <i class="fas fa-map-marker-alt orange-clr"></i> <span class="booking-msg-text">&nbsp;{{hoteldetail.DisplayAdd}}</span>
              </div>
            </div>
          
            <div class="rstic-pay"  *ngIf="selectedRoom!=null">
              <div class="stc-pay-in">
                <span>Check In</span><p>{{hotelRequest.CheckIn | date: "dd MMM yy"}}</p>
              </div>
              <div class="stc-pay-in">
                <span>Check Out</span>
                <p>{{hotelRequest.CheckOut | date: "dd MMM yy"}}</p>
              </div>
              <div class="stc-pay-in">
                <span>Room(s)</span>
                <p>{{selectedRoom.NoOfRoom}}</p>
              </div>
              <div class="stc-pay-in">
                <span>Meal Plan</span>
                <p>{{selectedRoom.DisplayMealDesc}}</p>
              </div>
              <div class="stc-pay-in">
                <span>Room Type</span><p>{{hoteldetail.Rooms.Room[0].Desc}}</p>
              </div><div class="stc-pay-in"><span>Guest</span><p>{{selectedRoom.NoOfAdult}} Adult(s), {{selectedRoom.NoOfChild}} Child</p></div>
            </div>
          </div>
        </div>
        <div class="bkg-hdtl tab-content" *ngIf="allConfirmatonData.BookingXML.PaymentDetail.Payment!=null && allConfirmatonData.BookingXML.PaymentDetail.Payment.length>0">
          <div class="row header rel-class">
            <h4 class="title heading-title-size">Payment Info</h4>
          </div>
          <div class="row">
            <div class="col-lg-3">
              <span>Payment Ref No</span>
              <p>{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PGTxnCode}}</p>
            </div>
            <div class="col-lg-3">
              <span>Payment Mode</span>
              <p>{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PGType}}</p>
            </div>
            <div class="col-lg-3">
              <span>Payment Status</span>
              <p>{{allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PGResponseDesc}}</p>
            </div>
            <div class="col-lg-3">
              <span>Total Price</span>
              <dl>
                <dd *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon==null">
                  {{sharedServiceService.currencySymbol}}
                  &nbsp;{{(allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PayAmount)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}
                </dd>
                <dd *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon!=null">
                  {{sharedServiceService.currencySymbol}}
                  &nbsp;{{(allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PayAmount+allConfirmatonData.BookingXML.CouponDetails.Coupon.DiscountAmount)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}
                </dd>
                <dt *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon!=null">Discount</dt>

                <dd *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon!=null">
                  {{sharedServiceService.currencySymbol}}
                  &nbsp;{{(allConfirmatonData.BookingXML.CouponDetails.Coupon.DiscountAmount)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}
                </dd>

                <dt *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon!=null">Total Net Price</dt>

                <dd *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon!=null">
                  {{sharedServiceService.currencySymbol}}
                  &nbsp;{{(allConfirmatonData.BookingXML.PaymentDetail.Payment[0].PayAmount)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}
                </dd>
              </dl>
            </div>

          </div>
        </div>
          <!--Coupen Details-->
          <div *ngIf="allConfirmatonData.BookingXML.CouponDetails.Coupon!=null" class="bkg-hdtl">
            <h2>Discount Details</h2>
            <dl class="term-description">
              <dt>PromoCodeUsed</dt>
              <dd class="total-price-value">{{allConfirmatonData.BookingXML.CouponDetails.Coupon.CouponCode}}</dd>
              <dt>DiscountAmount</dt>
              <dd>
                {{sharedServiceService.currencySymbol}}
                &nbsp;{{(allConfirmatonData.BookingXML.CouponDetails.Coupon.DiscountAmount)*(sharedServiceService.currencyConversionRate) | number:'1.2-2'}}
              </dd>
            </dl>

            <p class="red-color">
              Paymentismadeby
              OnlinePayment
            </p>
          </div>
          <div class="bkg-hdtl tab-content">
            <div class="row header rel-class">
              <h4 class="title heading-title-size">Guest Info</h4>
            </div>
            <div class="row" *ngFor="let room of Passengers;let i=index;">
              <div class="col-lg-2">
                <label class="maroon"><b>Room- {{room.room}} </b></label>
              </div>
              <div class="col-lg-10">
                <dl class="other-details" *ngFor="let pax of room.values;let px=index;">
                  <dt class="feature" style="float: left;margin-right: 5px; font-weight:normal">
                    {{pax.Title}}
                    {{pax.FirstName}}
                    {{pax.MiddleName}}
                    {{pax.LastName}}
                  </dt>
                  <dd class="value">
                     | {{pax.PaxType}} | {{pax.Gender}}
                  </dd>
                </dl>
              </div>
            </div>
            </div>           

            <!--if (Model.HotelInfo.Hotel_Booking_RQ[0].Hotel.BookingStatus == "HK")-->
            <div class="bkg-hdtl bkg-voucher">
              <a href="javascript:void(0)" class="print-button button btn-small soap-popupbox"
                 style="margin-left:20px;" data-toggle="modal" data-target="#Invoicepopup"
                 (click)="getInvoice(allConfirmatonData.BookingXML.ReservationRef)">Show Invoice</a>
              <a href="javascript:void(0)" class="print-button button btn-small soap-popupbox" data-target="#emailModal"
                 style="margin-left:20px;" data-toggle="modal">Email Voucher</a>

              <a style="margin-left:20px;" data-toggle="modal" data-target="#myModal"
                 (click)="getVoucher(allConfirmatonData.BookingXML.ReservationRef)" href="javascript:void(0)">
                <i class="fas fa-download"></i>Get the print Receipt
              </a>
            </div>
          </div>
      </div>
    <div class="container" *ngIf="!allConfirmatonData.BookingXML.ReservationRef">
        <div class="hb-confrm-inner">
            <div class="bkg-txt">
                <i class="fa fa-times-circle" style="font-size:48px;color:red"></i>
                <h3 style="padding: 1.5%;">Booking Failed</h3>
            </div>
        </div>
    </div>
</section>


<div id="emailModal" class="modal fade" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <button type="button" class="close confirmation-Close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
      <div class="modal-body">
        <div class="lr-box">
          <h3 class="border_btn_gray">Send voucher / Ticket</h3>
          <ul>
            <li>Hotel voucher with booking reference no {{allConfirmatonData.BookingXML.ReservationRef}} will be send to <b>{{allConfirmatonData.BookingXML.Client.Email}}</b></li>
            <!--<li>Do you want to send this voucher / ticket to some one?</li>
    <li><input type="text" class="form-control" placeholder="Email Id" id="emailId" name="emailId" value="" #emailId></li>-->
          </ul>
          <div *ngIf="isSendingMail" class="align_centre">
            <div class="four-img">
              <img src="assets/images/what-processing.gif" class="process-img" />
            </div>
            <div class="four-page-not orange-clr">
              <span>Mail Sending In Progress....</span>
            </div>
            
          </div>

          <button type="button" class="btn btn-sub btn-cancel" (click)="EmailVoucher(allConfirmatonData.BookingXML.ReservationRef)">
            Send
          </button>
        </div>
      </div>
    </div>

  </div>
</div>

<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
          <button type="button" class="close confirmation-Close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
            <div class="modal-body">
                <div *ngIf="showVoucher" [innerHTML]="htmlStr"></div>
                <div *ngIf="!showVoucher">Loading...</div>
            </div>
            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
            </div> -->
        </div>

    </div>
</div>
<div id="Invoicepopup" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
      <div class="modal-content">
        <button type="button" class="close confirmation-Close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
        <div class="modal-body">
          <div *ngIf="showInvoice" [innerHTML]="htmlStrInvoice"></div>
          <div *ngIf="!showInvoice">Loading...</div>
        </div>
        <!--<div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>-->
      </div>

    </div>
</div>
