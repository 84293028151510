import { Passenger } from './PassengerModel';

export class BookingModel{
Rooms:Rooms;
}

export class Rooms{
Room:Room[];
}
export class Room{
Adult:Passenger[];
Child:Passenger[];
}
