<div class="container" id="hotel-list">
  <div class="row">
    <div class="col-md-3">
      <div class="side-box">
        <div class="cats-title bar"> </div>
        <div class="form-area bar">

        </div>
        <div class="side-spc"></div>
        <div class="cats-title bar"> </div>
        <div class="slidecontainer bar">

        </div>
      </div>
      <div class="side-box">
        <div class="cats-title bar"> </div>
        <div class="form-area bar">

        </div>
        <div class="side-spc"></div>
        <div class="cats-title bar"> </div>
        <div class="slidecontainer bar">

        </div>
      </div>
    </div>
    <div class="col-md-9">
      <div class="row row-no-gutter row-eq-height row-radius">
        <div class="col-md-3 hotel-thumb ">
          <div class="bar" style="min-height: 246px"></div>
        </div>
        <div class="col-md-9">
          <div class="search-result-body">
            <div class="row row-eq-height">
              <div class="col-md-9 block-space">
                <span>
                  <h5 class="item-title item-title-lg">
                    <div class="bar" style="width: 100%"></div>
                  </h5>
                  <ul class="item-hotel-stars">

                    <li>
                      <div class="bar" style="width: 100%"></div>
                    </li>

                  </ul>
                </span>
                <p class="item-location">
                  <span class="bar" style="width: 60%"></span>
                </p>
                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 50%"></span>
                  </p>
                </div>

                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 30%"></span>
                  </p>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="col-md-3 block-space text-right border-left-dotted">

                <div class="save-deal"><span></span></div>
                <div class="save-price"><span></span></div>
                <div class="item-book">
                  <div class="item-price">
                    <p class="item-price-tag"> <span class="bar" style="width: 80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 70%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width:80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 50%"></span></p>
                  </div>
                  <span class="bar wh40x107" style="margin-top:46% "></span>

                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="row row-no-gutter row-eq-height row-radius">
        <div class="col-md-3 hotel-thumb ">
          <div class="bar" style="min-height: 246px"></div>
        </div>
        <div class="col-md-9">
          <div class="search-result-body">
            <div class="row row-eq-height">
              <div class="col-md-9 block-space">
                <span>
                  <h5 class="item-title item-title-lg">
                    <div class="bar" style="width: 100%"></div>
                  </h5>
                  <ul class="item-hotel-stars">

                    <li>
                      <div class="bar" style="width: 100%"></div>
                    </li>

                  </ul>
                </span>
                <p class="item-location">
                  <span class="bar" style="width: 60%"></span>
                </p>
                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 50%"></span>
                  </p>
                </div>

                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 30%"></span>
                  </p>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="col-md-3 block-space text-right border-left-dotted">

                <div class="save-deal"><span></span></div>
                <div class="save-price"><span></span></div>
                <div class="item-book">
                  <div class="item-price">
                    <p class="item-price-tag"> <span class="bar" style="width: 80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 70%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width:80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 50%"></span></p>
                  </div>
                  <span class="bar wh40x107" style="margin-top:46% "></span>

                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="row row-no-gutter row-eq-height row-radius">
        <div class="col-md-3 hotel-thumb ">
          <div class="bar" style="min-height: 246px"></div>
        </div>
        <div class="col-md-9">
          <div class="search-result-body">
            <div class="row row-eq-height">
              <div class="col-md-9 block-space">
                <span>
                  <h5 class="item-title item-title-lg">
                    <div class="bar" style="width: 100%"></div>
                  </h5>
                  <ul class="item-hotel-stars">

                    <li>
                      <div class="bar" style="width: 100%"></div>
                    </li>

                  </ul>
                </span>
                <p class="item-location">
                  <span class="bar" style="width: 60%"></span>
                </p>
                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 50%"></span>
                  </p>
                </div>

                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 30%"></span>
                  </p>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="col-md-3 block-space text-right border-left-dotted">

                <div class="save-deal"><span></span></div>
                <div class="save-price"><span></span></div>
                <div class="item-book">
                  <div class="item-price">
                    <p class="item-price-tag"> <span class="bar" style="width: 80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 70%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width:80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 50%"></span></p>
                  </div>
                  <span class="bar wh40x107" style="margin-top:46% "></span>

                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
      <div class="row row-no-gutter row-eq-height row-radius">
        <div class="col-md-3 hotel-thumb ">
          <div class="bar" style="min-height: 246px"></div>
        </div>
        <div class="col-md-9">
          <div class="search-result-body">
            <div class="row row-eq-height">
              <div class="col-md-9 block-space">
                <span>
                  <h5 class="item-title item-title-lg">
                    <div class="bar" style="width: 100%"></div>
                  </h5>
                  <ul class="item-hotel-stars">

                    <li>
                      <div class="bar" style="width: 100%"></div>
                    </li>

                  </ul>
                </span>
                <p class="item-location">
                  <span class="bar" style="width: 60%"></span>
                </p>
                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 50%"></span>
                  </p>
                </div>

                <div class="item-hotel-rating">
                  <p class="item-hotel-rating-title">
                    <span class="bar" style="width: 30%"></span>
                  </p>
                </div>
                <div class="clearfix"></div>
              </div>
              <div class="col-md-3 block-space text-right border-left-dotted">

                <div class="save-deal"><span></span></div>
                <div class="save-price"><span></span></div>
                <div class="item-book">
                  <div class="item-price">
                    <p class="item-price-tag"> <span class="bar" style="width: 80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 70%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width:80%"></span></p>
                    <p class="item-price-sign"> <span class="bar" style="width: 50%"></span></p>
                  </div>
                  <span class="bar wh40x107" style="margin-top:46% "></span>

                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</div>