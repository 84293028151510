import { InvoiceComponent } from './../invoice/invoice.component';
import { HotelConfirmationComponent } from './../hotel-confirmation/hotel-confirmation.component';
import { HotelPassengerComponent } from './../hotel-passenger/hotel-passenger.component';
import { HotelDetailComponent } from './../hotel-detail/hotel-detail.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HotelComponent } from './hotel.component';
import { HotelResultComponent } from '../hotel-result/hotel-result.component';
import { VoucherComponent } from '../voucher/voucher.component';

import { HotelPaymentConfirmationComponent } from '../hotel-payment-confirmation/hotel-payment-confirmation.component';
import { PaymentDeclinedComponent } from '../payment-declined/payment-declined.component';


const routes: Routes =
 [
    { path: '', component: HotelComponent },
    {path:'hotel-result',component:HotelResultComponent},
    {path:'hotel-detail',component:HotelDetailComponent},
    {path:'hotel-book',component:HotelPassengerComponent},
    {path:'hotel-confirmation',component:HotelConfirmationComponent},
    {path:'hotel-voucher/:bookingID',component:VoucherComponent},
    {path:'hotel-invoice/:bookingID',component:InvoiceComponent},
    {path:'hotel-payment',component:HotelPaymentConfirmationComponent},
    {path:'payment-declined',component:PaymentDeclinedComponent}
 ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HotelRoutingModule { }
