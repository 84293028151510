import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './aboutus/aboutus.component';
import { CancellationPolicyComponent } from './cancellation-policy/cancellation-policy.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FAQComponent } from './faq/faq.component';
import { MyBookingsComponent } from './my-bookings/my-bookings.component';
import { PricingComponent } from './pricing/pricing.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermAndConditionComponent } from './term-and-condition/term-and-condition.component';
import { ResetPassowordComponent } from './common/resetPassoword.component';


const routes: Routes = [
  //{ path: '', loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule) },
  { path: '', loadChildren: () => import('./flight/flight.module').then(m => m.FlightModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'flight', loadChildren: () => import('./flight/flight.module').then(m => m.FlightModule) },
  { path: 'hotel', loadChildren: () => import('./hotel/hotel.module').then(m => m.HotelModule) },
  { path: 'holiday', loadChildren: () => import('./holiday/holiday.module').then(m => m.HolidayModule) },
  { path: 'aboutus', component: AboutusComponent },
  { path: 'Faq', component: FAQComponent },
  { path: 'Privacy-Policy', component: PrivacyPolicyComponent },
  { path: 'Termandcondition', component: TermAndConditionComponent },
  { path: 'Cancellation-policy', component: CancellationPolicyComponent },
  { path: 'Contactus', component: ContactUsComponent },
  { path: 'Common/ResetPassword', component: ResetPassowordComponent },
  { path: 'Pricing', component: PricingComponent },
  { path: 'transfer', loadChildren: () => import('./transfer/transfer.module').then(m => m.TransferModule) },
  { path: 'sightseeing', loadChildren: () => import('./sightseeing/sightseeing.module').then(m => m.SightseeingModule) },
  { path: '*', loadChildren: () => import('./flight/flight.module').then(m => m.FlightModule) },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
