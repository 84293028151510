import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingService {

  constructor() { }
  public static readonly CompanyCode = 'SMT';
  public static readonly Channel = 'B2C';
  public static readonly Currency = 'INR';
  public static readonly CurrencySymbols = '₹';
  public static readonly Domain=window.location.origin;
  public static readonly AuthKey="2540D49A-FCFE-4422-9C4B-FBF2DA50B0BE";
  public static readonly IpAddress="10.0.50.1";
}
