import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedServiceService {
  currencySymbol:string='₹';
  currencyConversionRate:number=1;
  selectedCurrency:string='INR';
  isShownCurrency: boolean = true;
  updateGlobalVar(currencySymbol:string,currencyConversionRate:number,selectedCurrency:string) {
    this.currencySymbol = currencySymbol;
    this.currencyConversionRate = currencyConversionRate;
    this.selectedCurrency=selectedCurrency;
  }
}
