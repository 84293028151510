import { HotelRoom, HotelSearchModel, Request } from './../Model/HotelSearchModel';
import { HotelService } from './../Service/hotel.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Options } from 'ng5-slider';
import * as _ from "underscore";
import { GetRoomModel, GetRoomFilter, GetRoomRequest, GetRoomMaster, GetRoomAuthentication, GetRoomRequestDetails, GetRoomSubAgent, GetRoomGetRoom, GetRoomDetails, GetRoomHotelRoom } from '../Model/GetHotelModel';
import { NavigationService } from '../Service/navigation.service';
import { CompanySettingService } from '../Service/company-setting.service';
import { SharedServiceService } from '../Service/shared-service.service';

@Component({
  selector: 'app-hotel-result',
  templateUrl: './hotel-result.component.html',
  styleUrls: ['./hotel-result.component.css']
})



export class HotelResultComponent implements OnInit {
  showMoreCount: number = 50;
  loader: boolean;
  hotelResulList: Observable<any>;
  hotelNameList: Observable<any>;
  DestinationCity: string = "";
  selectedDestination: any;
  GuestCount: number;
  HotelSearchModel: HotelSearchModel = new HotelSearchModel();
  IsProdution:boolean=environment.production;
  ResultData: any;
  AllHotels: any[];
  FilteredHotels: any[];
  HotelCount: number = 0;
  showResult: boolean;
  IsRecommendedHotel: boolean;
  IsFreeCancellation: boolean;
  isPriceLowToHigh: boolean = true;
  isPriceHighToLow: boolean;
  isStarLowToHigh: boolean;
  isStarHighToLow: boolean;
  hotelNameArray: any[];

  HotelName: string;
  HotelFilterModel = { Price: [], Ratings: [], HotelName: null, PropertyType: [], Inclusions: [] };
  priceOptions: Options = {};
  AppliedFilter: any = [];
  minMaxValues = { priceMinValue: null, priceMaxValue: null };
  isHotelName: boolean;

  getHotelRoomModel: GetRoomModel;
  fromCityValid: boolean = true;
  hotelSuppliers: any[];
  minDate: Date;
  fromMax: Date;
  minCheckoutDate: Date;
  noResultFound: boolean;
  hitcount: number = 0;
  @ViewChild("searchButton", { static: false }) searchButton: ElementRef;
  CompanySetting = CompanySettingService;
  constructor(public _hotelService: HotelService, private route: Router
    , private _navigation: NavigationService,private activatedRoute:ActivatedRoute,public sharedServiceService:SharedServiceService) {

    this.sharedServiceService.isShownCurrency=true;
    this.minDate = new Date();
    const todayDate = new Date();
    this.fromMax = new Date(todayDate.setFullYear(todayDate.getFullYear() + 1));

    this._navigation.ChangeSelection('hotel');
    
    
    this.hotelResulList = Observable.create((observer: Observer<any[]>) => {
      this._hotelService.GetCCity(this.DestinationCity).subscribe((responseItems: any[]) => {
        var data = responseItems as any;
        observer.next(data.CityResponse.Destinations.DestinationItems);
      });
    });
    
  }
  async GetHotelsSupplier(){
   await this._hotelService.GetHotelsSuppliers().subscribe(data => {
      var allData = data as any;
      sessionStorage.setItem("hotelSuppliers", JSON.stringify(allData.ActiveSuppliers));
      this.hotelSuppliers = allData.ActiveSuppliers;
      this.GetHotels();
    });
  }
  ngOnInit() {

    localStorage.removeItem("hotelRequest");
    this.HotelSearchModel = new HotelSearchModel();
    this.activatedRoute.queryParams.subscribe(params => {
      let checkin = params.checkin;
      let checkout = params.checkout;
      let pax = params.pax;
      let type = params.type;
      let cityCode = params.cityCode;
      let cityName = params.cityName;
      let cCode = params.cCode;
      let cName = params.cName;
      let searchText = params.searchText;
      let hotelName = params.hotelName;
      if (checkin != '' && checkin != undefined)
        this.HotelSearchModel.Request.Master.CheckIn = checkin;
      else {
        this.HotelSearchModel.Request.Master.CheckIn = this.SetDateFormat((new Date().setDate(new Date().getDate() + 1)));
      }

      if (checkout != '' && checkout != undefined)
        this.HotelSearchModel.Request.Master.CheckOut = checkout;
      else {
        this.HotelSearchModel.Request.Master.CheckOut = this.SetDateFormat((new Date().setDate(new Date().getDate() + 3)));
      }

      if (cityCode != '' && cityCode != undefined)
        this.HotelSearchModel.Request.Master.CityCode = cityCode;

      if (cityName != '' && cityName != undefined)
        this.HotelSearchModel.Request.Master.CityName = cityName;

      if (cCode != '' && cCode != undefined)
        this.HotelSearchModel.Request.Master.CountryCode = cCode;

      if (cName != '' && cName != undefined)
        this.HotelSearchModel.Request.Master.CountryName = cName;

      if (type != '' && type != undefined) {
        this.HotelSearchModel.Request.RequestDetails.Filter.Type = type;
        if (type == 'Hotel') {
          if (hotelName != '' && hotelName != undefined)
            this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = hotelName;
            else
            this.HotelSearchModel.Request.RequestDetails.Filter.Type='City';
        }else{
          this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = "";
        }
      }      

      if (searchText != '' && searchText != undefined) {
        this.HotelSearchModel.Request.Master.DisplayCountryCityName = searchText;
        if(hotelName != '' && hotelName != undefined)
          this.DestinationCity = hotelName+', '+searchText;
        else
          this.DestinationCity = searchText;
      }

      if (pax != '' && pax != undefined) {
        //2$2^4,5|2$0
        let roomsc = pax.split('|');
        for (let i = 0; i < roomsc.length; i++) {
          var room = new HotelRoom();
          var temppax = roomsc[i];
          room.Adult = Number(temppax.split('$')[0]);
          room.Child = Number(temppax.split('$')[1].split('^')[0]);
          if (room.Child > 0) {
            room.ChildAge = temppax.split('$')[1].split('^')[1].split(',');
            room.ChildCountArray = temppax.split('$')[1].split('^')[1].split(',');
          }
          this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
        }

      } else {
        var room = new HotelRoom();
        room.Adult = 2;
        room.Child = 0;
        room.ChildAge = [];

        this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
      }
      this.initializeFilter();
      this.GetTotalGuest();
      var hotelsuppliers = sessionStorage.getItem("hotelSuppliers");
    if (hotelsuppliers != null || hotelsuppliers != undefined) {
      this.hotelSuppliers = JSON.parse(hotelsuppliers);
      this.GetHotels();
    } else {
      this.GetHotelsSupplier();
    }
    });
  }
  SetDateFormat(date: any) {
    var _dt = new Date(date);
    var _CheckIn = _dt.getFullYear() + "-" + (_dt.getMonth() + 1 < 10 ? "0" + (_dt.getMonth() + 1) : (_dt.getMonth() + 1)) + "-" + (_dt.getDate() < 10 ? "0" + _dt.getDate() : _dt.getDate());
    return _CheckIn;
  }
  formatHotelCity(result: any) {
    this.DestinationCity = result.item.DisplayName;
    this.selectedDestination = result.item;
    this.HotelSearchModel.Request.Master.CityName = this.selectedDestination.CityName;
    this.HotelSearchModel.Request.Master.CountryCode = this.selectedDestination.CountryCode;
    this.HotelSearchModel.Request.Master.CityCode = this.selectedDestination.CityCode;
    this.HotelSearchModel.Request.Master.CountryName = this.selectedDestination.CountryName;
    this.HotelSearchModel.Request.Master.DisplayCountryCityName = this.selectedDestination.DisplayCountryCityName;
    this.HotelSearchModel.Request.RequestDetails.Filter.Type = this.selectedDestination.Type;
    if (this.HotelSearchModel.Request.RequestDetails.Filter.Type == "Hotel")
      this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = this.selectedDestination.DisplayName.substring(0, this.selectedDestination.DisplayName.indexOf(','));
    else
      this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = "";
    this.fromCityValid = true;
  }

  initializeFilter() {
    for (var i = 0; i < 5; i++) {
      this.HotelFilterModel.Ratings.push({ name: i, value: false, count: 0 });
    }
    this.HotelFilterModel.PropertyType.push({ name: "Cottage", value: false });
    this.HotelFilterModel.PropertyType.push({ name: "Resort", value: false });
    this.HotelFilterModel.PropertyType.push({ name: "Homestay", value: false });
    this.HotelFilterModel.PropertyType.push({ name: "Villa", value: false });
    this.HotelFilterModel.PropertyType.push({ name: "Guesthouse", value: false });

    // this.HotelFilterModel.BoardBasis.push({ name: "Free Breakfast", value: false });

  }

 GetHotels() {
  
    this.noResultFound = false;
    this.hitcount = 0;
    for (var i = 0; i < this.hotelSuppliers.length; i++) {
      // if (i == 0)
      //   this.HotelSearchModel.Request.RequestDetails.Filter.Suppliers = 3;
      //   if (i == 1)
      //   this.HotelSearchModel.Request.RequestDetails.Filter.Suppliers = 8;
      this.HotelSearchModel.Request.RequestDetails.Filter.Suppliers = Number(this.hotelSuppliers[i].Provider);
      this.HotelSearchModel.Request.RequestDetails.Filter.Provider = this.hotelSuppliers[i].Provider;
      this._hotelService.GetHotels(this.HotelSearchModel).subscribe(data => {
        this.hitcount++;
        var allData = data as any;
        if (allData.Response.ResponseDetails.Error == null) {
          this.ResultData = allData;
          if (this.AllHotels == undefined)
            this.AllHotels = new Array<any>();
          allData.Response.ResponseDetails.Search.Hotels.Hotel.forEach(hotel => {
            this.AllHotels.push(hotel);
          });
         console.log(this.AllHotels);
          this.CalculateResult();
          this.HotelCount += allData.Response.ResponseDetails.Search.Hotels.Total;
          this.showResult = true;

          var Min = _.min(this.AllHotels, function (item) {
            return Math.round(item.MinPrice);
          });
          var Max = _.max(this.AllHotels, function (item) {
            return Math.round(item.MinPrice);
          });

          this.minMaxValues.priceMaxValue = Max.MinPrice;
          this.minMaxValues.priceMinValue = Min.MinPrice;
          this.priceOptions = {
            floor: Min.MinPrice,
            ceil: Max.MinPrice,
            step: 1,
            animate: true
          };

          this.ApplyFilter();

        }
        if (this.hitcount == this.hotelSuppliers.length && this.HotelCount <= 0) {
          this.noResultFound = true;
        }
      }, err => {
        this.hitcount++;
        if (this.hitcount == this.hotelSuppliers.length && this.HotelCount <= 0) {
          this.noResultFound = true;
        }
      });
    }
  }
  HotelFromDateChange(date) {
    if (date != undefined && !isNaN(date.getTime())) {
      var tempdate = new Date(date);
      tempdate.setHours(0, 0, 0, 0);
      var tempcheckd = new Date(this.HotelSearchModel.Request.Master.CheckOut);
      tempcheckd.setHours(0, 0, 0, 0);

      if (tempdate >= tempcheckd) {
        let fromDate = new Date(date);
        let tempcheckoutDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
        this.minCheckoutDate = tempcheckoutDate;
        this.HotelSearchModel.Request.Master.CheckOut = tempcheckoutDate.toString();

      } else {
        let fromDate = new Date(date);
        let tempcheckoutDate = new Date(fromDate.setDate(fromDate.getDate() + 1));
        this.minCheckoutDate = tempcheckoutDate;
      }
    }
  }

  GetTotalGuest() {
    var guestCount = 0;
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.forEach(room => {
      guestCount += room.Adult + room.Child;
    });
    this.GuestCount = guestCount;
  }

  AddRoom() {
    var room = new HotelRoom();
    room.Adult = 2;
    room.Child = 0;
    room.ChildAge = [];
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.push(room);
    this.GetTotalGuest();
  }

  RemoveRoom() {
    this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.pop();
    this.GetTotalGuest();
  }

  IncreaseGuest(type, i) {
    if (type == 'A') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult + 1;
    }
    if (type == 'C') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child + 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child - 1] = 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray = Array(this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child).fill(0).map((x, i) => i);
    }
    this.GetTotalGuest();

  }

  DecreaseGuest(type, i) {
    if (type == 'A') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Adult - 1;
    }
    if (type == 'C') {
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child - 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge.pop();
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildAge[this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child - 1] = 1;
      this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].ChildCountArray = Array(this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i].Child).fill(0).map((x, i) => i);
    }
    this.GetTotalGuest();

  }

  ApplyFilter() {
    if (this.AllHotels.length > 0) {
      this.SelectedFilters();
      this.PrepareResultData();
    }
  }

  SelectedFilters() {
    this.AppliedFilter = [];
    if (this.HotelFilterModel.Ratings[0].value) {
      this.AppliedFilter.push({ name: "1 Star", filter: "rating", value: 0 });
    }
    if (this.HotelFilterModel.Ratings[1].value) {
      this.AppliedFilter.push({ name: "2 Stars", filter: "rating", value: 1 });
    }
    if (this.HotelFilterModel.Ratings[2].value) {
      this.AppliedFilter.push({ name: "3 Stars", filter: "rating", value: 2 });
    }
    if (this.HotelFilterModel.Ratings[3].value) {
      this.AppliedFilter.push({ name: "4 Stars", filter: "rating", value: 3 });
    }
    if (this.HotelFilterModel.Ratings[4].value) {
      this.AppliedFilter.push({ name: "5 Stars", filter: "rating", value: 4 });
    }

    const InclusionsList = this.HotelFilterModel.Inclusions.filter(data => {
      return data.value;
    });
    for (var i = 0; i < InclusionsList.length; i++) {
      this.AppliedFilter.push({ name: InclusionsList[i].name, filter: "Inclusions", value: InclusionsList[i].name });
    }
    const propertyTypeList = this.HotelFilterModel.PropertyType.filter(data => {
      return data.value;
    });
    for (var i = 0; i < propertyTypeList.length; i++) {
      this.AppliedFilter.push({ name: propertyTypeList[i].name, filter: "property", value: propertyTypeList[i].name });
    }


    if (this.priceOptions.floor < this.minMaxValues.priceMinValue || this.priceOptions.ceil > this.minMaxValues.priceMaxValue) {
      this.AppliedFilter.push({ name: 'AED ' + this.minMaxValues.priceMinValue + ' - AED ' + this.minMaxValues.priceMaxValue, filter: "price", value: 2 });
    }

  }
  getHotelSearchSourceAndDestination(){
  if(this.ResultData)
    return  this.ResultData.Response.Master.DisplayCountryCityName.split(',')[0] +":" +this.ResultData.Response.ResponseDetails.Search.Hotels.Total + " Hotels found";
  return 0;
  }
  PrepareResultData() {
    var PropertyTypes = JSON.parse(JSON.stringify(this.HotelFilterModel.PropertyType));
    var Inclusions = JSON.parse(JSON.stringify(this.HotelFilterModel.Inclusions));
    PropertyTypes = _.filter(PropertyTypes, function (item) {
      return item.value != false;
    });

    Inclusions = _.filter(Inclusions, function (item) {
      return item.value != false;
    });

    var RatingJson = JSON.stringify(this.HotelFilterModel.Ratings);
    var Ratings = JSON.parse(RatingJson);
    var removertings = [];
    for (var i = 0; i < 5; i++) {
      if (Ratings[i].value) {
        Ratings[i] = i + 1;
      }
      else {
        Ratings[i] = "";
      }
      var stop = parseInt(Ratings[i]);
      if (!isNaN(stop))
        removertings.push(Ratings[i]);
    }
    Ratings = removertings;
    this.HotelFilterModel.Price = [this.minMaxValues.priceMinValue, this.minMaxValues.priceMaxValue, 1];
    var filterModel = JSON.parse(JSON.stringify(this.HotelFilterModel));
    filterModel.PropertyType = PropertyTypes;

    filterModel.Ratings = Ratings;
    filterModel.Inclusions = Inclusions;

    this.ApplyAllFilter(filterModel);
  }
  ApplyAllFilter(filter) {
    var ServerData = this.AllHotels;
    this.hotelNameArray = [];
    this.FilteredHotels = ServerData.filter(data => {
      return this.isShowHotel(data, filter);
    });
    this.FilteredHotels = this.SortByPrice(this.FilteredHotels);
    this.FilteredHotels = this.SortByStar(this.FilteredHotels);
    
    this.hotelNameList = this.hotelNameArray as any;
    this.HotelCount=this.FilteredHotels.length;

  }

  isShowHotel(item, filter) {
    this.hotelNameArray.push(item.Name);
    var IsPrice = true;
    var IsHotel = true;
    var IsRating = true;
    var IsPropertyType = true;
    var IsInclusions = true;
    var IsHotelNameTyping = true;
    var IsRecommended = true;
    var IsFreeCancellations = true;

    if (filter.Price.length > 0) {
      var hotelPrice = item.MinPrice;
      if (((parseFloat(hotelPrice) * parseFloat(filter.Price[2])) >= filter.Price[0]) && ((parseFloat(hotelPrice) * parseFloat(filter.Price[2])) <= filter.Price[1])) {
        IsPrice = true;
      }
      else {
        IsPrice = false;
      }
    }

    if (this.HotelName != null && this.HotelName.length > 0) {
      IsHotel = item.Name.toLowerCase().startsWith(this.HotelName.toLowerCase());
      this.isHotelName = true;
    }

    // if (filter.HotelNameTyping != null && filter.HotelNameTyping.length > 0) {
    //   IsHotelNameTyping = item.hotelName.toLowerCase().includes(filter.HotelNameTyping.toLowerCase());
    // }

    if (filter.Ratings != null && filter.Ratings.length > 0) {
      var isFind = _.find(filter.Ratings, function (item1) {
        return parseInt(item1) == parseInt(item.Star);
      });
      IsRating = isFind != undefined && isFind != null;
    }

    if (filter.PropertyType != null && filter.PropertyType.length > 0) {
      var isFind = _.find(filter.PropertyType, function (item1) {
        return item1.name.toLowerCase() == item.propertyType.categoryDescription.toLowerCase();
      });
      IsPropertyType = isFind != undefined && isFind != null;
    }

    IsInclusions = this.ApplyInclusionsFilter(item, filter);
    if (this.IsRecommendedHotel) {
      IsRecommended = item.Offer == "Y" ? true : false;
    }
    if (this.IsFreeCancellation) {
      IsFreeCancellations = item.Refundablenfo != "" && item.Refundablenfo != null && item.Refundablenfo != undefined ? true : false;
    }

    return IsPrice && IsHotel && IsHotelNameTyping && IsRating && IsPropertyType && IsInclusions && IsRecommended && IsFreeCancellations;
  }


  ApplyInclusionsFilter(item, filter) {
    if (filter.Inclusions.some(x => x.value == true)) {
      var Inclusions = _.filter(filter.Inclusions, function (item) {
        return item.value == true;
      });
      var isShow = false;
      for (var ami = 0; ami < Inclusions.length; ami++) {
        if (item.Inclusions != null && item.Inclusions.length > 0)
          isShow = item.Inclusions.some(code => filter.Inclusions[ami].name == code);
      }
      return isShow;
    } else {
      return true;
    }

  }

  SortByPrice(data) {
    if (this.isPriceHighToLow || this.isPriceLowToHigh) {
      var sortedPrice = _.sortBy(data, function (item) {
        var hotelPrice = item.MinPrice;
        return hotelPrice;
      });
      if (this.isPriceHighToLow) {
        sortedPrice = sortedPrice.reverse();
      }
      return sortedPrice;
    }
    else
      return data;
  }
  SortByStar(data) {
    if (this.isStarHighToLow || this.isStarLowToHigh) {
      var sortedStar = _.sortBy(data, function (item) {
        var hotelStar = item.Star;
        return hotelStar;
      });
      if (this.isStarHighToLow) {
        sortedStar = sortedStar.reverse();
      }
      return sortedStar;
    }
    else
      return data;
  }

  PriceClick(type) {

    if (type == "H") {
      this.isPriceHighToLow = true;
      this.isPriceLowToHigh = false;
    }
    else {
      this.isPriceLowToHigh = true;
      this.isPriceHighToLow = false;
    }

    this.ApplyFilter();
  }
  StarClick(type) {

    if (type == "H") {
      this.isStarHighToLow = true;
      this.isStarLowToHigh = false;
    }
    else {
      this.isStarLowToHigh = true;
      this.isStarHighToLow = false;
    }

    this.ApplyFilter();
  }

  PriceChange() {
    this.ApplyFilter();
  }
  StarChange(index) {
    this.HotelFilterModel.Ratings[index].value = !this.HotelFilterModel.Ratings[index].value;
    this.ApplyFilter();
  }

  CalculateResult() {
    var context = this;
    for (var i = 0; i < this.HotelFilterModel.Ratings.length; i++) {
      var count = _.countBy(this.AllHotels, function (item) {
        return item.Star == context.HotelFilterModel.Ratings[i].name + 1;
      });
      if (count.true)
        this.HotelFilterModel.Ratings[i].count = count.true;
      else
        this.HotelFilterModel.Ratings[i].count = 0;

    }

    for (var i = 0; i < this.AllHotels.length; i++) {
      var data = [];
      if (this.AllHotels[i].Inclusions != null && this.AllHotels[i].Inclusions.length > 0)
        data = this.AllHotels[i].Inclusions.map(item => item).filter((value, index, self) => self.indexOf(value) === index);

      for (var j = 0; j < data.length; j++) {
        if (!this.HotelFilterModel.Inclusions.some(code => data[j] == code.name))
          this.HotelFilterModel.Inclusions.push({ name: data[j], value: false });
      }
    }
  }


  ModifyResult(vaild) {
    if (this.HotelSearchModel.Request.Master.CityName == undefined || this.HotelSearchModel.Request.Master.CityName == null) {
      this.fromCityValid = false;
      return false;
    }
    if (vaild) {
      var _dt = new Date(this.HotelSearchModel.Request.Master.CheckIn);
      var _dt1 = new Date(this.HotelSearchModel.Request.Master.CheckOut);

      var _CheckIn = _dt.getFullYear() + "-" + ((_dt.getMonth() + 1) < 10 ? "0" + (_dt.getMonth() + 1) : (_dt.getMonth() + 1)) + "-" + (_dt.getDate() < 10 ? "0" + _dt.getDate() : _dt.getDate());
      var _CheckOut = _dt1.getFullYear() + "-" + ((_dt1.getMonth() + 1) < 10 ? "0" + (_dt1.getMonth() + 1) : (_dt1.getMonth() + 1)) + "-" + (_dt1.getDate() < 10 ? "0" + _dt1.getDate() : _dt1.getDate());
      this.HotelSearchModel.Request.Master.CheckIn = _CheckIn;
      this.HotelSearchModel.Request.Master.CheckOut = _CheckOut;

      this.HotelSearchModel.Request.RequestDetails.Filter.HotelId = "";
      this.HotelSearchModel.Request.RequestDetails.Filter.HotelName = "";
      this.redirectTo('/hotel-result');
  }
}
redirectTo(uri:string) {
  this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    let checkIn = this.HotelSearchModel.Request.Master.CheckIn;
    let checkOut = this.HotelSearchModel.Request.Master.CheckOut;
    let pax = '2$0';//2$2^4,5|2$0
    for (let i = 0; i < this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room.length; i++) {
      let room = this.HotelSearchModel.Request.RequestDetails.Search.Rooms.Room[i];
      if (i == 0)
        pax = '';
      else
        pax = pax + '|';

      pax = pax + room.Adult + '$' + (room.Child == 0 ? '0' : room.Child + '^' + room.ChildAge.join(','));
    }
    let type = this.HotelSearchModel.Request.RequestDetails.Filter.Type;
    let hotelName = '';
    if (type == "Hotel")
      hotelName = this.HotelSearchModel.Request.RequestDetails.Filter.HotelName;

    this.route.navigate([uri], { queryParams: { checkin: checkIn, checkout: checkOut, pax: pax, type: type, cityCode: this.HotelSearchModel.Request.Master.CityCode, cityName: this.HotelSearchModel.Request.Master.CityName, cCode: this.HotelSearchModel.Request.Master.CountryCode, cName: this.HotelSearchModel.Request.Master.CountryName, searchText: this.HotelSearchModel.Request.Master.CityName + ', ' + this.HotelSearchModel.Request.Master.CountryName, hotelName: hotelName } });

  });
}
  myFormSubmitHandler() {
    let el: HTMLElement = this.searchButton.nativeElement;
    el.click();
  }
  formatHotelName(result: any) {
    this.HotelName = result.item;
    this.ApplyFilter();
  }

  ClearHotel() {
    this.HotelName = null;
    this.isHotelName = false;
    this.ApplyFilter();
  }

  GetHotelRoom(HotelID: any, Token: any, Provider: any) {
    this.loader = true;
    this.getHotelRoomModel = new GetRoomModel();
    this.getHotelRoomModel.Request = new GetRoomRequest();
    this.getHotelRoomModel.Request.Master = new GetRoomMaster();
    this.getHotelRoomModel.Request.Authentication = new GetRoomAuthentication();
    this.getHotelRoomModel.Request.RequestDetails = new GetRoomRequestDetails();
    this.getHotelRoomModel.Request.Authentication.SubAgent = new GetRoomSubAgent();
    this.getHotelRoomModel.Request.RequestDetails.GetRoom = new GetRoomGetRoom();
    this.getHotelRoomModel.Request.RequestDetails.GetRoom.Rooms = new GetRoomDetails();
    this.getHotelRoomModel.Request.RequestDetails.GetRoom.Rooms.Room = new Array<GetRoomHotelRoom>();

    this.getHotelRoomModel.Request.Master = this.ResultData.Response.Master;
    this.getHotelRoomModel.Request.Authentication = this.ResultData.Response.Authentication;
    this.getHotelRoomModel.Request.Authentication.SubAgent = this.ResultData.Response.Authentication.SubAgent;
    this.getHotelRoomModel.Request.RequestDetails.GetRoom = this.HotelSearchModel.Request.RequestDetails.Search;
    this.getHotelRoomModel.Request.RequestDetails.Filter = new GetRoomFilter();
    this.getHotelRoomModel.Request.RequestDetails.Filter.HotelId = HotelID;
    this.getHotelRoomModel.Request.RequestDetails.Filter.Token = Token;
    this.getHotelRoomModel.Request.Master.SessionId = this.ResultData.Response.Master.SessionId;
    this.getHotelRoomModel.Request.RequestDetails.Filter.Provider = Provider;

    sessionStorage.setItem("getRoomRquest", JSON.stringify(this.getHotelRoomModel));
    sessionStorage.setItem("currencySymbals", JSON.stringify(this.sharedServiceService.currencySymbol));
    sessionStorage.setItem("currencyConversionRate", JSON.stringify(this.sharedServiceService.currencyConversionRate));
    sessionStorage.setItem("selectedCurrency", JSON.stringify(this.sharedServiceService.selectedCurrency));
    //this.route.navigate(['/hotel-detail']);
    window.open("/hotel-detail", '_blank');
    this.loader = false;

  }

  initilizeArray(i: number) {
    return new Array(i);
  }

  
}
