import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CompanySettingService } from './company-setting.service';
import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  BaseURL = '';
  RecommendedInfoURL = 'api/RecommendedInfo';
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  baseURLGenric = '';
  UtilityBaseURL='';
  constructor(private _http: HttpClient) {
    this.BaseURL = environment.UtilityBaseURL;
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('token')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseURLGenric = environment.GenericBaseURL;
  }

  private header = new HttpHeaders({
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
    
   
  });
  GetRecommendedInfo() {
    return this._http.get(this.BaseURL + this.RecommendedInfoURL + "?CompanyCode="+CompanySettingService.CompanyCode+"&PageName=HOME&ValueType=PC,PH,PR");
  }
  GetEmailByToken(token) {
    
    return this._http.post(this.baseURLGenric + "api/User/GetEmailByToken", token, { headers: this.header });
  
    //return this._http.get( url);//+"?CompanyCode="+CompanySettingService.CompanyCode+"&PageName=HOME&ValueType=PC,PH,PR");
  }
  RestPassword(request: any) {
    return this._http.post(this.baseURLGenric + "api/User/ResetPassword", request, { headers: this.header });
  }
}
