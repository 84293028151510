
<div class="breadcrumbs hotel_banner" style="padding-top: 80px;">
    <div class="container">
        <div class="text-left breadcrumbs-item"  style="padding-top: 20px; padding-bottom: 20px;">
           
        <h2><span>Contact</span> US</h2>
        <div style="content:'';display: block;width:150px;height: 3px;background: #06608c;border-radius: 25%;margin-top: 15px;margin-bottom: 5px;"></div>
        </div>
    </div>
  </div>
  
  <div class="main-body"  style="padding-bottom: 30px;">
    <div class="container">
      <div class="map" style="box-shadow: 0 0 1px 2px #b2b2b27a;padding: 15px;">
              <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d112073.08309274809!2d76.99128643460004!3d28.6212537991611!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390d04d9ed2c7edb%3A0xafc95a453c1a6824!2ssource+my+trip!3m2!1d28.6212718!2d77.0613267!5e0!3m2!1sen!2sin!4v1535658326778" width="100%" height="350" frameborder="0" style="border:0" allowfullscreen></iframe>
         
      </div>
      <div class="row" style="padding-top: 20px;">
  
  
        <div class="col-md-8" style="box-shadow: 0 0 1px 2px #b2b2b27a;padding: 15px;">
            <p>If you have any questions regarding our products or services, please contact us by calling or e-mailing us. We will get back to you as soon as possible.</p>
  
            <form method="post" action="#">
                <div class="row">
                    <div class="col-md-6" style="padding-bottom: 10px;">
                        <input type="text" class="form-control" placeholder="First Name">
                    </div>
                    <div class="col-md-6 mat-n-t" style="padding-bottom: 10px;">
                        <input type="text" class="form-control" placeholder="Last Name">
                    </div>
                </div>
  
                <div class="row mar-t10">
                    <div class="col-md-6" style="padding-bottom: 10px;">
                        <input type="text" class="form-control" placeholder="Mobile Number">
                    </div>
                    <div class="col-md-6 mat-n-t" style="padding-bottom: 10px;">
                        <input type="text" class="form-control" placeholder="Email ">
                    </div>
                </div>
  
                <div class="row mar-t10">
                    <div class="col-md-12" style="padding-bottom: 10px;">
                        <textarea class="form-control" cols="10" rows="3" placeholder="Enter Your Message"></textarea>
                    </div>
                </div>
                <div class="row mar-t10" style="padding-bottom: 10px;">
                    <div class="col-md-12"> <input type="button" class="btn btn-primary" value="Submit Now" name="submit-contact"></div>
                </div>
            </form>
  </div>
  
        <div class="col-md-4" style="box-shadow: 0 0 1px 2px #b2b2b27a;padding: 15px;">
            <h4 class="talk-head">Reach Us</h4>
            <ul class="list-unstyled mb-0">
                <li style="padding-bottom: 4px;border-bottom: 2px solid #ffcd82;width: 48%;"><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-map text-secondaryf"></i></span>
                    <span style="display:table-cell;vertical-align: top;font-weight: 550;COLOR: #085d86;">DUBAI BRANCH </span></li>
                <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-home mr-3 text-secondaryf"></i></span>
                    <span style="display:table-cell;vertical-align: top;">Property No. 101-A2, Plot No. 416-0, Makani No. 31435 96000, mohamed hamed saif alrumhi building,<br> Al Muteena, Dubai</span></li>
                <li><span><i class="fa fa-envelope mr-3 fs-12 text-secondaryf"></i></span><span>sourcemytrip85@gmail.com</span></li>
                <li><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-phone mr-3 text-secondaryf"></i></span><span style="display:table-cell;vertical-align: top;">+971 56 263 6755</span></li>
            </ul>
            
          <!--<ul class="list-unstyled mb-0" style="margin-top: 15px;">
    <li style="padding-bottom: 4px;border-bottom: 2px solid #ffcd82;width: 60%;"><span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-map text-secondaryf"></i></span>
                                                                                 <span style="display:table-cell;vertical-align: top;font-weight: 550;COLOR: #085d86;">DALLAS Taxas(U.S) BRANCH </span></li>
    <li>
      <span style="width: 30px;display:table-cell;vertical-align: top;"><i class="fa fa-home mr-3 text-secondaryf"></i></span>
      <span style="display:table-cell;vertical-align: top;">4239 DARK STAR LANE,<br>  DALLAS Taxas 72511</span>
    </li>
</ul>-->
    
        </div>
  
  
  
  
  
  
  
  
  
    </div>
    </div>
  </div>
