  <!--login-->
  <div class="login-box">

    <div class="login-head">
        <h2>Register</h2>
        <p>Already have an account? <a href="javascript:void(0);" (click)="gotologin()">Log In</a></p>
    </div>
    <div class="field-box">
        <div class="row">
            <div class="col-md-12 b-r">

                <form (ngSubmit)="RegisterUser(registerForm.form.valid)" #registerForm="ngForm" novalidate >
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Name" name="FirstName"
                        [(ngModel)]="registerModel.UserRQ.RequestBody.UserDetails.FirstName" 
                        [ngClass]="{'is-invalid': registerForm.submitted && FirstName.invalid}" 
                        #FirstName="ngModel" required>
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Email id" 
                         [(ngModel)]="registerModel.UserRQ.RequestBody.UserDetails.Email" 
                         [ngClass]="{'is-invalid': registerForm.submitted && Email.invalid}" 
                        #Email="ngModel" required name="Email">

                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" placeholder="Phone no."  
                        [(ngModel)]="registerModel.UserRQ.RequestBody.UserDetails.MobileNo" 
                        [ngClass]="{'is-invalid': registerForm.submitted && MobileNo.invalid}" 
                        #MobileNo="ngModel" required name="MobileNo">

                    </div>
                    <div class="form-group">
                        <input type="password" class="form-control" placeholder="Password" 
                         [(ngModel)]="registerModel.UserRQ.RequestBody.UserDetails.Password" 
                         [ngClass]="{'is-invalid': registerForm.submitted && Password.invalid}" 
                        #Password="ngModel" required name="Password">

                    </div>
                    <ul class="form_ul">
                        <li>
                            <div class="form-check">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input"
                                      [(ngModel)]="registerModel.UserRQ.RequestBody.UserDetails.RememberMe"
                                      #RememberMe="ngModel" name="RememberMe">Remember me
                                </label>
                            </div>
                        </li>

                    </ul>
                    <button type="submit" hidden #registerbtn></button>
                    <a href="javascript:void(0)" class="submit_login" (click)="myFormSubmitHandler()">Register</a>


                </form>
            </div>
            <!-- <div class="col-md-6">
                <div class="social-login">
                    <a href="#" class="fb-btn">
                        <i class="fab fa-facebook-f"></i>
                        Continue with Facebook
                    </a>

                    <a href="#" class="gpls-btn">
                        <i class="fab fa-google"></i>

                        Continue with Google
                    </a> </div>
            </div> -->
        </div>
    </div>

</div>
<app-loader *ngIf="loader"></app-loader>